import { createUseStyles } from 'react-jss'
import { fonts } from '#base/js/variables.style'

export const style = {
  pointLabel: {
    fontSize: 13,
    fontFamily: fonts.lato,
    textAnchor: 'middle'
  }
}

export const useStyles = createUseStyles(style)
