import { colors } from '#base/js/variables.style'
import { useStyles } from './confidence-plot.style'

const STROKE_WIDTH = 2

export const POINT_RADIUS = 4
export const POINT_RADIUS_LARGER = 5 // Larger point when no confidence range
export const ARROW_WIDTH = 7
export const ARROW_HEIGHT = 6

export const PLOT_TYPE = {
  FILLED: 'filled',
  CIRCLE: 'circle'
}

export function ConfidencePlot ({
  value,
  ciLo,
  ciHi,
  xScale,
  y,
  type = PLOT_TYPE.FILLED,
  color,
  label
}) {
  const classes = useStyles()

  const domain = xScale.domain()
  let showArrowRight = false
  if (ciHi > domain[1]) {
    ciHi = domain[1]
    showArrowRight = true
  }
  let showArrowLeft = false
  if (ciLo < domain[0]) {
    ciLo = domain[0]
    showArrowLeft = true
  }

  // Only show the line if lo & hi are defined and at least one of them is within the domain bounds
  const shouldShowCILine =
    ciLo !== undefined &&
    ciHi !== undefined &&
    ciLo <= ciHi &&
    (ciLo < domain[1] || ciHi >= domain[0])

  // Only show the point if it is within the domain bounds
  const shouldShowPoint =
    value !== undefined && value >= domain[0] && value <= domain[1]

  const pointRadius = shouldShowCILine ? POINT_RADIUS : POINT_RADIUS_LARGER

  if (value === undefined || value === null || !y || !xScale) {
    return null
  }

  return (
    <>
      {showArrowLeft && <LeftArrow x={xScale(domain[0])} y={y} color={color} />}
      {showArrowRight && (
        <RightArrow x={xScale(domain[1])} y={y} color={color} />
      )}
      {shouldShowCILine ? (
        <>
          <line
            stroke={color}
            strokeWidth={pointRadius / 2}
            x1={xScale(ciLo)}
            y1={y}
            x2={xScale(ciHi)}
            y2={y}
          />
          {label && (
            <text className={classes.pointLabel} x={xScale(value)} y={y - 8}>
              {label}
            </text>
          )}
        </>
      ) : (
        label && (
          <text className={classes.pointLabel} x={xScale(value)} y={y - 8}>
            {label}
          </text>
        )
      )}
      {shouldShowPoint && type === PLOT_TYPE.FILLED && (
        <circle r={pointRadius} fill={color} cx={xScale(value)} cy={y} />
      )}
      {shouldShowPoint && type === PLOT_TYPE.CIRCLE && (
        <circle
          r={pointRadius - 1}
          fill={colors.background}
          stroke={color}
          strokeWidth={STROKE_WIDTH}
          cx={xScale(value)}
          cy={y}
        />
      )}
    </>
  )
}

function LeftArrow ({ x, y, color }) {
  return (
    <polygon
      points={`${x} ${y + ARROW_HEIGHT / 2}, ${x - ARROW_WIDTH} ${y}, ${x} ${
        y - ARROW_HEIGHT / 2
      }`}
      fill={color}
    />
  )
}

function RightArrow ({ x, y, color }) {
  return (
    <polygon
      points={`${x} ${y - ARROW_HEIGHT / 2}, ${x + ARROW_WIDTH} ${y}, ${x} ${
        y + ARROW_HEIGHT / 2
      }`}
      fill={color}
    />
  )
}
