import { Outlet } from 'react-router'
import { NavbarLayout as Layout } from '#base/js/common/components/Navigation/navbar-layout'

export function NavbarLayout () {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
