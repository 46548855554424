import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'
import { paddingRight } from './table.style'

export const style = {
  headingContainer: ({ scrollTop }) => ({
    position: 'sticky',
    zIndex: 1,
    top: scrollTop
  }),
  topHeadingRow: {
    display: 'flex',
    backgroundColor: colors.background,
    paddingRight
  },
  topHeadingCell: {
    flex: '1 0 auto',
    height: 23,
    margin: '2px 2px 0'
  },
  topHeadingLabelContainer: {
    extend: 'topHeadingCell',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: `1px solid ${colors.brandAlt}`,
    borderRight: `1px solid ${colors.brandAlt}`,
    borderTop: `1px solid ${colors.brandAlt}`,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    padding: '0 15px'
  },
  topHeadingLabel: {
    textAlign: 'center',
    color: colors.brandAlt,
    fontSize: 13,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  columnGroupWarning: {
    backgroundColor: colors.warning
  },
  headingRow: {
    display: 'flex',
    position: 'relative',
    backgroundColor: colors.brandAlt,
    border: '1px solid ' + colors.brandAlt,
    color: 'white',
    fontSize: 13,
    fontWeight: 'normal',
    paddingRight
  },
  headingCell: {
    flex: '1 0 auto',
    alignSelf: 'center',
    padding: '6px 5px'
  },
  headingCellSortable: {
    extend: 'headingCell',
    '&:hover': {
      color: colors.brandHighlight,
      cursor: 'pointer'
    },
    '&:hover $sortIndicator': {
      fill: colors.brandHighlight
    }
  },
  headingCellLabel: {
    position: 'relative',
    display: 'inline'
  },
  sortIndicator: {
    fill: 'white',
    position: 'absolute',
    right: -17,
    marginTop: 5
  },
  labelHeadingContainer: {
    flex: '1 0 auto',
    display: 'flex',
    padding: '6px 40px 6px 20px'
  },
  labelHeadingCell: {
    flex: '1 1 auto',
    alignSelf: 'center'
  },
  secondaryLabelHeadingCell: {
    justifyContent: 'center',
    flex: '0 0 75px',
    display: 'flex',
    alignSelf: 'flex-end'
  },
  dataColumnOverflow: {
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  dataColumnContainer: {
    display: 'inline-flex',
    position: 'relative',
    transition: 'left 200ms'
  },
  leftArrow: {
    position: 'absolute',
    zIndex: 1,
    width: 20,
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rightArrow: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
    width: 24,
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  disableArrow: {
    cursor: 'default'
  }
}

export const useStyles = createUseStyles(style)
