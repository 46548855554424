import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const style = {
  svgContainer: {
    width: '100%',
    maxWidth: 100,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  },
  refLine: {
    stroke: colors.chartAxis,
    strokeWidth: '1px'
  }
}

export const useStyles = createUseStyles(style)
