class AppSettingsService {
  appSettings = {}

  setCache (appSettings = {}) {
    this.appSettings = { ...this.appSettings, ...appSettings }
  }

  get (key) {
    return this.appSettings[key]
  }
}

export const appSettingsService = new AppSettingsService()
