/***
 *** Context that holds master schema for JSON schema of Evaluation Functions and Graph Types
 ***
 *** NOTES:
 ***  Stores in cache so only loaded once
 ***/

import { createContext } from 'react'
import axios from 'axios'

const emptyMaster = {
  title: 'Evaluation Schema Error',
  description: 'There was a problem loading the master schema',
  $id: 'master',
  evalFuncInputs: {
    ipwrisk: {
      title: 'Invalid Schema',
      type: 'object',
      properties: {}
    }
  },
  evalFuncUI: {
    ipwrisk: {}
  },
  schemaToGraphTypes: {
    ipwrisk: ['error']
  },
  graphInputs: {
    error: {
      title: 'Evaluation Schema Error',
      type: 'object',
      properties: {}
    }
  }
}

class SchemaLoader {
  constructor () {
    this.schemaCache = null
  }

  // PURPOSE: Ensure that master schema has been loaded and return top-level pointer to it
  // INPUT: callback is called when cache has been loaded, with error if any
  async ensureCached () {
    if (this.schemaCache !== null) return

    try {
      const res = await axios.get('api/schemas/master')
      this.schemaCache = res.data
    } catch (err) {
      // Fatal error if cannot load master schema, but provide a NULL schema
      // to prevent downstream crashes
      // eslint-disable-next-line no-console
      console.error('ERROR: Cannot load master schema data ', err)
      this.schemaError = err
      this.schemaCache = emptyMaster
    }
  } // ensureCached()

  get evalFuncInputs () {
    return this.schemaCache.evalFuncInputs
  } // evalFuncInputs()

  get evalFuncUI () {
    return this.schemaCache.evalFuncUI
  } // evalFuncUI()

  get schemaToGraphTypes () {
    return this.schemaCache.schemaToGraphTypes
  } // schemaToGraphTypes()

  get graphInputs () {
    return this.schemaCache.graphInputs
  } // graphInputs()
} // SchemaLoader()

export const schemaLoader = new SchemaLoader()
export const SchemaContext = createContext()
