import { appSettingsService } from '../services/app-settings.service'
import axios from 'axios'

// Create an axios instance which communicates with
//  the OAuth2 agent, but does not intercept 401s
const agentAxios = axios.create()
agentAxios.interceptors.request.clear()
agentAxios.defaults.withCredentials = true
agentAxios.defaults.validateStatus = (status) => {
  return status === 200
}

class OAuth2AgentService {
  async verify () {
    return agentAxios.get(
      `${appSettingsService.get('authAgentUrl')}/auth/oauth2/verify`
    )
  }

  async authorizeUrl (options: {
    providerHint?: string // Look up provider by idp identifier
    provider?: string // Log into a particular provider
    redirectUri?: string
  }) {
    options.redirectUri = options.redirectUri ?? '/reports'
    const { data } = await agentAxios.get(
      `${appSettingsService.get(
        'authAgentUrl'
      )}/auth/oauth2/authorize?${objectToSearchParams(options)}`
    )
    return data as {
      url: string
      providerName: string
      clientId: string
    }
  }

  async bearerTokenAuthorize (options: {
    accessToken: string
    logoutUrl?: string
  }) {
    await agentAxios.post(
      `${appSettingsService.get('authAgentUrl')}/auth/oauth2/access`,
      {
        accessToken: options.accessToken,
        ...(options.logoutUrl ? { logoutUrl: options.logoutUrl } : {})
      }
    )
  }

  async logoutUrl (options: { redirectUri: string; message?: string }) {
    const state =
      options.message &&
      window.btoa(JSON.stringify({ message: options.message }))
    const { data } = await axios.get(
      `${appSettingsService.get(
        'authAgentUrl'
      )}/auth/oauth2/logout?${objectToSearchParams({
        redirectUri: options.redirectUri,
        ...(state ? { state } : {})
      })}`
    )
    return data as {
      url: string
    }
  }

  async idpResponse (options: { search: string }) {
    const { data } = await agentAxios.get(
      `${appSettingsService.get('authAgentUrl')}/auth/oauth2/idpresponse${
        options.search
      }`
    )
    return data
  }
}

function objectToSearchParams (obj: Record<string, string>): URLSearchParams {
  const params = new URLSearchParams()
  Object.entries(obj).forEach(([k, v]) => params.append(k, v))
  return params
}

export const oauth2AgentService = new OAuth2AgentService()
