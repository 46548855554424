import { SORT_DIRECTION, tableSortService } from './table-sort.service'
import { useEffect, useState } from 'react'

import { useLayeredScrollContext } from '#base/js/common/components/effect/layered-scroll'
import { GenericTooltip } from '#base/js/common/components/FloatingUI/GenericTooltip'
import { useStyles } from './table-column-header.style'
import { useTooltipDisplayContext } from '../common/tooltip/tooltip.context'

export const TableColumnHeader = function ({
  columns,
  columnGroups = [],
  onShiftChange = () => {},
  sortSetting,
  disableSort,
  noSortColumns,
  onSortColumnChange = () => {},
  isPreviewMode
}) {
  const { marginTop: scrollTop = 0 } = useLayeredScrollContext() ?? {}

  const classes = useStyles({ scrollTop })

  const {
    labelColumn,
    dataColumns,
    labelColumnWidth,
    dataColumnWidth,
    columns: allColumns = []
  } = columns

  const [shiftBy, setShiftBy] = useState(0)
  const [shouldEnableLeftArrow, setShouldEnableLeftArrow] = useState(
    canShiftLeft(shiftBy)
  )
  const [shouldEnableRightArrow, setShouldEnableRightArrow] = useState(
    canShiftRight(shiftBy)
  )
  const [, setTooltipDisplayData] = useTooltipDisplayContext()

  const shouldDisplayColumnGroup =
    !shouldEnableLeftArrow && !shouldEnableRightArrow && columnGroups.length > 0
  const shouldDisplayColumnGroupWarning =
    !shouldDisplayColumnGroup && isPreviewMode && columnGroups.length > 0

  useEffect(() => {
    setShiftBy(0)
    updateArrowState(0)
    onShiftChange(0)
  }, [allColumns, labelColumnWidth, dataColumnWidth])

  function shiftLeft () {
    if (canShiftLeft(shiftBy)) {
      const nextShift = shiftBy + dataColumnWidth
      setShiftBy(nextShift)
      updateArrowState(nextShift)
      onShiftChange(nextShift)
    }
  }

  function shiftRight () {
    if (canShiftRight(shiftBy)) {
      const nextShift = shiftBy - dataColumnWidth
      setShiftBy(nextShift)
      updateArrowState(nextShift)
      onShiftChange(nextShift)
    }
  }

  function canShiftLeft (currentShift) {
    return currentShift < 0
  }

  function canShiftRight (currentShift) {
    const nextShift = currentShift - dataColumnWidth
    const hasReachedTheEnd =
      Math.abs(nextShift) >=
      columns.dataColumnTotalWidth -
        columns.dataColumnViewableWidth +
        dataColumnWidth
    return !hasReachedTheEnd
  }

  function updateArrowState (shift) {
    setShouldEnableRightArrow(canShiftRight(shift))
    setShouldEnableLeftArrow(canShiftLeft(shift))
  }

  function isSortable (column) {
    return !disableSort && !(noSortColumns || []).includes(column)
  }

  function isSortColumn (sortSetting, column) {
    if (!sortSetting || sortSetting.column === null) {
      return false
    }
    return column === sortSetting.column
  }

  function showTooltip (sortSetting, column) {
    let primaryValue = 'Click to sort ascending'
    if (isSortColumn(sortSetting, column)) {
      primaryValue =
        sortSetting.direction === SORT_DIRECTION.DESC
          ? 'Click to remove sort'
          : 'Click to sort descending'
    }
    setTooltipDisplayData({ primaryValue })
  }

  function hideTooltip () {
    setTooltipDisplayData()
  }

  return (
    <div className={classes.headingContainer}>
      {/* Only display top header row if all columns fit without arrows. In preview mode, it warns users to adjust column widths. */}
      {shouldDisplayColumnGroup || shouldDisplayColumnGroupWarning ? (
        <GenericTooltip
          placement={'top'}
          mode={'div'}
          persist={shouldDisplayColumnGroupWarning}
          Target={
            <div className={classes.topHeadingRow}>
              {labelColumn && (
                <div
                  className={classes.topHeadingCell}
                  style={{ width: labelColumnWidth }}
                ></div>
              )}
              {columnGroups.map(({ label, columnCount = 1 }, i) => {
                return (
                  <div
                    key={label || i}
                    style={{ width: dataColumnWidth * columnCount }}
                  >
                    {label ? (
                      <div
                        className={`${classes.topHeadingLabelContainer} ${
                          shouldDisplayColumnGroupWarning
                            ? classes.columnGroupWarning
                            : ''
                        }`}
                      >
                        <div className={classes.topHeadingLabel} title={label}>
                          {label}
                        </div>
                      </div>
                    ) : (
                      <div className={classes.topHeadingCell}></div>
                    )}
                  </div>
                )
              })}
            </div>
          }
          Popup={
            shouldDisplayColumnGroupWarning && (
              <div className="tooltip-content">
                These column groups will be hidden. Please adjust the width of
                the columns so that they all fit within the width of the page
                without scrolling.
                <div className="tooltip-tip" />
              </div>
            )
          }
        />
      ) : null}
      <div className={classes.headingRow}>
        {labelColumn && (
          <div
            key={labelColumn.key}
            className={classes.labelHeadingContainer}
            style={{
              textAlign: labelColumn.align || 'left',
              width: labelColumnWidth
            }}
            data-qa={'column-' + labelColumn.key}
          >
            <div className={classes.labelHeadingCell}>{labelColumn.label}</div>
            {labelColumn.secondaryLabel && (
              <div className={classes.secondaryLabelHeadingCell}>
                {labelColumn.secondaryLabel}
              </div>
            )}
          </div>
        )}
        {(shouldEnableLeftArrow || shouldEnableRightArrow) && (
          <div
            className={`${classes.leftArrow} ${
              !shouldEnableLeftArrow && classes.disableArrow
            }`}
            style={{ left: labelColumnWidth }}
            onClick={shiftLeft}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 8 8"
              fill="#fff"
              opacity={shouldEnableLeftArrow ? 1 : 0.1}
            >
              <path
                d="M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z"
                transform="translate(1)"
              />
            </svg>
          </div>
        )}
        <div className={classes.dataColumnOverflow}>
          <div
            className={classes.dataColumnContainer}
            style={{ left: `${shiftBy}px` }}
          >
            {dataColumns.map((column, i) => {
              const style = {
                textAlign: column.align || 'center',
                width: dataColumnWidth
              }
              return isSortable(column.key) ? (
                <div
                  key={column.key}
                  className={classes.headingCellSortable}
                  style={style}
                  data-qa={'column-' + column.key}
                  onClick={() => {
                    const nextSort = tableSortService.getNextSort(
                      sortSetting,
                      column.key
                    )
                    showTooltip(nextSort, column.key)
                    onSortColumnChange(column.key)
                  }}
                  onMouseMove={() => {
                    showTooltip(sortSetting, column.key)
                  }}
                  onMouseLeave={hideTooltip}
                >
                  <div className={classes.headingCellLabel}>
                    {column.label}
                    {isSortColumn(sortSetting, column.key) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 24 24"
                        className={classes.sortIndicator}
                      >
                        {sortSetting.direction === SORT_DIRECTION.ASC ? (
                          <path d="M0 21l12-18 12 18z" />
                        ) : (
                          <path d="M24 3l-12 18-12-18z" />
                        )}
                      </svg>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  key={column.key}
                  className={classes.headingCell}
                  style={style}
                  data-qa={'column-' + column.key}
                >
                  <div className={classes.headingCellLabel}>{column.label}</div>
                </div>
              )
            })}
          </div>
        </div>
        {(shouldEnableLeftArrow || shouldEnableRightArrow) && (
          <div
            className={`${classes.rightArrow} ${
              !shouldEnableRightArrow && classes.disableArrow
            }`}
            onClick={shiftRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 8 8"
              fill="#fff"
              opacity={shouldEnableRightArrow ? 1 : 0.1}
            >
              <path
                d="M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z"
                transform="translate(1)"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}
