import { createUseStyles } from 'react-jss'

import { colors } from '#base/js/variables.style'

export const style = {
  emphasis: {
    color: colors.brandAccent,
    fontWeight: 'bold'
  }
}

export const useStyles = createUseStyles(style)
