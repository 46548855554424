import { useStyles } from './item-card.style'

export const ItemCard = function ({
  children,
  className = '',
  onClick,
  ...props
}) {
  const classes = useStyles()
  const hasOnClick = !!onClick

  if (!hasOnClick) {
    onClick = function () {}
  }

  return (
    <div
      className={`${classes.container} ${className} ${
        hasOnClick ? classes.clickable : ''
      }`}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  )
}
