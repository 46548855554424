import { createUseStyles } from 'react-jss'
import { colors, zIndexes } from '../../../variables.style'

export const style = {
  overlay: {
    zIndex: zIndexes.dropdownMenuOverlay,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  menu: {
    textAlign: 'start',
    composes: 'flex-column',
    zIndex: zIndexes.dropdownMenu,
    backgroundColor: 'white',
    borderRadius: '4px',
    fontSize: '14px',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1)',
    margin: '8px 0',
    padding: '4px 0',

    '& > a': {
      padding: '8px 12px',
      cursor: 'pointer',
      textDecoration: 'none',
      whiteSpace: 'nowrap',

      '&:hover': {
        backgroundColor: colors.brandAccent,
        color: 'white'
      }
    }
  },
  menuSmall: {
    extend: 'menu',
    fontSize: 12,
    margin: '4px 0',
    padding: '2px 0'
  },
  menuTitle: {
    margin: '10px',
    maxWidth: '180px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export const useStyles = createUseStyles(style)
