import { createContext, useContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { UserContext } from '#base/js/common/contexts/userContext'
import { useParams } from 'react-router'

const HOST = __CONFIG__.apiHost || window.location.hostname
const BASE_URL =
  window.location.protocol + '//' + HOST + ':' + __CONFIG__.apiPort

export const WebsocketContext = createContext()

export function WebsocketContextProvider ({ namespace = '', children }) {
  const params = useParams()

  for (const param in params) {
    namespace = namespace.replace(`:${param}`, params[param])
  }

  const { user } = useContext(UserContext)

  const [context, setContext] = useState({})

  useEffect(() => {
    const socket = user && connect(namespace)

    return () => {
      disconnect(socket)
    }
  }, [user, namespace])

  function connect (namespace) {
    // Create new socket
    const socket = io(`${BASE_URL}/${namespace}`, {
      withCredentials: true,
      multiplex: false,
      transports: __CONFIG__.socketTransports
    })

    // Set context on connect
    socket.on('connect', () => {
      setContext({ socket })
    })

    // Clear context on disconnect
    socket.on('disconnect', () => {
      setContext({ socket: null })
    })

    // Add error handlers
    socket.on('connect_error', (err) => {
      onError(err)
    })

    socket.on('error', onError)

    return socket
  }

  function disconnect (socket) {
    if (socket?.connected) socket.close()
  }

  function onError (err) {
    // eslint-disable-next-line no-console
    console.error(err instanceof Error ? err : new Error(err))
  }

  return (
    <WebsocketContext.Provider value={context}>
      {children}
    </WebsocketContext.Provider>
  )
}
