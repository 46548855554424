import { useState } from 'react'

import { TextInput } from '../common/components/text-input'
import NotificationManager from '../common/components/Notifications/NotificationManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faPencil,
  faTrashCan
} from '@fortawesome/pro-solid-svg-icons'

import { useStyles } from './category-bar.style'

export function CategoryBar ({
  groups,
  index,
  mode = 'view',
  onChange = function () {}
}) {
  const classes = useStyles()

  const [isCategoryNameEditable, setIsCategoryNameEditable] = useState(false)
  const [categoryName, setCategoryName] = useState('')

  const label = groups[index].label

  function handleCategoryBlur () {
    const newGroupId = categoryName.toLowerCase().trim()
    const copiedGroups = JSON.parse(JSON.stringify(groups))
    const groupToEdit = copiedGroups[index]

    if (newGroupId.length === 0) {
      NotificationManager.create({
        type: 'error',
        timeOut: 20000,
        message: 'Sorry, category name is required. Could you enter a name?'
      })
      return
    }

    let isDuplicateName = false
    copiedGroups.forEach((group) => {
      if (group !== groupToEdit && group.id === newGroupId) {
        isDuplicateName = true
      }
    })

    if (isDuplicateName) {
      NotificationManager.create({
        type: 'error',
        timeOut: 20000,
        message:
          'Sorry, category names must be unique. Could you enter another name?'
      })
      return
    }

    groupToEdit.id = newGroupId
    groupToEdit.label = categoryName

    setIsCategoryNameEditable(false)
    onChange(copiedGroups)
  }

  function handleCategoryKeyDown (event) {
    switch (event.key) {
      case 'Enter':
        handleCategoryBlur()
        break
      case 'Escape':
        setIsCategoryNameEditable(false)
        break
    }
  }

  function handleCategoryEdit () {
    setCategoryName(label)
    setIsCategoryNameEditable(true)
  }

  function handleCategoryDelete () {
    const copiedGroups = JSON.parse(JSON.stringify(groups))

    if (copiedGroups[index].pages.length > 0) {
      NotificationManager.create({
        type: 'error',
        timeOut: 20000,
        message:
          'Sorry, this category must be empty before it can be deleted. Try moving all pages to another category first?'
      })
      return
    }

    copiedGroups.splice(index, 1)
    onChange(copiedGroups)
  }

  function moveCategoryUp () {
    const copiedGroups = JSON.parse(JSON.stringify(groups))

    if (index === 0) {
      return
    }

    const [removedGroup] = copiedGroups.splice(index, 1)
    copiedGroups.splice(index - 1, 0, removedGroup)

    onChange(copiedGroups)
  }

  function moveCategoryDown () {
    const copiedGroups = JSON.parse(JSON.stringify(groups))

    if (index === copiedGroups.length - 1) {
      return
    }

    const [removedGroup] = copiedGroups.splice(index, 1)
    copiedGroups.splice(index + 1, 0, removedGroup)

    onChange(copiedGroups)
  }

  function focusOnCategoryField (ref) {
    if (ref) {
      ref.focus()
    }
  }

  if (!label) {
    return null
  }

  if (mode !== 'edit') {
    return (
      <div
        className={classes.categoryContainer}
        data-qa="report-category-container"
      >
        <div className={classes.category}>{label}</div>
      </div>
    )
  }

  if (isCategoryNameEditable) {
    return (
      <div
        className={classes.categoryTextFieldContainer}
        data-qa="report-category-container"
      >
        <TextInput
          value={categoryName}
          onChange={(event) => {
            setCategoryName(event.target.value)
          }}
          onBlur={handleCategoryBlur}
          onKeyDown={handleCategoryKeyDown}
          className={classes.categoryTextField}
          placeholder="required"
          _ref={focusOnCategoryField}
          data-qa="category-edit-input"
        />
      </div>
    )
  } else {
    return (
      <div
        className={classes.categoryContainer}
        data-qa="report-category-container"
      >
        <div className={classes.arrowContainer}>
          {index > 0 &&
            (index === groups.length - 1 ? (
              <FontAwesomeIcon
                className={classes.upArrow}
                onClick={moveCategoryUp}
                data-qa="category-up"
                icon={faChevronUp}
              />
            ) : (
              <FontAwesomeIcon
                className={classes.upArrow}
                onClick={moveCategoryUp}
                data-qa="category-up"
                icon={faChevronUp}
              />
            ))}
          {index < groups.length - 1 && (
            <FontAwesomeIcon
              className={classes.downArrow}
              onClick={moveCategoryDown}
              data-qa="category-down"
              icon={faChevronDown}
            />
          )}
        </div>
        <div className={classes.category}>{label}</div>
        <FontAwesomeIcon
          className={classes.editCategory}
          onClick={handleCategoryEdit}
          data-qa="category-edit"
          icon={faPencil}
        />
        <FontAwesomeIcon
          className={classes.deleteCategory}
          onClick={handleCategoryDelete}
          data-qa="category-delete"
          icon={faTrashCan}
        />
      </div>
    )
  }
}
