import axios from 'axios'
import moment from 'moment'
import { mapFromRawData } from '../blocks/table/table-mapping'
import { INTERVAL } from './constants'

export interface ReportVisitOptions {
  startDate?: Date
  endDate?: Date
  includePages?: boolean
  uniqueVisitors?: boolean
  filterEmployees?: boolean
}
const TOTAL_LABEL = 'Total'
const MAIN_PAGE_LABEL = 'Main Page'
const EXEC_SUMMARY_LABEL = 'Executive Summary'

class ReportMetricsService {
  /**
   * Retrieve the count of report and page visits over the given time interval
   */
  async getVisits (
    reportId: string,
    dateInterval: string = INTERVAL.MONTH,
    options: ReportVisitOptions
  ) {
    const formatDate = (date) => moment.utc(date).format('YYYY-MM-DD')
    const params = {
      dateInterval,
      startDate: options.startDate ? formatDate(options.startDate) : undefined,
      endDate: options.endDate ? formatDate(options.endDate) : undefined,
      includePages: options.includePages,
      uniqueVisitors: options.uniqueVisitors,
      filterEmployees: options.filterEmployees
    }
    const { data } = await axios.get(`api/report/${reportId}/visits`, {
      params
    })

    return data
  }

  mapToTable (metrics, report) {
    const sections = this.buildTableSections(report)
    const cellFormat = 'page'
    const tableData = []
    const columnTotals = []
    const rowTotals = []

    metrics.forEach(({ _id: idParts, count }) => {
      const { item, date } = idParts
      const section = sections.find((s) => s.rows.find((r) => r.value === item))
      if (section) {
        tableData.push({
          section: section.value,
          row: item,
          column: date,
          count,
          cell_format: cellFormat
        })
        this.addToTotal(columnTotals, date, count)
        this.addToTotal(rowTotals, item, count, { section: section.value })
      }
    })

    tableData.unshift(
      ...rowTotals.map((entry) => ({
        section: entry.section,
        row: entry.id,
        column: TOTAL_LABEL,
        count: entry.count,
        cell_format: cellFormat
      }))
    )
    tableData.unshift(
      ...columnTotals.map((entry) => ({
        section: '',
        row: TOTAL_LABEL,
        column: entry.id,
        count: entry.count,
        cell_format: cellFormat
      }))
    )
    tableData.unshift({
      section: '',
      row: TOTAL_LABEL,
      column: TOTAL_LABEL,
      count: columnTotals.reduce((count, entry) => count + entry.count, 0),
      cell_format: cellFormat
    })

    const cellFormats = {
      [cellFormat]: [
        { variables: ['count'], format: 'number', align: 'center' }
      ]
    }

    return mapFromRawData(tableData, {
      cellFormats,
      primaryLabel: '',
      sections,
      rows: [
        {
          value: TOTAL_LABEL,
          label: TOTAL_LABEL
        },
        {
          value: report.id,
          label: MAIN_PAGE_LABEL
        }
      ]
    })
  }

  private buildTableSections (report) {
    const sections = []
    report?.groups.forEach((group) => {
      const section = {
        value: group.id === '' ? EXEC_SUMMARY_LABEL : group.id,
        label: group.label ?? EXEC_SUMMARY_LABEL,
        rows: group.pages.map((page) => ({
          value: page.id,
          label: page.title
        }))
      }
      if (group.id === '') {
        sections.unshift(section)
      } else {
        sections.push(section)
      }
    })
    sections.unshift({
      value: '',
      label: '',
      rows: [
        {
          value: report.id,
          label: MAIN_PAGE_LABEL
        }
      ]
    })
    return sections
  }

  private addToTotal (list, id, count, additionalProps?) {
    const entry = list.find((entry) => entry.id === id)
    if (entry) {
      entry.count += count
    } else {
      list.push({ id, count, ...additionalProps })
    }
  }
}

export const reportMetricsService = new ReportMetricsService()
