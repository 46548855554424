import { ascending, descending } from 'd3-array'

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
}

class TableSortService {
  getNextSort (current, newColumn) {
    if (current.column !== newColumn) {
      // New column sort, first click - start with ascending
      return { column: newColumn, direction: SORT_DIRECTION.ASC }
    } else if (current.direction === SORT_DIRECTION.ASC) {
      // Same column sort, second click - change direction to descending
      return { column: newColumn, direction: SORT_DIRECTION.DESC }
    } else if (current.direction === SORT_DIRECTION.DESC) {
      // Same column sort, third click - disable sorting
      return { column: null, direction: null }
    }
  }

  sortData (tableData, { column, direction }) {
    if (column === null || direction === null) {
      return tableData
    }

    tableData = { ...tableData }

    if (tableData.sections) {
      tableData.sections = tableData.sections.map((section) => {
        section = { ...section }
        section.rows = this.sortRows(section.rows, { column, direction })
        return section
      })
    }

    if (tableData.rows) {
      tableData.rows = this.sortRows(tableData.rows, { column, direction })
    }

    return tableData
  }

  sortRows (rows, { column, direction }) {
    return [...rows].sort((a, b) => {
      a = this.getSortValue(a, column)
      b = this.getSortValue(b, column)

      // Sort undefined to the bottom (normally sort itself does this, but we're sorting objects)
      if (a === undefined) {
        return 1
      }
      if (b === undefined) {
        return -1
      }

      const sortFunction =
        direction === SORT_DIRECTION.ASC ? ascending : descending
      return sortFunction(a, b)
    })
  }

  getSortValue (row, column) {
    const cell = row.columns.find(({ key }) => key === column)
    if (cell && cell.parts && cell.parts.length > 0) {
      let value = Array.isArray(cell.parts[0].values)
        ? cell.parts[0].values[0]
        : cell.parts[0].values
      value = Number(value)
      if (!isNaN(value)) {
        return value
      }
    }
  }
}

export const tableSortService = new TableSortService()
