import { createUseStyles } from 'react-jss'
import { colors } from '../../variables.style'

export const LOADER_HEIGHT = 40

export const style = {
  container: {
    margin: 'auto',
    width: '50px',
    height: LOADER_HEIGHT,
    textAlign: 'center',
    fontSize: '10px',

    '& > div': {
      backgroundColor: colors.brandPrimary,
      height: '100%',
      width: '6px',
      display: 'inline-block',
      animationName: 'stretch-with-delay',
      animationDuration: '1.2s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite'
    }
  },
  rectangle1: {
    animationDelay: '0s',
    marginRight: '3px'
  },
  rectangle2: {
    animationDelay: '-1.1s',
    marginRight: '3px'
  },
  rectangle3: {
    animationDelay: '-1.0s',
    marginRight: '3px'
  },
  rectangle4: {
    animationDelay: '-0.9s',
    marginRight: '3px'
  },
  rectangle5: {
    animationDelay: '-0.8s'
  },
  icon: {
    composes: 'fa-spin-pulse',
    transform: 'rotate(45deg)',
    '& .fa-primary': {
      fill: colors.brandPrimary
    },
    '& .fa-secondary': {
      fill: colors.brandAlt
    }
  }
}

export const useStyles = createUseStyles(style)
