import { createUseStyles } from 'react-jss'
import { zIndexes } from '../../../variables.style'

export const styles = {
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  },
  headerContainer: {
    display: 'block',
    position: 'sticky',
    top: 0,
    width: '100%',
    background: 'transparent',
    zIndex: zIndexes.scrollContent,
    pointerEvents: 'none'
  },
  header: {
    width: '100%',
    pointerEvents: 'auto'
  },
  content: {
    width: '100%',
    height: '100%'
  }
}

export const useStyles = createUseStyles(styles)
