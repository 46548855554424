import { Fragment } from 'react'
import ReactSelect, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from './button'
import { Label } from './label'
import { Tooltip } from './Tooltip'
import { colors, sizes, zIndexes } from '../../variables.style'
import { useStyles } from './select.style'

export const ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

export function Select ({
  'data-qa': dataQa,
  className,
  labelClass,
  size = 'large',
  label,
  width,
  border = true,
  orientation = ORIENTATION.VERTICAL,
  isCreatable = false,
  onOpen = () => {},
  onClose = () => {},
  styles,
  tooltipLabel = '',
  tooltipDelay,
  actions,
  ...props
}) {
  const classes = useStyles()

  function buildStyles (styles = {}) {
    const {
      option,
      container,
      control,
      input,
      indicatorSeparator,
      dropdownIndicator,
      clearIndicator,
      menu,
      ...otherStyles
    } = styles

    // React-Select component uses Emotion to style. This is not JSS.
    // https://react-select.com/styles
    return {
      option: (provided, state) => {
        const defaultStyle = {
          ...provided,
          '&:hover': {
            cursor: 'pointer'
          }
        }
        if (state.isSelected) {
          return {
            ...defaultStyle,
            backgroundColor: colors.brandAccent,
            ...option
          }
        } else {
          return { ...defaultStyle, ...option }
        }
      },
      container: (provided) => ({
        ...provided,
        ...(width ? { width } : {}),
        ...container
      }),
      control: (provided, state) => ({
        ...provided,
        minHeight:
          size === 'medium' ? sizes.buttonHeight : sizes.textInputHeight,
        border: 'none',
        boxShadow: 'none',
        ...control
      }),
      input: (provided) => ({
        ...provided,
        margin: 1,
        ...input
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        ...indicatorSeparator
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 5,
        ...dropdownIndicator
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
        ...clearIndicator
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: zIndexes.dropdownMenu,
        ...menu
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: zIndexes.dropdownMenu
      }),
      ...Object.entries(otherStyles).reduce(
        (styles, [key, value]) => ({
          ...styles,
          [key]: (provided) => ({
            ...provided,
            ...value
          })
        }),
        {}
      )
    }
  }

  const Select = isCreatable ? CreatableSelect : ReactSelect

  const menu = (
    <div className={classes.menu}>
      <Select
        isClearable={isCreatable}
        // isSearchable breaks enter animations (https://github.com/JedWatson/react-select/issues/165)
        // Example of the issue: https://codesandbox.io/s/affectionate-rhodes-qir59
        isSearchable={isCreatable}
        styles={buildStyles(styles)}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        menuPortalTarget={document.querySelector('.body-text')}
        classNamePrefix="react-select"
        components={{
          MenuPortal: ({ children, ...props }) => (
            <components.MenuPortal {...props}>
              <div data-qa={`${dataQa}-menu`}>{children}</div>
            </components.MenuPortal>
          )
        }}
        {...props}
      />
    </div>
  )

  const box = (
    <div
      data-qa={dataQa}
      className={className ? `${className} ${classes.box}` : classes.box}
    >
      {actions?.map(
        ({ icon, className, separatorClass, tooltipLabel, onClick }, index) => (
          <Fragment key={index}>
            <Button
              variant="icon-link"
              className={
                className ? `${className} ${classes.button}` : classes.button
              }
              tooltipLabel={tooltipLabel}
              onClick={onClick}
            >
              <FontAwesomeIcon icon={icon} />
            </Button>
            <span
              className={
                separatorClass
                  ? `${separatorClass} ${classes.separator}`
                  : classes.separator
              }
            ></span>
          </Fragment>
        )
      )}
      {tooltipLabel && !props.disabled ? (
        <Tooltip
          title={tooltipLabel}
          placement="top"
          enterDelay={tooltipDelay}
          enterNextDelay={tooltipDelay}
        >
          {menu}
        </Tooltip>
      ) : (
        menu
      )}
    </div>
  )

  const select =
    orientation === ORIENTATION.VERTICAL ? (
      <div
        className={
          labelClass
            ? `${labelClass} form-element-select`
            : 'form-element-select'
        }
      >
        {label && (
          <Label
            className={
              size === 'medium'
                ? 'form-element-label-medium'
                : 'form-element-label'
            }
            required={props.required}
          >
            {label}
          </Label>
        )}
        {box}
      </div>
    ) : (
      <div
        className={
          labelClass
            ? `${labelClass} form-element-select form-element-wrapper-horizontal`
            : 'form-element-select form-element-wrapper-horizontal'
        }
      >
        {label && (
          <Label
            className="form-element-label-horizontal"
            required={props.required}
          >
            {label}
          </Label>
        )}
        <div className="form-element-horizontal">{box}</div>
      </div>
    )

  return select
}
