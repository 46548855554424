export const REPORT_TYPES = {
  PHR: 0,
  SANKEY: 1,
  NARRATIVE: 2,
  IP: 3
}

export const REPORT_VIEWERS = {
  IFRAME: 0,
  MARKDOWN: 1,
  PAGE_LIST: 2,
  PAGE: 3,
  IFRAME_SUBFOLDER: 4,
  DEMO_PAGE_LIST: 5,
  DEMO_PAGE: 6,
  EXECUTIVE_SUMMARY_PAGE: 7
}

export const PAGE_CONTROL_BAR_KEY = 'page_control_bar'
