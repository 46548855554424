import { createUseStyles } from 'react-jss'

import { colors } from '../variables.style'

export const style = {
  pageNumber: {
    fontFamily: 'Raleway',
    fontVariantNumeric: 'lining-nums',
    fontSize: 15,
    flex: '0 0 34px',
    marginTop: 2
  },
  pageLink: {
    composes: 'flex-auto link',
    fontSize: 16
  },
  select: {
    margin: '-9px 0 -9px -9px'
  },
  currentReport: {
    composes: 'flex-auto',
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.brandAccent
  },
  currentPageNumber: {
    extend: 'pageNumber',
    color: colors.brandAccent
  },
  draftIcon: {
    marginLeft: '-10px',
    padding: '0 10px'
  }
}

export const useStyles = createUseStyles(style)
