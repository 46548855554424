import { createUseStyles } from 'react-jss'

const gutter = 24
export const style = {
  form: {
    composes: 'flex-column flex-center-items',
    margin: '100px auto 0',
    maxWidth: 992 + gutter * 2,
    width: '100%',
    padding: `0 ${gutter}px`,
    '& > div': {
      width: '100%',
      maxWidth: 380,
      '& + *': {
        marginTop: 40
      }
    }
  },
  infoTooltip: {
    marginLeft: 4
  }
}

export const useStyles = createUseStyles(style)
