import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'
import { UserContext } from '#base/js/common/contexts/userContext'
import { FullPageLoader } from '#base/js/common/components/full-page-loader'
import { oauth2AgentService } from '#base/js/common/services/oauth2Agent.service'
import { IdleTimer } from '#base/js/common/components/idle-timer'
import { idleTimeoutManagerService } from '#base/js/common/services/idle-timeout-manager.service'

export function RequireAuth () {
  const { user, providerHint } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      if (!user) {
        // If no user exists at this point in the route hierarchy,
        //  forward to the authorize endpoint to retrieve cached browser state
        //  from the IdP, then potentially to the IdP's login page
        try {
          const { url } = await oauth2AgentService.authorizeUrl({
            providerHint,
            redirectUri: location.pathname
          })
          window.location.assign(url)
        } catch (err) {
          navigate('/welcome', {
            replace: true,
            state: {
              error: (err as Error).message
            }
          })
        }
      }
    })()
  }, [user])

  return user ? (
    <>
      {idleTimeoutManagerService.isRunnable() && <IdleTimer />}
      <Outlet />
    </>
  ) : (
    <FullPageLoader />
  )
}
