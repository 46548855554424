/* eslint-disable */
/***
 *** Defining routes to pages
 ***/

import { lazy, Suspense } from 'react'
import {
  RouterProvider,
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useParams
} from 'react-router-dom'
import { Navigate } from 'react-router-dom'

// Global helper functions
import './analyses/Utilities/globalHelpers'

// =======
// Causal Studo Grouping
const ProjectView = lazy(() =>
  import('./analyses/Project/CausalStudio/HomeView/ProjectView').then((m) => ({
    default: m.ProjectView
  }))
)
const ProjectPermissions = lazy(() =>
  import(
    './analyses/Project/CausalStudio/PermissionsManagement/ProjectPermissions'
  ).then((m) => ({ default: m.ProjectPermissions }))
)
const CreationWizard = lazy(() =>
  import('./analyses/Project/CausalStudio/ItemCreation/CreationWizard').then(
    (m) => ({ default: m.CreationWizard })
  )
)

const ViewItem = lazy(() =>
  import('./analyses/Project/CausalStudio/ItemManagement/ItemView').then(
    (m) => ({ default: m.ViewItem })
  )
)
const SettingsEditor = lazy(() =>
  import('./admin/SettingsEditor').then((m) => ({ default: m.SettingsEditor }))
)
const RawItemViewer = lazy(() =>
  import('./analyses/Project/RawItemViewer').then((m) => ({
    default: m.RawItemViewer
  }))
)
const UserProfilePage = lazy(() =>
  import('./profile/profile.page').then((m) => ({
    default: m.ProfilePage
  }))
)

const HelpPage = lazy(() =>
  import('./help/help.page').then((m) => ({ default: m.HelpPage }))
)
const PlatformEmptyStatePage = lazy(() =>
  import('./empty/platform-empty-state.page').then((m) => ({
    default: m.PlatformEmptyStatePage
  }))
)

const WelcomePage = lazy(() =>
  import('./welcome/welcome.page').then((m) => ({ default: m.WelcomePage }))
)
const ActLoginPage = lazy(() =>
  import('./login/act.page').then((m) => ({ default: m.ActLoginPage }))
)
const IdpResponsePage = lazy(() =>
  import('./login/idpResponse.page').then((m) => ({
    default: m.IdpResponsePage
  }))
)

const ReportsPage = lazy(() =>
  import('./reports/reports.page').then((m) => ({ default: m.ReportsPage }))
)
const CreateReportPage = lazy(() =>
  import('./reports/create-report.page').then((m) => ({
    default: m.CreateReportPage
  }))
)
const ViewReportPage = lazy(() =>
  import('./reports/view-report.page').then((m) => ({
    default: m.ViewReportPage
  }))
)
const PageEditorPage = lazy(() =>
  import('./reports/editor/page-editor.page').then((m) => ({
    default: m.PageEditorPage
  }))
)
const CreatePagePage = lazy(() =>
  import('./reports/create-page.page').then((m) => ({
    default: m.CreatePagePage
  }))
)
const ManageDataPage = lazy(() =>
  import('./reports/dataset/manage-data.page').then((m) => ({
    default: m.ManageDataPage
  }))
)
const StandaloneReportBundlePage = lazy(() =>
  import('./reports/standalone-report/bundle.page').then((m) => ({
    default: m.StandaloneReportBundlePage
  }))
)
const DuplicateReportPage = lazy(() =>
  import('./reports/duplicate-report.page').then((m) => ({
    default: m.DuplicateReportPage
  }))
)

// Contexts
import { UserContextProvider } from './common/contexts/userContext'
import { RouterContext } from './common/contexts/routerContext'
import { CreateExecutiveSummaryPage } from './reports/create-executive-summary'
import { useContext, useEffect, useLayoutEffect } from 'react'
import { httpAuthService } from './common/services/http-auth.service'
import { sentryService } from './common/services/sentry.service'
import { SchemaContext, schemaLoader } from './common/contexts/schemaContext'

import { RequireAuth } from './route/auth/require'
import { GuardUserAdmin, GuardSiteAdmin } from './route/guard/admin'
import { NavbarLayout } from './route/layout/navbar'
import { GuardUserPermission } from './route/guard/permission'
import { ProvideAlerts } from './route/provider/alerts'
import { ProvideNotifications } from './route/provider/notifications'
import { ProvideRouteContext } from './route/provider/route-context'
import { ProvideWebsocketContext } from './route/provider/websocket'
import { Redirect } from './route/redirect'
import { FullPageLoader } from './common/components/full-page-loader'
import { ReportMetricsPage } from './reports/metrics/metrics.page'

function LazyPage({ component }) {
  return <Suspense fallback={<FullPageLoader />}>{component}</Suspense>
}

function RouterBase({ appSettings }) {
  const { router } = useContext(RouterContext)

  httpAuthService.initialize(router)
  sentryService.initialize(appSettings, router)

  // Dictionary to map pathname to desired page title
  const titles = {
    help: 'Help Page',
    reports: 'Report List',
    projects: 'Project List',
    admin: 'Admin',
    login: 'Login',
    'login-sso': 'Oauth Login',
    'login-act': 'Act Login'
  }

  // Pull out potential params, as they may differ through projects or reports
  const { reportId, id, itemId, pageId, parent } = useParams()
  const location = useLocation()
  const appSection = location.pathname.split('/')[1]

  // Update page title upon information change
  useLayoutEffect(() => {
    const isDev = process.env.NODE_ENV === 'development'
    const derivedId = reportId || id || itemId || pageId || parent
    if (!derivedId) {
      document.title =
        (isDev ? '[DEV] ' : '') + (titles[appSection] ?? 'Target RWE')
    }
  }, [reportId, location, id, itemId, pageId, parent])

  return (
    <UserContextProvider>
      <SchemaContext.Provider value={schemaLoader}>
        <Outlet />
      </SchemaContext.Provider>
    </UserContextProvider>
  )
}

export function AppRoutes({ appSettings }) {
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route element={<ProvideRouteContext />}>
            <Route path="/" element={<RouterBase appSettings={appSettings} />}>
              <Route
                index
                element={
                  <Redirect to={({ router }) => router.getUserHomeRoute()} />
                }
              />

              <Route element={<ProvideNotifications />}>
                <Route
                  path="/welcome"
                  element={<LazyPage component={<WelcomePage />} />}
                />
                <Route
                  path="/login-act"
                  element={<LazyPage component={<ActLoginPage />} />}
                />
                <Route
                  path="/idpresponse"
                  element={<LazyPage component={<IdpResponsePage />} />}
                />
                <Route
                  path="/login"
                  element={<Navigate to="/welcome" replace={true} />}
                />
              </Route>

              <Route element={<RequireAuth />}>
                <Route element={<ProvideWebsocketContext namespace="alerts" />}>
                  <Route element={<NavbarLayout />}>
                    <Route
                      element={
                        <ProvideAlerts
                          ignoreContexts={[
                            'report-data-import',
                            'report-data-export',
                            'standalone-report-bundle'
                          ]}
                        />
                      }
                    >
                      <Route element={<GuardUserAdmin />}>
                        <Route
                          path="/admin/settings"
                          element={<LazyPage component={<SettingsEditor />} />}
                        />
                      </Route>

                      <Route element={<GuardSiteAdmin />}>
                        <Route
                          path="/rawitem/:id"
                          element={<LazyPage component={<RawItemViewer />} />}
                        />
                      </Route>

                      <Route
                        path="/profile"
                        element={<LazyPage component={<UserProfilePage />} />}
                      />
                      <Route
                        path="/help"
                        element={<LazyPage component={<HelpPage />} />}
                      />
                      <Route
                        path="/empty"
                        element={
                          <LazyPage component={<PlatformEmptyStatePage />} />
                        }
                      />

                      <Route path="/reports" element={<ProvideNotifications />}>
                        <Route
                          index
                          element={<LazyPage component={<ReportsPage />} />}
                        />
                        <Route path=":reportId" element={<Outlet />}>
                          <Route
                            element={
                              <ProvideWebsocketContext namespace="item/:reportId" />
                            }
                          >
                            <Route
                              path="view"
                              element={
                                <LazyPage component={<ViewReportPage />} />
                              }
                            />
                            <Route
                              path="edit"
                              element={
                                <LazyPage component={<PageEditorPage />} />
                              }
                            />
                            <Route
                              path="pages/create"
                              element={
                                <LazyPage component={<CreatePagePage />} />
                              }
                            />
                            <Route
                              path="duplicate"
                              element={
                                <LazyPage component={<DuplicateReportPage />} />
                              }
                            />
                            <Route
                              path="create-executive-summary"
                              element={
                                <LazyPage
                                  component={<CreateExecutiveSummaryPage />}
                                />
                              }
                            />
                            <Route
                              path="data"
                              element={
                                <LazyPage component={<ManageDataPage />} />
                              }
                            />
                            <Route
                              path="standalone/bundles"
                              element={
                                <LazyPage
                                  component={<StandaloneReportBundlePage />}
                                />
                              }
                            />
                            <Route
                              path="metrics"
                              element={
                                <LazyPage component={<ReportMetricsPage />} />
                              }
                            />
                          </Route>
                        </Route>

                        <Route
                          element={
                            <div className="full-view-height">
                              <GuardUserPermission permission="report:create" />
                            </div>
                          }
                        >
                          <Route
                            path="create"
                            element={
                              <LazyPage component={<CreateReportPage />} />
                            }
                          />
                        </Route>
                      </Route>
                      <Route
                        element={
                          <div className="full-view-height">
                            <GuardUserPermission permission="causal_studio:read" />
                          </div>
                        }
                      >
                        <Route
                          path="/projects"
                          element={<ProvideNotifications />}
                        >
                          <Route
                            index
                            element={<LazyPage component={<ProjectView />} />}
                          />

                          <Route
                            path=":id"
                            element={<LazyPage component={<ProjectView />} />}
                          />
                          <Route
                            path=":id/permissions"
                            element={
                              <LazyPage component={<ProjectPermissions />} />
                            }
                          />
                          <Route
                            path="create/:type/:parent"
                            element={
                              <LazyPage component={<CreationWizard />} />
                            }
                          />
                          <Route
                            path="create/:type"
                            element={
                              <LazyPage component={<CreationWizard />} />
                            }
                          />
                          <Route
                            path="manage/:type/:itemId"
                            element={<LazyPage component={<ViewItem />} />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        )
      )}
    />
  )
}
