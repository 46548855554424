import { createUseStyles } from 'react-jss'
import { colors } from '../variables.style'
import { style as pageStyle } from './category-page.style'

export const style = {
  handle: {
    composes: 'flex-row',
    alignItems: 'center',
    padding: '8px'
  },
  actions: {
    composes: 'flex-row',
    alignItems: 'center',
    padding: '8px'
  },
  container: {
    composes: 'flex-row',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.background
  },
  details: {
    composes: 'flex-column',
    flex: 1,
    margin: '8px',
    rowGap: '8px',
    minWidth: 0
  },
  detailsUpdateForm: {
    extend: 'details',
    rowGap: '16px',
    '& .form-element-label': {
      margin: '0 0 8px 0'
    }
  },
  detailsUpdateFormActions: {
    composes: 'flex-row',
    justifyContent: 'end'
  },
  alias: {
    color: colors.fontAlt
  },
  draftIcon: pageStyle.draftIcon
}

export const useStyles = createUseStyles(style)
