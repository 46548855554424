import { createUseStyles } from 'react-jss'

export const style = {
  modal: {
    maxWidth: 480,
    width: '100%'
  },
  modalOverlay: {
    // The idle timer modal should lie over everything
    zIndex: 9999
  },
  modalBody: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Raleway, san-serif',
    '& p': {
      lineHeight: 1.5,
      fontSize: 18
    }
  },
  modalTitle: {
    textTransform: 'none'
  }
}

export const useStyles = createUseStyles(style)
