import moment from 'moment'
import { format, FormatSpecifier } from 'd3-format'
import currency from 'currency.js'
import {
  DEFAULT_PRECISION,
  FORMAT_TYPES,
  MAX_PRECISION,
  CURRENCY,
  CURRENCY_SYMBOL
} from '#common/src/report/format.constants'

// Currency Factory Functions
const USD = (value, precision) =>
  currency(value, { symbol: CURRENCY_SYMBOL.USD, precision })
const JPY = (value, precision) =>
  currency(value, { symbol: CURRENCY_SYMBOL.JPY, precision })
const EUR = (value, precision) =>
  currency(value, {
    symbol: CURRENCY_SYMBOL.EUR,
    precision,
    decimal: ',',
    separator: '.'
  })
const GBP = (value, precision) =>
  currency(value, { symbol: CURRENCY_SYMBOL.GBP, precision })

class FormatService {
  /**
   * Format value
   *
   * @param {any} value
   * @param {string} format
   * @param {object} options
   * @returns {string} Formatted string
   */
  formatValue (value, format, { precision, timeFormat, denomination } = {}) {
    switch (format) {
      case FORMAT_TYPES.NUMBER:
        return this.formatNumber(value, precision)
      case FORMAT_TYPES.PERCENT:
        return this.formatPercent(value, precision)
      case FORMAT_TYPES.DATE:
        return this.formatDate(value, timeFormat)
      case FORMAT_TYPES.CURRENCY:
        return this.formatCurrency(value, denomination)

      default:
        return (value ?? '').toString()
    }
  }

  /**
   * Format number
   *
   * @param {any} value
   * @param {number} precision
   * @returns {string} Formatted number
   */
  formatNumber (value, precision = DEFAULT_PRECISION) {
    // Limit precision
    precision = Math.min(precision, MAX_PRECISION)

    if (!isFiniteNumber(value)) {
      return (value ?? '').toString()
    }

    const smallestAtPrecision = Math.pow(10, -precision) / 2
    if (precision > 0 && value > 0 && value < smallestAtPrecision) {
      return '<' + format(`0.${precision}`)(Math.pow(10, -precision))
    }

    // Attempt to work around floating point rounding issues
    const workaroundValue = Number(
      Math.round(value + 'e' + precision) + 'e-' + precision
    )

    // Note: the above workaround leads to NaN when numbers are already represented by JS internally with e notation
    // so we're avoiding applying it when that happens
    if (!isNaN(workaroundValue)) {
      value = workaroundValue
    }

    // Handle edge case when precision is 6 or greater and the workaround above doesn't work
    if (precision >= 6 && value < 1e-6 && value >= 5e-7) {
      return '0.000001'
    }

    const formatSpec = new FormatSpecifier({
      comma: true,
      precision,
      trim: true,
      type: 'f'
    })
    return format(formatSpec)(value)
  }

  /**
   * Format percent
   *
   * @param {any} value
   * @param {string} precision
   * @returns {string} Formatted percent
   */
  formatPercent (value, precision = DEFAULT_PRECISION) {
    if (!isFiniteNumber(value)) {
      return (value ?? '').toString()
    }

    // using the exponential notation hack instead of multiplying by 100 to avoid floating point rounding issues
    return this.formatNumber(Number(value + 'e2'), precision) + '%'
  }

  /**
   * Used for formatting a number that will fit in a smaller space such as an axis or map key
   */
  formatShortenedValue (value, format, options = {}) {
    options = { ...options }
    const xLabelOverride = options.xLabels
    if (xLabelOverride && xLabelOverride.length > 0) {
      const findMatch =
        format === 'date'
          ? (item) => moment.utc(item.x).isSame(moment.utc(value))
          : (item) => String(item.x) === String(value)
      const match = xLabelOverride.find(findMatch)
      if (match) {
        return match.label
      }
    }
    if (Number.isInteger(options.precision)) {
      // Limit precision to 3
      options.precision = Math.min(options.precision, 3)
    }
    if (format === FORMAT_TYPES.NUMBER) {
      return this.formatShortenedNumber(value, options.precision)
    }
    if (format === FORMAT_TYPES.PERCENT) {
      return this.formatShortenedPercent(value, options.precision)
    }
    return this.formatValue(value, format, options)
  }

  /**
   * Format shortened number
   *
   * @param {any} value
   * @param {string} precision
   * @returns {string} Formatted shortened number
   */
  formatShortenedNumber (value, precision = 2) {
    if (!isFiniteNumber(value)) {
      return (value ?? '').toString()
    }

    if (value > 9999 || value < -9999) {
      return this.formatAbbreviated(value, precision)
    }

    return this.formatNumber(value, precision)
  }

  /**
   * Format shortened percent
   *
   * @param {any} value
   * @param {string} precision
   * @returns {string} Formatted shortened percent
   */
  formatShortenedPercent (value, precision) {
    if (!isFiniteNumber(value)) {
      return (value ?? '').toString()
    }

    return this.formatShortenedNumber(value * 100, precision) + '%'
  }

  /**
   * Format abbreviated
   *
   * @param {any} value
   * @param {string} precision
   * @returns {string} Formatted abbreviated
   */
  formatAbbreviated (value, precision) {
    const formatSpec = new FormatSpecifier({
      precision,
      trim: true,
      type: 's'
    })

    return formatSI(format(formatSpec)(value))
  }

  /**
   * Format shortened percent from fraction
   *
   * @param {number} numerator
   * @param {number} denominator
   * @returns {string} Formatted shortened percent from fraction
   */
  formatPercentShortFromFraction (numerator, denominator) {
    return this.formatPercent(numerator / denominator, 2)
  }

  /**
   * Format date
   *
   * @param {any} value
   * @param {string} timeFormat
   * @returns {string} Formatted date
   */
  formatDate (value, timeFormat = 'YYYY-MM-DD') {
    return moment.utc(value).format(timeFormat)
  }

  /**
   * Format currency
   *
   * @param {any} value
   * @param {string} denomination
   * @returns {string} Formatted currency value in accordance with denomination
   */
  formatCurrency (value, denomination) {
    if (!value || !denomination) {
      return ''
    }

    const precision = Number.isInteger(value) ? 0 : 2

    switch (denomination) {
      case CURRENCY.USD:
        return USD(value, precision).format()
      case CURRENCY.JPY:
        return JPY(value, precision).format()
      case CURRENCY.EUR:
        return EUR(value, precision).format()
      case CURRENCY.GBP:
        return GBP(value, precision).format()
      default:
        throw new Error(`Unsupported denomination: ${denomination}`)
    }
  }
}

/**
 * Format SI
 *
 * @param {string} value
 * @returns {string} Formatted SI value
 */
function formatSI (value) {
  // Uses International System of Units (SI) except for B instead of G
  return value.replace(/G/, 'B')
}

/**
 * Is Finite Number?
 *
 * @param {any} value
 * @returns {boolean}
 */
function isFiniteNumber (value) {
  if (value === null || isNaN(Number(value))) {
    return false
  }
  return Number.isFinite(Number(value))
}

export const formatService = new FormatService()
