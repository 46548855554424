import { useState, useEffect } from 'react'

import {
  useFloating,
  useInteractions,
  useHover,
  offset,
  autoUpdate,
  useFocus,
  useRole,
  useDismiss
} from '@floating-ui/react'

import { motion, AnimatePresence } from 'framer-motion'

export const GenericTooltip = function ({
  Popup,
  Target,
  placement = 'right',
  offsetArray = [0, 0],
  persist = false,
  mode = 'span',
  className = ''
}) {
  const [open, setOpen] = useState(false)

  const { x, y, strategy, refs, update, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset({ crossAxis: offsetArray[0], mainAxis: offsetArray[1] })
    ]
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: { open: 200 },
      restMs: 40
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context)
  ])
  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update)
    }
  }, [refs.reference, refs.floating, update, open])

  return (
    <>
      {mode === 'span' ? (
        <span
          className={className}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {Target}
        </span>
      ) : (
        <div
          className={className}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {Target}
        </div>
      )}

      <AnimatePresence>
        {(open || persist) && (
          <motion.div
            initial={{ opacity: 0, scale: 0.85 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 300 }}
            ref={refs.setFloating}
            {...getFloatingProps({
              ref: refs.setFloating,
              className: 'Tooltip',
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? ''
              }
            })}
            data-placement={placement}
          >
            {Popup}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
