import { memo, useState, useRef } from 'react'

import { useStyles } from './table-row.style'
import { TableDataCell } from './table-data-cell'
import { SparklineCell } from './cell-formats/sparkline-cell'

export const ROW_CLASSES = {
  ROW: 'row',
  STANDALONE: 'standalone'
}

const TableRow = ({
  rows,
  index,
  rowClass = 'row',
  columns,
  selectedRows = new Set(),
  section,
  shiftBy,
  onClick,
  style
}) => {
  const classes = useStyles()

  const row = rows[index]
  const [isRowHover, setIsRowHover] = useState(false)
  const rootRef = useRef()

  function handleLabelClick () {
    if (onClick) {
      onClick({ section, row })
    }
  }

  const columnWidth = columns.labelColumnWidth
  return (
    <div
      ref={rootRef}
      style={style}
      className={`${classes[rowClass]} ${
        selectedRows.has(row.key) ? classes.selectedRow : ''
      }`}
      onMouseOver={() => setIsRowHover(true)}
      onMouseLeave={() => setIsRowHover(false)}
      data-row-key={row.key}
    >
      <>
        {row.label !== undefined && (
          <div className={classes.rowLabelCell} style={{ width: columnWidth }}>
            <div
              className={onClick ? classes.rowLabelLink : classes.rowLabel}
              onClick={handleLabelClick}
            >
              {row.label}
            </div>
            {row.secondaryLabel && (
              <div className={classes.rowLabelSecondary}>
                <SecondaryLabel
                  definition={row.secondaryLabel}
                  columns={row.columns}
                />
              </div>
            )}
          </div>
        )}
        <div className={classes.dataCellOverflow}>
          <div
            className={classes.dataCellContainer}
            style={{ left: `${shiftBy}px` }}
          >
            {(row.columns || []).map((column, i) => (
              <TableDataCell
                key={`${i}-${column.key}`}
                parts={column.parts}
                rows={rows}
                rowIndex={index}
                columns={columns}
                colIndex={i}
                section={section}
                isRowHover={isRowHover}
                width={columns.dataColumnWidth}
                flexDefault={rowClass === ROW_CLASSES.STANDALONE ? 'auto' : 1}
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      </>
    </div>
  )
}

export const tableRowsAreEqual = (prev, next) => {
  const result =
    prev.shiftBy === next.shiftBy &&
    prev.index === next.index &&
    prev.rowClass === next.rowClass &&
    isDeepEqual(prev.columns, next.columns) &&
    isDeepEqual(prev.rows[prev.index], next.rows[next.index])

  if (!result) {
    // If false, no need to check anymore.
    return false
  } else if (
    prev.selectedRows === undefined &&
    next.selectedRows !== undefined
  ) {
    return false
  } else if (
    prev.selectedRows !== undefined &&
    next.selectedRows === undefined
  ) {
    return false
  } else if (
    prev.selectedRows !== undefined &&
    next.selectedRows !== undefined
  ) {
    return isDeepEqual(
      Array.from(prev.selectedRows),
      Array.from(next.selectedRows)
    )
  } else {
    return true
  }
}

export const TableRowMemo = memo(TableRow, tableRowsAreEqual)

function SecondaryLabel ({ definition = {}, columns }) {
  if (definition.key !== 'sparkline') {
    return null
  }

  const { variable: sparklineVariable } = definition
  const sparklineValues = columns.reduce((sparklineValue, column) => {
    // Nothing to add if no parts
    if (!column.parts || column.parts.length === 0) {
      return sparklineValue
    }

    let columnPart
    // Backwards compatible - default to first column value
    if (sparklineVariable === true) {
      columnPart = column.parts[0]
    } else {
      // Find the column part based on variable name
      columnPart = column.parts.find(({ variables }) => {
        return variables.includes(sparklineVariable)
      })
    }

    if (columnPart) {
      const value =
        columnPart.values[columnPart.variables.indexOf(sparklineVariable)]

      if (value !== undefined && typeof value === 'number') {
        sparklineValue.push(value)
      }
    }
    return sparklineValue
  }, [])

  return (
    <SparklineCell
      values={sparklineValues}
      formatOptions={definition.formatOptions}
    />
  )
}

function isDeepEqual (val1, val2) {
  return JSON.stringify(val1) === JSON.stringify(val2)
}
