import { createUseStyles } from 'react-jss'
import { colors, sizes } from '../../variables.style'

export const style = {
  input: {
    height: sizes.textInputHeight,
    padding: `0 ${sizes.formElementPadding}`
  },
  inputMedium: {
    height: sizes.buttonHeight,
    padding: `0 ${sizes.formElementMediumPadding}`
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    columnGap: '12px',
    padding: '12px',
    '&.error': {
      color: colors.error
    },
    '&.info': {
      color: colors.info
    },
    '&.success': {
      color: colors.success
    },
    '&.pending': {
      color: colors.pending
    },
    '&.warning': {
      color: colors.warning
    }
  }
}

export const useStyles = createUseStyles(style)
