export type Value = string | number

export interface TableDataColumnGroup {
  label: string
  columnCount: number
}

export interface TableDataColumn {
  key: string
  label: string
}

export interface TableDataRowColumnParts {
  values: Value[]
  variables?: string[]
}

export interface TableDataRowColumn {
  parts: TableDataRowColumnParts[]
}

export interface TableDataRow {
  label: string
  columns: TableDataRowColumn[]
}

export interface TableDataSection {
  label: string
  rows: TableDataRow[]
}

export interface TableData {
  columns: TableDataColumn[]
  rows: TableDataRow[]
  columnGroups?: TableDataColumnGroup[]
  sections?: TableDataSection[]
}

export interface GetExportRowsOptions {
  includeVariableColumn?: boolean
  section?: string
}

export const LABEL = {
  GROUP: 'Group',
  SECTION: 'Section',
  VARIABLE: ''
}

export class CsvExportService {
  /**
   * Passes in tableData object and returns a CSV string
   *
   * @param {TableData} tableData Table data
   * @returns {string} CSV formatted string
   */
  mapTableDataToCsv (tableData: TableData): string {
    let headers = tableData.columns.map((column) => column.label)

    const includeVariableColumn = this.hasMultiVarColumns(tableData)
    if (includeVariableColumn) {
      // Insert column to display variable name
      headers.splice(1, 0, LABEL.VARIABLE)
    }

    const includeSectionColumn = tableData.sections?.length > 0
    let rows: Value[][] = this.getExportRows(
      tableData.rows,
      includeSectionColumn
        ? {
            includeVariableColumn,
            section: null
          }
        : {
            includeVariableColumn
          }
    )
    if (includeSectionColumn) {
      // Add a section header
      headers = [LABEL.SECTION, ...headers]

      rows = [
        ...rows,
        ...tableData.sections
          .map((section) =>
            this.getExportRows(section.rows, {
              includeVariableColumn,
              section: section.label
            })
          )
          .flat()
      ]
    }

    if (tableData.columnGroups?.length > 0) {
      const groups = tableData.columnGroups.reduce(
        (groups, { columnCount, label }) => [
          ...groups,
          ...Array(columnCount).fill(label)
        ],
        []
      )
      if (groups.length > 0) {
        // Add a row after the header row for groups
        let row = [LABEL.GROUP, ...groups]
        if (tableData.sections?.length > 0) {
          // Shift columns to make room for section names
          row = ['', ...row]
        }
        rows.unshift(row)
      }
    }

    return this.toCsv([headers, ...rows])
  }

  /**
   * Get export rows for table data rows
   *
   * @param {TableDataRow[]} tableRows Table data row
   * @param {ExportedRowOptions} options Export options
   * @returns {Value[][]} Export rows
   */
  getExportRows (
    tableRows: TableDataRow[],
    { includeVariableColumn = false, section }: GetExportRowsOptions = {}
  ): Value[][] {
    return tableRows.reduce((rows, { label, columns }) => {
      const variableRows = new Map<string, Value[]>()

      for (const [columnIndex, column] of columns.entries()) {
        let columnValueIdx = 0
        const parts = column.parts ?? []
        for (const { values = [], variables = [] } of parts) {
          for (const [valueIndex, value] of values.entries()) {
            if (value || value === 0) {
              const variable =
                variables[valueIndex] ?? `value_${columnValueIdx}`
              let variableRow = variableRows.get(variable)
              if (!variableRow) {
                // Create a new variable row with empty placeholder values for each column
                variableRow = Array(columns.length).fill('')

                variableRows.set(variable, variableRow)
              }

              // Replace empty placeholder value at column index with part value
              variableRow.splice(columnIndex, 1, value)
            }

            columnValueIdx++
          }
        }
      }

      for (const [variable, variableRow] of variableRows.entries()) {
        const row = []

        if (section !== undefined) {
          // Include section
          row.push(section)
        }

        // Row label
        row.push(label)

        if (includeVariableColumn) {
          // Only include variable name if including more than one variable
          row.push(variable)
        }

        // Include all variable row columns
        row.push(...variableRow)

        rows.push(row)
      }

      return rows
    }, [])
  }

  /**
   * Converts an array of arrays into CSV format and handles commas and backspaces,
   * if they were to appear in the dataset
   *
   * @param {Value[][]} rows Rows of column values
   * @returns {string} CSV formatted string
   */
  toCsv (rows: Value[][]): string {
    const csvRows = rows.map((row) => {
      return row
        .map((value) => {
          if (value === null || value === undefined) {
            value = ''
          }
          value = value.toString()

          // Escape backslashes in the data
          value = value.replace(/\\/g, '\\\\')

          // Escape double quotes in the data
          value = value.replace(/"/g, '\\"')

          // Wrap the value in double quotes
          return `"${value}"`
        })
        .join(',')
    })

    return csvRows.join('\r\n')
  }

  /**
   * Does the table data have multi var columns?
   *
   * @param {TableData} tableData Table data
   * @returns {boolean}
   */
  hasMultiVarColumns (tableData: TableData): boolean {
    let rows = tableData.rows
    if (tableData.sections) {
      rows = [...rows, ...tableData.sections.map(({ rows }) => rows).flat()]
    }

    return rows.some(({ columns }) =>
      columns.some(
        ({ parts }) => parts?.length > 1 || parts[0]?.values?.length > 1
      )
    )
  }
}

export const csvExportService = new CsvExportService()
