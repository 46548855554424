import { createUseStyles } from 'react-jss'

export const style = {
  container: {
    position: 'relative'
  },
  positionDiv: {
    position: 'absolute',
    width: 10,
    height: 10,
    pointerEvents: 'none',
    backgroundColor: 'transparent'
  }
}

export const useTooltipContextStyles = createUseStyles(style)
