/**
 * Websocket event service
 */
export class WebsocketEventService {
  /**
   * Listen for an event
   *
   * @param {Socket} socket
   * @param {object} event
   * @param {string} event.name Event name
   * @param {string} [event.room] Event room
   * @param {(..args[]) => void} onEvent
   */
  on (socket, event, onEvent) {
    const { room, name } = event

    if (room) socket.emit('join', { room })
    socket.on(name, onEvent)
  }

  /**
   * Stop listening for an event
   *
   * @param {Socket} socket Socket
   * @param {object} event
   * @param {string} event.name Event name
   * @param {string} [event.room] Event room
   * @param {(..args[]) => void} onEvent
   */
  off (socket, event, onEvent) {
    const { room, name } = event

    socket.off(name, onEvent)
    if (room) socket.emit('leave', { room })
  }
}

export const websocketEventService = new WebsocketEventService()
