import { MouseEventHandler } from 'react'
import { Tooltip } from './Tooltip'
import { useStyles } from './button.style'

interface ButtonProps {
  children?: any
  type?: 'button' | 'submit' | 'reset'
  className?: string
  variant?:
    | 'primary'
    | 'secondary'
    | 'link'
    | 'link-sans-decoration'
    | 'inline-link'
    | 'mini'
    | 'mini-page-list'
    | 'mini-secondary'
    | 'icon-link'
    | 'icon-secondary'
  size?: string
  tooltipLabel?: string
  tooltipDelay?: number
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const Button = function ({
  children,
  type = 'button',
  className = '',
  variant = 'primary',
  size = '',
  tooltipLabel = '',
  tooltipDelay,
  ...props
}: ButtonProps) {
  const classes = useStyles()

  let variantClass
  let sizeClass = ''

  switch (variant) {
    case 'link':
      variantClass = classes.link
      break
    case 'link-sans-decoration':
      variantClass = classes.linkSansDecoration
      break
    case 'inline-link':
      variantClass = classes.inlineLink
      break
    case 'secondary':
      variantClass = classes.secondary
      break
    case 'mini':
      variantClass = classes.mini
      break
    case 'mini-page-list':
      variantClass = classes.miniPageList
      break
    case 'mini-secondary':
      variantClass = classes.miniSecondary
      break
    case 'icon-link':
      variantClass = classes.link
      break
    case 'icon-secondary':
      variantClass = classes.iconSecondary
      break
    default:
      variantClass = classes.button
  }

  switch (size) {
    case 'large':
      sizeClass = classes.large
      break
  }

  const button = (
    <button
      type={type}
      className={[variantClass, sizeClass, className]
        .filter((className) => className)
        .join(' ')}
      {...props}
    >
      {children}
    </button>
  )

  return tooltipLabel && !props.disabled ? (
    <Tooltip
      title={tooltipLabel}
      placement="top"
      enterDelay={tooltipDelay}
      enterNextDelay={tooltipDelay}
    >
      {button}
    </Tooltip>
  ) : (
    button
  )
}
