import { useState } from 'react'
import { LinePath } from '@visx/shape'
import { scaleLinear } from '@visx/scale'
import { localPoint } from '@visx/event'
import { extent } from 'd3-array'

import { useTooltipDisplayContext } from '../../common/tooltip/tooltip.context'
import { colors } from '#base/js/variables.style'

import { cellFormatService } from './cell-format.service'

import { useStyles } from './sparkline-cell.style'

const SPARKLINE_WIDTH = 60
const SPARKLINE_HEIGHT = 17
const VERTICAL_PADDING = 3
const HORIZONTAL_PADDING = 3

export const SparklineCell = ({ values, ...props }) => {
  if (Array.isArray(values) && values.length === 0) {
    return null
  }

  const xScales = scaleLinear({
    domain: [0, values.length - 1],
    range: [0 + HORIZONTAL_PADDING, SPARKLINE_WIDTH - HORIZONTAL_PADDING]
  })
  const yScales = scaleLinear({
    domain:
      props.formatOptions && props.formatOptions.enableaxis
        ? extent([...values, 0])
        : extent(values),
    range: [SPARKLINE_HEIGHT - VERTICAL_PADDING, 0 + VERTICAL_PADDING]
  })

  return (
    <Sparkline data={values} xScales={xScales} yScales={yScales} {...props} />
  )
}

function Sparkline ({
  data,
  xScales,
  yScales,
  formatOptions,
  isInDataColumn = false
}) {
  const classes = useStyles()
  const [, setTooltipDisplayData] = useTooltipDisplayContext()
  const [highlightPoint, setHighlightPoint] = useState()

  function showTooltip (event) {
    const { x } = localPoint(event.currentTarget, event)
    const xValueExact = xScales.invert(x)

    let xIndex = Math.round(xValueExact)
    if (xIndex < 0) {
      xIndex = 0
    } else if (xIndex > data.length - 1) {
      xIndex = data.length - 1
    }

    setHighlightPoint(xIndex)
    setTooltipDisplayData({
      primaryValue: cellFormatService.formatValues(data[xIndex], formatOptions)
    })
  }

  function hideTooltip () {
    setHighlightPoint()
    setTooltipDisplayData()
  }

  if (!data || data.length === 0) {
    return null
  }

  const shouldShowPoints = data.length < 10

  return (
    <div
      className={`${
        isInDataColumn ? classes.svgContainerInDataColumn : classes.svgContainer
      }`}
    >
      <svg
        className={classes.svg}
        viewBox={`0 0 ${SPARKLINE_WIDTH} ${SPARKLINE_HEIGHT}`}
        preserveAspectRatio="xMidYMid meet"
        width="100%"
        height="100%"
        onMouseMove={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {formatOptions.enableaxis && (
          <line
            className={classes.xAxis}
            x1={0}
            y1={yScales(0)}
            x2={SPARKLINE_WIDTH}
            y2={yScales(0)}
          />
        )}
        <LinePath
          data={data}
          x={(point, index) => xScales(index)}
          y={(point) => yScales(point)}
          className={classes.line}
          stroke={colors.brandAccent}
        />
        <g>
          {shouldShowPoints &&
            data.map((point, index) => (
              <circle
                key={index}
                r={2}
                fill={colors.background}
                stroke={colors.brandAccent}
                strokeWidth={1}
                cx={xScales(index)}
                cy={yScales(point)}
              />
            ))}
          {highlightPoint !== undefined && (
            <circle
              r={2}
              fill={colors.brandAccent}
              stroke={colors.brandAccent}
              strokeWidth={1}
              cx={xScales(highlightPoint)}
              cy={yScales(data[highlightPoint])}
            />
          )}
        </g>
      </svg>
    </div>
  )
}
