import MuiTooltip from '@mui/material/Tooltip'
import { styles } from './Tooltip.style'

export const Tooltip = ({
  arrow = true,
  type = 'default',
  disable = false,
  disableInteractive = true,
  children,
  ...props
}) => {
  if (disable) {
    return children
  }

  return (
    <MuiTooltip
      {...props}
      arrow={arrow}
      disableInteractive={disableInteractive}
      componentsProps={styles[type]}
    >
      {children}
    </MuiTooltip>
  )
}
