import { createUseStyles } from 'react-jss'

import { colors } from '../variables.style'

export const style = {
  executiveSummary: {
    marginTop: '24px',
    marginLeft: '16px'
  },
  tocContainer: {
    backgroundColor: colors.background
  },
  newCategoryContainer: {
    composes: 'flex-row flex-center-items',
    border: `2px dashed ${colors.border}`,
    borderRadius: 4,
    height: 80,
    marginTop: 60
  },
  newCategory: {
    composes: 'flex-row',
    alignItems: 'center',
    flex: 1,
    padding: 16
  },
  newCategoryField: {
    flex: 1
  },
  newCategoryButton: {
    marginLeft: 16
  }
}

export const useStyles = createUseStyles(style)
