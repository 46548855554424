import { colors } from '../../variables.style'
import { createUseStyles } from 'react-jss'

export const style = {
  backLink: {
    '&:hover': {
      color: colors.brandPrimary,
      borderColor: `${colors.brandPrimary}`,
      zIndex: '5'
    },
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px',
    border: '1px solid black',
    borderRadius: '5px 0px 0px 5px'
  },
  noTocRadius: {
    borderRadius: '5px 5px 5px 5px'
  },
  backIcon: {
    fontSize: '18px',
    margin: '0px 6px 0 0'
  },
  tocMenuCaret: {
    fontSize: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '7px',
    marginRight: '6px'
  },
  backText: {
    margin: 'auto 0',
    paddingBottom: '2px'
  },
  hoverAnchor: {
    cursor: 'pointer'
  },
  backButton: {
    overflow: 'hidden',
    display: 'flex'
  },
  tocList: {
    visibility: 'hidden',
    position: 'absolute',
    backgroundColor: '#f1f1f1',
    width: 'calc(100vw * 0.5)',
    minWidth: '260px',
    maxWidth: '510px',
    boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
    zIndex: '1',
    opacity: 0,
    maxHeight: 'calc(100vh * 0.65)',
    overflowY: 'auto'
  },
  tocVisible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.2s'
  },
  tocDivision: {
    display: 'inline-flex',
    border: '1px solid black',
    marginLeft: '-1px',
    alignItems: 'center',
    borderRadius: '0px 5px 5px 0px',
    '&:hover': {
      color: colors.brandPrimary,
      borderColor: `${colors.brandPrimary}`
    }
  }
}

export const useStyles = createUseStyles(style)
