import { createUseStyles } from 'react-jss'
import { colors, zIndexes } from '#base/js/variables.style'

export const style = {
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    background: 'white',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    '&:hover': {
      borderColor: colors.brandAccent,
      boxShadow: 'none'
    }
  },
  button: {
    padding: '5px',
    fontSize: '16px',
    width: '30px',
    height: '30px'
  },
  menu: {
    flex: '1 0 0'
  },
  select: {
    display: 'flex'
  },
  separator: {
    alignSelf: 'stretch',
    background: colors.border,
    width: '1px',
    zIndex: zIndexes.filterSelectSeparator
  }
}

export const useStyles = createUseStyles(style)
