/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios'

type DoneCb = (err: Error | null, data?: any) => void
type Body = string | FormData

type Method = 'GET' | 'POST'

class GlobalHelpers {
  static async ajax (
    method: Method,
    url: string,
    body: Body | null,
    cb: DoneCb
  ) {
    const { data, error } = await GlobalHelpers.ajaxPromise(method, url, body)
    cb(error, data)
  }

  static async ajaxPromise (
    method: Method,
    url: string,
    body: Body | null,
    config?: any
  ): Promise<{ data?: any; error: Error | null }> {
    try {
      let resp
      switch (method.toUpperCase()) {
        case 'GET':
          resp = await axios.get(url, config)
          break
        case 'POST':
          resp = await axios.post(url, body, config)
          break
        default:
          throw Error(
            `Unsupported method type in GlobalHelpers.ajax...: "${method}"`
          )
      }
      return { data: resp.data, error: null }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return { error: err }
    }
  }

  // Non json body. Only invocation is to post FormDatam, which axios should correctly detect
  static async ajaxWithBody (
    method: Method,
    url: string,
    body: FormData,
    cb: DoneCb
  ) {
    return GlobalHelpers.ajax(method, url, body, cb)
  }

  static async ajaxRaw (method: Method, url: string, body: any, cb: DoneCb) {
    return GlobalHelpers.ajax(method, url, body, cb)
  }

  static getAPIBaseURL = function () {
    // @ts-ignore
    const host = __CONFIG__.apiHost || window.location.hostname
    const baseURL =
      // @ts-ignore
      window.location.protocol + '//' + host + ':' + __CONFIG__.apiPort + '/'
    return baseURL
  }
}

globalThis.ajax = GlobalHelpers.ajax
globalThis.ajaxPromise = GlobalHelpers.ajaxPromise
globalThis.ajaxWithBody = GlobalHelpers.ajaxWithBody
globalThis.ajaxRaw = GlobalHelpers.ajaxRaw
globalThis.getAPIBaseURL = GlobalHelpers.getAPIBaseURL
