import { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWarning,
  faInfoCircle,
  faCircleCheck,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'

class Notification extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    title: PropTypes.node,
    message: PropTypes.node.isRequired,
    timeOut: PropTypes.number,
    progress: PropTypes.number,
    onClick: PropTypes.func,
    onRequestHide: PropTypes.func
  }

  static defaultProps = {
    type: 'info',
    title: null,
    message: null,
    timeOut: 5000,
    progress: null,
    onClick: () => {},
    onRequestHide: () => {}
  }

  componentDidMount = () => {
    const { timeOut } = this.props
    if (timeOut !== 0) {
      this.timer = setTimeout(this.requestHide, timeOut)
    }
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.requestHide()
  }

  handleClick = () => {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
    this.requestHide()
  }

  requestHide = () => {
    const { onRequestHide } = this.props
    if (onRequestHide) {
      onRequestHide()
    }
  }

  render () {
    const faIconsMap = {
      info: faInfoCircle,
      success: faCircleCheck,
      warning: faWarning,
      error: faTriangleExclamation
    }
    const { type, message, progress } = this.props
    let { title } = this.props
    const className = classnames(['notification', `notification-${type}`])
    title = title ? <div className="title">{title}</div> : null
    return (
      <div className={className} onClick={this.handleClick}>
        <FontAwesomeIcon
          className="notification-icon"
          icon={faIconsMap[type]}
        />
        <div className="notification-message" role="alert">
          {title}
          <div className="message" data-qa="notification-message">
            {message}
          </div>
          {progress !== null ? <div className="progress">{progress}</div> : ''}
        </div>
      </div>
    )
  }
}

export default Notification
