import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import Root from './Root'
import { httpService, HTTP_MODE } from './common/services/http.service'
import { httpAuthService } from './common/services/http-auth.service'
import { errorHandlerService } from './common/services/error-handler.service'
import { idleTimeoutManagerService } from './common/services/idle-timeout-manager.service'
import { appSettingsService } from './common/services/app-settings.service'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import reducers from './redux/reducers'

async function applyAppSettings () {
  let appSettings

  try {
    // Initialize HTTP calls - when in the full app, we set the mode to call the API
    httpService.initialize(HTTP_MODE.API)
    httpAuthService.initialize() // Adds 401 Interceptor w/ API retry

    const response = await axios.get(
      `${httpService.getBaseURL()}api/app-settings`
    )
    appSettings = response.data
    appSettingsService.setCache(appSettings)
    const {
      enableSentry,
      inactiveSessionTimeout,
      inactiveSessionWarningTimeout
    } = appSettings
    if (enableSentry) {
      // Turn on error tracking for sentry - perf tracing is enabled in the router
      Sentry.init({
        dsn: __CONFIG__.sentryDsn,
        release: __CONFIG__.sentryRelease
      })
    }

    // Setup idle timeout manager if a session timeout property is set.
    if (inactiveSessionTimeout && !isNaN(inactiveSessionTimeout)) {
      idleTimeoutManagerService.init({
        timeout: inactiveSessionTimeout,
        warningTimeout: inactiveSessionWarningTimeout
      })
    }
  } catch (error) {
    errorHandlerService.handleServerError(error)
  }

  return appSettings
}

applyAppSettings().then(function (appSettings) {
  const container = document.getElementById('app-container')
  const root = createRoot(container)
  const store = configureStore({
    reducer: reducers
  })
  root.render(
    <Provider store={store}>
      <Root appSettings={appSettings} />
    </Provider>
  )
})
