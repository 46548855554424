import { CSSProperties } from 'react'
import { ChartSizer } from '#base/js/reports/charts/common/chart-sizer'
import {
  TableData,
  TableDataRow,
  TableDataSection
} from '#base/js/reports/blocks/csv-export.service'
import { Table } from './table'

export interface OnClickEventParams {
  row: TableDataRow
  section?: TableDataSection
}

export interface Props {
  dataKey: string
  tableData: TableData
  isDownloadable?: boolean
  selectedRows?: Set<string>
  width?: string | number
  height?: string | number
  labelColumnWidth?: string | number
  dataColumnWidth?: string | number
  disableSort?: boolean
  noSortColumns?: string[]
  isChartTable?: boolean
  isPreviewMode?: boolean
  style?: CSSProperties
  onClick?: ({ section, row }: OnClickEventParams) => void
}

export function DataTable (props: Props) {
  return (
    <ChartSizer
      heightWidthRatio={0.5}
      heightViewportRatio={0.9}
      maxHeight={400}
    >
      {({ width, height }) => <Table width={width} {...props} />}
    </ChartSizer>
  )
}
