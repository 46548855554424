import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Switch } from '#base/js/common/components/switch'
import { Select } from '#base/js/common/components/select'
import { BackLink } from '#base/js/common/components/back-link'
import { FullPageLoader } from '#base/js/common/components/full-page-loader'
import { RouterContext } from '#base/js/common/contexts/routerContext'
import { appSettingsService } from '#base/js/common/services/app-settings.service'
import { errorHandlerService } from '#base/js/common/services/error-handler.service'
import { Report } from '../report.model'
import { reportService } from '../report.service'
import { reportMetricsService, ReportVisitOptions } from './metrics.service'

import { useStyles } from './metrics.page.style'
import { DataTable } from '../blocks/table/data-table'
import { INTERVAL } from './constants'

const timeIntervalOptions = [
  { value: INTERVAL.DAY, label: 'Day' },
  { value: INTERVAL.MONTH, label: 'Month' },
  { value: INTERVAL.YEAR, label: 'Year' }
]

export function ReportMetricsPage () {
  const classes = useStyles()
  const { router } = useContext(RouterContext)
  const shouldShowEmployeeFilter = appSettingsService.get(
    'showReportMetricsDomainFilter'
  )

  const [isLoading, setIsLoading] = useState(true)
  const [report, setReport] = useState()
  const [tableData, setTableData] = useState()
  const [startDate, setStartDate] = useState(
    moment.utc().subtract(3, 'months').toDate()
  )
  const [endDate, setEndDate] = useState(moment.utc().toDate())
  const [timeInterval, setTimeInterval] = useState(INTERVAL.MONTH)
  const [uniqueVisitors, setUniqueVisitors] = useState(false)
  const [filterEmployees, setFilterEmployees] = useState(true)

  const { reportId } = useParams()

  async function loadReportData () {
    const data = await reportService.getReport(reportId)

    const report = new Report(data)
    setReport(report)
    return report
  }

  async function loadMetrics (report) {
    const options: ReportVisitOptions = {
      startDate,
      endDate,
      uniqueVisitors
    }

    if (shouldShowEmployeeFilter) {
      options.filterEmployees = filterEmployees
    }

    const metrics = await reportMetricsService.getVisits(
      reportId,
      timeInterval,
      options
    )

    const tableData = reportMetricsService.mapToTable(metrics, report)
    setTableData(tableData)
  }

  async function load () {
    setIsLoading(true)

    try {
      const report = await loadReportData()
      await loadMetrics(report)
    } catch (error) {
      errorHandlerService.handleServerError(error, ({ status }) =>
        status === 404
          ? 'Sorry, either you do not have access to this report or it simply does not exist. Check again?'
          : 'Sorry, we are having some problems loading this report. Try again?'
      )
      router.navigate(router.getReportViewRoute(report))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    load()
  }, [reportId])

  useEffect(() => {
    if (report) {
      loadMetrics(report)
    }
  }, [startDate, endDate, timeInterval, uniqueVisitors, filterEmployees])

  if (isLoading) {
    return <FullPageLoader />
  }

  return (
    <div className="page-layout">
      <div className="page-header">
        <div className="page-header-left flex-row">
          <BackLink to={router.getReportViewRoute(report)} />
        </div>
      </div>
      <div className="page-content">
        <div className={classes.content}>
          <h1 className="h1">Report Metrics</h1>
          <div className={classes.controlBar}>
            <div className={classes.datePickerControl}>
              <label>Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                shouldCloseOnSelect={true}
                closeOnScroll={true}
                selectsStart
                maxDate={endDate}
                showMonthDropdown
                showYearDropdown
                withPortal
              />
            </div>
            <div className={classes.datePickerControl}>
              <label>End Date</label>
              <DatePicker
                selected={endDate}
                onChange={setEndDate}
                shouldCloseOnSelect={true}
                closeOnScroll={true}
                selectsEnd
                minDate={startDate}
                showMonthDropdown
                showYearDropdown
                withPortal
              />
            </div>
            <div className={classes.control}>
              <Select
                label="Time Interval"
                size="medium"
                options={timeIntervalOptions}
                value={timeIntervalOptions.find(
                  ({ value }) => value === timeInterval
                )}
                onChange={({ value }) => {
                  setTimeInterval(value)
                }}
              />
            </div>
            <div className={classes.switchControl}>
              <label>Unique Visits</label>
              <Switch
                isOn={uniqueVisitors}
                onToggle={() => {
                  setUniqueVisitors(!uniqueVisitors)
                }}
              />
            </div>
            {shouldShowEmployeeFilter && (
              <div className={classes.switchControl}>
                <label>Filter Employees</label>
                <Switch
                  isOn={filterEmployees}
                  onToggle={() => {
                    setFilterEmployees(!filterEmployees)
                  }}
                />
              </div>
            )}
          </div>
          {tableData && (
            <div>
              <DataTable
                dataKey="report_metrics"
                tableData={tableData}
                isDownloadable={true}
                dataColumnWidth={100}
              />
              <div id={'tooltip-container'}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
