import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'
import { borderStyle, paddingRight } from './table.style'

export const style = {
  row: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: borderStyle,
    paddingRight,

    '&:nth-child(odd):not($selectedRow):not($standalone)': {
      backgroundColor: colors.tableRowOdd
    },
    '&:hover': {
      boxShadow: '0px 0px 5px gray',
      zIndex: 1,
      transform: 'scale(1)' // required to get the box-shadow to work on the <tr>
    }
  },
  selectedRow: {
    backgroundColor: colors.brandHighlight
  },
  emptyRow: {
    height: 27,
    width: '100%'
  },
  rowLabelCell: {
    display: 'flex',
    flex: '1 0 auto',
    padding: '5px 40px 5px 20px'
  },
  rowLabel: {
    flex: '1 1 auto',
    '&:not(:last-child)': {
      paddingRight: 16
    }
  },
  rowLabelLink: {
    extend: 'rowLabel',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: colors.brandPrimary
    }
  },
  rowLabelSecondary: {
    flex: '0 0 75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dataCellContainer: {
    flex: '1 0 auto',
    display: 'inline-flex',
    position: 'relative',
    transition: 'left 200ms'
  },
  dataCellOverflow: {
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden'
  },

  // Special Row Classes

  standalone: {
    extend: 'row',
    borderBottom: 'none',
    marginTop: 16,

    '& $rowLabelCell': {
      fontFamily: "'Raleway', sans-serif",
      fontVariantNumeric: 'lining-nums',
      textTransform: 'uppercase'
    }
  }
}

export const useStyles = createUseStyles(style)
