import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { UserContext } from '#base/js/common/contexts/userContext'
import { RouterContext } from '#base/js/common/contexts/routerContext'

export function GuardUserAdmin () {
  const { user } = useContext(UserContext)
  const { router } = useContext(RouterContext)

  return user && (user.isSiteAdmin() || user.isUserAdmin()) ? (
    <Outlet />
  ) : (
    <Navigate replace to={router.getUserHomeRoute()} />
  )
}

export function GuardSiteAdmin () {
  const { user } = useContext(UserContext)
  const { router } = useContext(RouterContext)

  return user && user.isSiteAdmin() ? (
    <Outlet />
  ) : (
    <Navigate replace to={router.getUserHomeRoute()} />
  )
}
