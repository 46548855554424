import { Fragment, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from './back-link.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCaretDown } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown as faCaretDownSolid } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip } from '#base/js/common/components/Tooltip'
import { TocList } from '#base/js/reports/toc-list'

export const BackLink = function ({
  showToc = false,
  report = {},
  to,
  label = 'Back',
  className = '',
  onClick = () => {},
  tocPageMode,
  ...props
}) {
  const classes = useStyles()

  const [tocVisible, setTocVisible] = useState(false)
  const [solidIcon, setSolidIcon] = useState(false)
  const [filteredReportGroups, setFilteredReportGroups] = useState(
    report.groups ?? []
  )

  function toggleToc () {
    setTocVisible(!tocVisible)
  }

  useEffect(() => {
    if (report.groups) {
      setFilteredReportGroups(report.groups)
    }
  }, [report])

  /**
   * Hook closes TOC menu when user clicks outside of it
   */
  function useDetectOutsideClick (ref) {
    useEffect(() => {
      /**
       * Close TOC if user clicks outside of it
       */
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setTocVisible(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      // Cleanup
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const tocWrapper = useRef(null)
  useDetectOutsideClick(tocWrapper)

  return (
    <Fragment>
      {to ? (
        <div ref={tocWrapper} style={{ marginRight: '15px' }}>
          <div className={classes.backButton}>
            <Link
              to={to}
              className={
                showToc && filteredReportGroups.length > 0
                  ? `${classes.backLink} flex-row undecorated-link ${className}`
                  : `${classes.backLink} ${classes.noTocRadius} flex-row undecorated-link ${className}`
              }
              {...props}
            >
              <FontAwesomeIcon
                size={'sm'}
                className={classes.backIcon}
                icon={faChevronLeft}
              />
              <span className={classes.backText}>{label}</span>
            </Link>
            {showToc && filteredReportGroups.length > 0 ? (
              <Tooltip
                title="Table of Contents"
                placement={tocVisible ? 'right' : 'bottom'}
              >
                <div
                  onMouseEnter={() => setSolidIcon(true)}
                  onMouseLeave={() => setSolidIcon(false)}
                  data-qa="open-toc-menu-button"
                  onClick={toggleToc}
                  className={classes.tocDivision}
                >
                  <FontAwesomeIcon
                    size={'sm'}
                    className={classes.tocMenuCaret}
                    icon={solidIcon ? faCaretDownSolid : faCaretDown}
                  />
                </div>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          {showToc && filteredReportGroups.length > 0 ? (
            <TocList
              showExecSummary={true}
              report={report}
              className={
                tocVisible
                  ? `${classes.tocList} ${classes.tocVisible}`
                  : classes.tocList
              }
              filteredToc={filteredReportGroups}
              pageMode={tocPageMode}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        <a
          className={`${classes.backLink} ${classes.hoverAnchor} flex-row undecorated-link`}
          onClick={onClick}
          {...props}
        >
          <FontAwesomeIcon className={classes.backIcon} icon={faChevronLeft} />
          <span className={classes.backText}>{label}</span>
        </a>
      )}
    </Fragment>
  )
}
