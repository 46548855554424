import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { errorHandlerService } from '../../common/services/error-handler.service'

const HIGH_LIMIT = 999
export const fetchProjectById = createAsyncThunk(
  'project/fetchById',
  async ({ id, location }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`api/project/${id}`, {
        params: { limit: HIGH_LIMIT }
      })

      const populatedChildren = await axios.get(`api/project/${id}/items`, {
        params: { limit: HIGH_LIMIT }
      })

      return {
        id,
        reduxCallLocation: location,
        items: populatedChildren.data.docs,
        title: response.data.title,
        description: response.data.description,
        access: response.data.access,
        permissions: response.data.permissions
      }
    } catch (err) {
      errorHandlerService.handleServerError(
        err,
        'Sorry, we are unable to fetch project contents. Try again?'
      )
      return rejectWithValue(err)
    }
  }
)

export const fetchProjectList = createAsyncThunk(
  'project/fetchList',
  async () => {
    const response = await axios.get('api/project/', {
      params: { page: 1, search: '', limit: HIGH_LIMIT }
    })
    return response.data.docs
  }
)

export const deleteCausalProject = createAsyncThunk(
  'project/delete',
  async (id) => {
    const response = await axios.post(`api/project/${id}/delete`)
    if (response.status === 200) {
      localStorage.removeItem('selectedProject')
      return id
    }
  }
)

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projects: [],
    populatedProject: {},
    selectedProject: '',
    searchFilter: '',
    loadingState: false
  },
  reducers: {
    selectProject: (state, action) => {
      localStorage.setItem('selectedProject', action.payload)
      state.selectedProject = action.payload
    },
    toggleLoading: (state, action) => {
      state.loadingState = action.payload
    },
    setReportSearchFilter: (state, action) => {
      state.searchFilter = action.payload
    },
    updateProject: (state, action) => {
      // Update the project in an immutable way
      state.populatedProject = {
        ...state.populatedProject,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectById.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.loading = false
        state.populatedProject = action.payload
        const isDev = process.env.NODE_ENV === 'development'
        document.title = (isDev ? '[DEV] ' : '') + action.payload.title
      })
      .addCase(fetchProjectById.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(fetchProjectList.fulfilled, (state, action) => {
        state.projects = action.payload
      })
      .addCase(deleteCausalProject.fulfilled, (state, action) => {
        state.projects = state.projects.filter(
          (project) => project._id !== action.payload
        )
        state.selectedProject = 'deleted'
        if (state.populatedProject.id === action.payload) {
          state.populatedProject = {}
        }
      })
  }
})

// Action creators are generated for each case reducer function
export const {
  selectProject,
  toggleLoading,
  setReportSearchFilter,
  updateProject
} = projectSlice.actions
export default projectSlice.reducer
