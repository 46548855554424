import { createUseStyles } from 'react-jss'

export const NAVBAR_WIDTH = 54

export const style = {
  layout: {
    composes: 'body-text'
  }
}

export const useStyles = createUseStyles(style)
