import { createUseStyles } from 'react-jss'
import { colors } from '../../variables.style'

export const style = {
  requiredLabel: {
    color: colors.fontAlt
  }
}

export const useStyles = createUseStyles(style)
