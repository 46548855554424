import { useContext } from 'react'
import logo from '#base/assets/img/logo-nav.png'
import { useStyles } from './navbar.style'
import { UserContext } from '#base/js/common/contexts/userContext'
import { NavbarIcon } from './navbar-icon'
import { NavbarUserMenu } from './NavbarUserMenu'

const NavbarBase = () => {
  const classes = useStyles()
  const { user } = useContext(UserContext)

  window.addEventListener('touchend', (_) => {
    window.scrollTo({
      top: 0
    })
  })
  return (
    user && (
      <div className={classes.navbar} data-qa="navbar">
        <img src={logo} alt="Logo" className={classes.logo} />
        <NavbarIcon label="Reports" to="/reports" />
        {user.hasPermission('causal_studio:read') && (
          <NavbarIcon label="Projects" to="/projects" />
        )}
        <div className={classes.navbarEnd}>
          <NavbarUserMenu />
        </div>
      </div>
    )
  )
}

export const Navbar = NavbarBase
