import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const style = {
  svgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  svgContainerInDataColumn: {
    extend: 'svgContainer',
    padding: 6
  },
  svg: {
    display: 'block',
    flex: '1 1 auto'
  },
  xAxis: {
    stroke: colors.chartAxis,
    strokeWidth: '0.5px'
  }
}

export const useStyles = createUseStyles(style)
