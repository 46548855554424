import { createUseStyles } from 'react-jss'

export const style = {
  dataCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default'
  },
  dataCellClickable: {
    extend: 'dataCell',
    cursor: 'pointer'
  },
  dataCellValue: {
    padding: '5px'
  }
}

export const useStyles = createUseStyles(style)
