import { oauth2AgentService } from './oauth2Agent.service'
import axios, { AxiosRequestConfig } from 'axios'

/**
 * This service handles adding any auth specific settings to http library
 */
class HttpAuthService {
  private _pendingVerify: Promise<void> | undefined

  initialize () {
    this.set401Interceptor()
  }

  set401Interceptor () {
    // Configure response interceptor
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        // Only activate on 401 errors
        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest.retried
        ) {
          originalRequest.retried = true

          if (this._pendingVerify === undefined) {
            this._pendingVerify = this._refreshTokens(originalRequest).finally(
              () => (this._pendingVerify = undefined)
            )
          }

          await this._pendingVerify
          return axios.request(originalRequest)
        }
        return Promise.reject(error)
      }
    )
  }

  private async _refreshTokens (request: AxiosRequestConfig): Promise<void> {
    const resp = await oauth2AgentService.verify()
    if (resp.status !== 200 || !resp.data.authorized) {
      throw Error('User is not authorized')
    }
  }
}

export const httpAuthService = new HttpAuthService()
