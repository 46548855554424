import { Outlet } from 'react-router-dom'
import { RouterContextProvider } from '#base/js/common/contexts/routerContext'

export function ProvideRouteContext () {
  return (
    <RouterContextProvider>
      <Outlet />
    </RouterContextProvider>
  )
}
