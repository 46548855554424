import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { UserContext } from '#base/js/common/contexts/userContext'
import { RouterContext } from '#base/js/common/contexts/routerContext'

export function GuardUserPermission ({ permission }) {
  const { user } = useContext(UserContext)
  const { router } = useContext(RouterContext)

  if (user && user.hasPermission(permission)) {
    return <Outlet />
  } else {
    return <Navigate replace to={router.getUserHomeRoute()} />
  }
}
