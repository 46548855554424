import { createUseStyles } from 'react-jss'
import { borderStyle } from '../table.style'
import { colors } from '#base/js/variables.style'

export const style = {
  displayText: {
    color: colors.brandAccent,
    fontSize: 12,
    fontWeight: 'bold'
  },
  percentBar: {
    width: 43,
    height: 10,
    marginTop: 2,
    border: borderStyle
  }
}

export const useStyles = createUseStyles(style)

export const getBarBackground = (percent) => {
  return percent > 0
    ? `linear-gradient(90deg, ${colors.brandAccent} ${
        percent < 2 ? 2 : percent
      }%, rgba(0,0,0,0) 0%)`
    : 'none'
}
