import { createUseStyles } from 'react-jss'
import { colors, zIndexes } from '../../../variables.style'

export const style = {
  navbar: {
    composes: 'flex-row',
    backgroundColor: colors.brandAlt,
    alignItems: 'center',
    zIndex: zIndexes.navbar,
    overflow: 'auto'
  },
  logo: {
    display: 'block',
    maxHeight: '32px',
    maxWidth: '175px',
    margin: '5px 20px 5px 15px'
  },
  logoText: {
    display: 'inline-block',
    width: 'auto',
    color: 'white',
    fontSize: '20px',
    marginRight: 20
  },
  navbarEnd: {
    composes: 'flex-auto flex-row',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center'
  }
}

export const useStyles = createUseStyles(style)
