import {
  LABEL_COLUMN_MIN_WIDTH,
  DATA_COLUMN_DEFAULT_WIDTH
} from './table.constants'

export const ROW_LABEL_KEY = 'row_label'
const DEFAULT_TABLE_WIDTH = 1024
const MOBILE_BREAKPOINT = 705

export class TableColumn {
  constructor (
    columns = [],
    labelColumnWidth = LABEL_COLUMN_MIN_WIDTH,
    dataColumnWidth = DATA_COLUMN_DEFAULT_WIDTH,
    tableWidth = DEFAULT_TABLE_WIDTH
  ) {
    this.columns = columns
    this.labelColumnWidth = 0
    this.dataColumnWidth = 0
    this.dataColumnViewableWidth = 0
    this.dataColumnTotalWidth = 0

    this.calculateColumnWidths(
      columns,
      labelColumnWidth,
      dataColumnWidth,
      tableWidth
    )
  }

  get labelColumn () {
    const labelColumns = this.columns.filter((column) =>
      this.isLabelColumn(column)
    )
    return labelColumns && labelColumns[0] // there should only be one label column
  }

  get dataColumns () {
    return this.columns.filter((column) => !this.isLabelColumn(column))
  }

  calculateColumnWidths (
    columns,
    labelColumnWidth,
    dataColumnWidth,
    tableWidth
  ) {
    const totalCount = columns.length
    let dataCount = totalCount
    let dataTotalWidth = tableWidth
    let label = 0
    let data = 0

    // Bail out if no columns
    if (totalCount < 1) {
      return
    }

    // Check if there's a label column and default to min label width
    if (columns[0].key === ROW_LABEL_KEY) {
      label = labelColumnWidth
      if (tableWidth < MOBILE_BREAKPOINT) {
        label = tableWidth / 2
      }
      dataTotalWidth -= label
      --dataCount // remove label from data column count
    }

    // Set the data column width if there are data columns
    if (dataCount > 0) {
      data = dataColumnWidth
    }

    // If there is a label and total data columns widths are less than the viewable area, give the rest of the space to label
    const currentDataTotalWidth = data * dataCount
    if (label > 0 && currentDataTotalWidth < dataTotalWidth) {
      const theRest = dataTotalWidth - currentDataTotalWidth
      label += theRest
      dataTotalWidth = currentDataTotalWidth
    }

    this.dataColumnWidth = data
    this.labelColumnWidth = label
    this.dataColumnViewableWidth = dataTotalWidth
    this.dataColumnTotalWidth = dataColumnWidth * dataCount
  }

  isLabelColumn ({ key }) {
    return key === ROW_LABEL_KEY
  }
}
