import axios from 'axios'

export const HTTP_MODE = {
  API: 'api',
  STANDALONE: 'standalone'
} as const
type Mode = (typeof HTTP_MODE)[keyof typeof HTTP_MODE]

class HttpService {
  private mode: Mode

  constructor () {
    this.mode = HTTP_MODE.API
  }

  get isStandalone () {
    return this.mode === HTTP_MODE.STANDALONE
  }

  initialize (mode = HTTP_MODE.API) {
    this.mode = mode
    this.setDefaults()
  }

  setDefaults () {
    axios.defaults.baseURL = this.getBaseURL()
    axios.defaults.withCredentials = true
  }

  getBaseURL () {
    if (this.isStandalone) {
      return ''
    }
    return `//${__CONFIG__.apiHost || window.location.hostname}:${
      __CONFIG__.apiPort
    }/`
  }

  async checkResourceExists (url) {
    try {
      const response = await axios.get(url)
      return response.status === 200
    } catch (e) {
      return false
    }
  }

  /**
   * For development and debugging, show all HttpService requests
   *  and responses in the browser console
   */
  /* eslint-disable no-console */
  private installLoggingInterceptors () {
    const style = 'color:purple; font-weight: bold'
    axios.interceptors.request.use((req) => {
      console.log(`%c-----> ${req.method.toUpperCase()} ${req.url}`, style)
      console.log(req.headers)
      return req
    })
    axios.interceptors.response.use((res) => {
      console.log(`%c${res.status} <-----`, style)
      console.log(res.headers)
      console.log(res.data)
      return res
    })
  }
}

export const httpService = new HttpService()
