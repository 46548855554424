import { createContext, useEffect, useState } from 'react'
import { oauth2AgentService } from '../services/oauth2Agent.service'
import axios from 'axios'

interface UserInit {
  userId: string
  email: string
  id: string
  firstName: string
  lastName: string
  authGroups: string[]
  userSettings: Record<string, any>
  userGroups: string[]
  permissions: string[]
}

// An AuthenticatedUser represents a logged in user/session
export class User {
  readonly username: string
  readonly firstName: string
  readonly lastName: string
  readonly authGroups: string[]
  readonly userGroups: string[]
  readonly permissions: string[]
  readonly displayName: string
  settings: Record<string, any>

  constructor (authData: UserInit) {
    // Initialize the user object based on the user reponse from the API
    this.username = authData.userId || authData.id
    this.firstName = authData.firstName ?? ''
    this.lastName = authData.lastName ?? ''
    this.authGroups = authData.authGroups || []
    this.userGroups = authData.userGroups || []
    this.permissions = authData.permissions || []
    this.displayName =
      authData.firstName && authData.firstName
        ? `${authData.firstName} ${authData.lastName}`
        : authData.firstName ?? authData.lastName ?? ''
    this.settings = authData.userSettings ?? {}
  }

  get previousUsername () {
    // For authenticated user, the "previous" name is the currrent name
    return this.username
  }

  updateFavorites (favorites: any) {
    if (this.settings.favorites) {
      this.settings.favorites.reports = favorites
    } else {
      this.settings.favorites = { reports: favorites }
    }
  }

  userIsInAuthGroup (groupName: string) {
    return this.authGroups.includes(groupName)
  }

  isSiteAdmin () {
    return (
      this.hasPermission('site:admin') || this.authGroups.includes('SiteAdmin')
    )
  }

  isUserAdmin () {
    return this.hasPermission('users:admin')
  }

  usernameMatches (username) {
    return this.username === username
  }

  hasPermission (permission) {
    return this.permissions.includes(permission)
  }
}

/**
 *
 */
export const UserContext = createContext<
  | {
      user: User | undefined
      providerHint: string | undefined
      signOut:(msg?: string) => void
      setProviderHint: (hint?: string | undefined) => void
      setCurrentUser: (user: User | undefined) => void
        }
        | undefined
        >(undefined)

export function UserContextProvider (props) {
  const [authCheck, setAuthCheck] = useState(false)
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)
  const [providerHint, setProviderHint] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    (async () => {
      if (!currentUser) {
        // Verify existing auth tokens w/ refresh if needed
        const resp = await oauth2AgentService.verify()
        if (resp.status === 200 && resp.data.authorized) {
          try {
            const { data } = await axios.get('api/auth/user')
            setCurrentUser(new User(data))
          } catch (err) {
            setCurrentUser(undefined)
          }
        }
        setAuthCheck(true)
      }
    })()
  }, [currentUser])

  return (
    authCheck && (
      <UserContext.Provider
        value={{
          user: currentUser,
          setCurrentUser,
          providerHint,
          setProviderHint,
          signOut: (msg?: string) => {
            (async () => {
              const { url } = await oauth2AgentService.logoutUrl({
                redirectUri: `${window.location.protocol}//${
                  window.location.hostname
                }${
                  window.location.port ? ':' + window.location.port : ''
                }/welcome`
              })
              if (msg) {
                // Remember any messaging about the signout to display
                //  on the /welcome page
                sessionStorage.setItem('lastAuthMessage', msg)
              } else {
                sessionStorage.removeItem('lastAuthMessage')
              }
              window.location.assign(url)
            })()
          }
        }}
      >
        {props.children}
      </UserContext.Provider>
    )
  )
}
