import { NumberCell } from './number-cell'
import { PercentBar } from './percent-bar'
import { SparklineCell } from './sparkline-cell'
import { TextCell } from './text-cell'
import { ConfidenceCell } from './confidence-cell'

export const FORMATS = {
  NUMBER: 'number',
  PERCENT_BAR: 'percent_bar',
  SPARKLINE: 'sparkline',
  CONFIDENCE: 'confidence',
  TEXT: 'text'
}

export const CellFormat = ({
  format = 'text',
  formatOptions,
  values,
  rows,
  rowIndex,
  colIndex,
  cellIndex,
  isRowHover = true,
  isCellHover = false
}) => {
  const props = {
    values,
    formatOptions,
    rows,
    rowIndex,
    colIndex,
    cellIndex,
    isRowHover,
    isCellHover
  }

  switch (format) {
    case FORMATS.PERCENT_BAR:
      return <PercentBar {...props} />
    case FORMATS.NUMBER:
      return <NumberCell {...props} />
    case FORMATS.SPARKLINE:
      return <SparklineCell {...props} isInDataColumn={true} />
    case FORMATS.CONFIDENCE:
      return <ConfidenceCell {...props} />
    default:
      return <TextCell {...props} />
  }
}
