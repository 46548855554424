class SortService {
  ascending (a: string, b: string): number {
    return Intl.Collator().compare(a, b)
  }

  descending (a: string, b: string): number {
    return Intl.Collator().compare(b, a)
  }

  ascendingBy (prop: string) {
    return (a, b) => this.ascending(a[prop], b[prop])
  }

  ascendingByWithFavorites (prop: string, favorites: string[]) {
    return (a, b) => {
      if (favorites.includes(a.id) && !favorites.includes(b.id)) {
        return -1
      } else if (!favorites.includes(a.id) && favorites.includes(b.id)) {
        return 1
      } else {
        return this.ascending(a[prop], b[prop])
      }
    }
  }

  descendingBy (prop: string) {
    return (a, b) => this.descending(a[prop], b[prop])
  }
}

export const sortService = new SortService()
