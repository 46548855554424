import { useStyles } from './download-link.style'

export const DownloadLink = function ({
  label = 'Download Data',
  className = '',
  fileName = '',
  data = '',
  contentType = 'text/plain'
}) {
  const classes = useStyles()

  const result = new Blob([data], { type: `${contentType}` })

  const url = window.URL.createObjectURL(result)

  return (
    <a
      className={`${classes.downloadLink} ${classes.hoverAnchor} ${className}`}
      download={fileName}
      href={url}
      data-qa="download-link"
    >
      <span className={classes.linkText}>{label}</span>
    </a>
  )
}
