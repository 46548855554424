import { useContext, useEffect, useState } from 'react'
import { idleTimeoutManagerService } from '../services/idle-timeout-manager.service'
import { Modal } from './modal'
import { useStyles } from './idle-timer.style'
import { Button } from './button'
import { UserContext } from '../contexts/userContext'
import { RouterContext } from '../contexts/routerContext'
import { createUUID } from '../services/uuid.service'

const TAB_ID = createUUID()
const LocalStorageKeys = {
  COUNTDOWN_EXPIRATION: '_idle_timer_countdown_exp',
  ACTIVE_TAB_ID: '_idle_timer_active_tab'
}

const States = {
  ACTIVE: 1,
  IDLE_COUNTDOWN: 2,
  IDLE: 3
}

let idleCountdownInterval
let runningIdleDetection = false
export const IdleTimer = () => {
  const classes = useStyles()
  const [currentState, setCurrentState] = useState(States.ACTIVE)
  const [logoutCountdownSeconds, setLogoutCountdownSeconds] = useState(0)
  const { signOut } = useContext(UserContext)
  const { router } = useContext(RouterContext)

  /**
   * Run idle detection, and perform a state
   * change to begin a warning countdown once an
   * inactive session has been detected
   */
  const startIdleDetection = () => {
    if (!runningIdleDetection) {
      idleTimeoutManagerService.awaitIdle(() => {
        setCurrentState(States.IDLE_COUNTDOWN)
      })
      runningIdleDetection = true
    }
  }

  /**
   * Clear the idle detection interval
   */
  const stopIdleDetection = () => {
    if (runningIdleDetection) {
      idleTimeoutManagerService.clear()
      runningIdleDetection = false
    }
  }

  /**
   * Stop the logout countdown timer
   */
  const stopLogoutCountdown = () => {
    if (idleCountdownInterval) {
      clearInterval(idleCountdownInterval)
      idleCountdownInterval = null
    }
  }

  /**
   * Perform user logout
   */
  const logout = async () => {
    // Update the router context so that the router knows the session
    // is inactive and does not redirect to login automatically upon logout
    router.setInactiveSession()

    // Update modal state
    setCurrentState(States.IDLE)

    // Stop the logout counter
    stopLogoutCountdown()

    // Perform logout by retrieving logout url from the Oauth2 Agent
    signOut('You have been signed out automatically due to inactivity')
  }

  /**
   * Start the logout countdown and update the number of seconds left as each second passes
   */
  const startLogoutCountdown = () => {
    const countdownExpiration =
      idleTimeoutManagerService.getWarningExpirationTimestamp()
    if (!idleCountdownInterval) {
      localStorage.setItem(
        LocalStorageKeys.COUNTDOWN_EXPIRATION,
        countdownExpiration
      )
      const secondsLeft = Math.floor((countdownExpiration - Date.now()) / 1000)
      setLogoutCountdownSeconds(secondsLeft)

      // Interval runs every 100 milliseconds and updates the number of seconds left
      // by checking against the timestamp in the countdownExpiration localstorage property.
      // Logout is performed only for last "active" tab seen (tracked as ACTIVE_TAB_ID in localstorage),
      // to avoid race conditions
      idleCountdownInterval = setInterval(async () => {
        const secondsLeft = Math.floor(
          (countdownExpiration - Date.now()) / 1000
        )
        const activeTabId = localStorage.getItem(LocalStorageKeys.ACTIVE_TAB_ID)
        if (secondsLeft <= 0 && (!activeTabId || activeTabId === TAB_ID)) {
          setCurrentState(States.IDLE)
          localStorage.setItem(LocalStorageKeys.COUNTDOWN_EXPIRATION, 'expired')
          await logout()
        } else if (secondsLeft > 0) {
          setLogoutCountdownSeconds(secondsLeft)
        }
      }, 100)
    }
  }

  /**
   * Get the current logout countdown to display on the screen
   * @returns {string} the number of minutes/seconds in m:ss form
   */
  const getLogoutCountdown = () => {
    const minutes = Math.floor(logoutCountdownSeconds / 60)
    const seconds = String(logoutCountdownSeconds % 60).padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  // Initial setup
  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.ACTIVE_TAB_ID, TAB_ID)
    startIdleDetection()

    // Detect countdown expiration changes in localstorage
    const detectCountdownExpirationChange = async ({
      key,
      oldValue,
      newValue
    }) => {
      if (key === LocalStorageKeys.COUNTDOWN_EXPIRATION) {
        if (!newValue) {
          setCurrentState(States.ACTIVE)
        } else if (newValue === 'expired') {
          await logout()
        } else if (!isNaN(newValue) && currentState !== States.IDLE_COUNTDOWN) {
          setCurrentState(States.IDLE_COUNTDOWN)
        }
      }
    }
    window.addEventListener('storage', detectCountdownExpirationChange)

    // Track the last "active" tab seen
    const trackActiveTab = () => {
      if (document.visibilityState === 'visible') {
        localStorage.setItem(LocalStorageKeys.ACTIVE_TAB_ID, TAB_ID)
      }
    }
    document.addEventListener('visibilitychange', trackActiveTab)

    // Remove last active tab ID if the tab is closed
    const onPageClose = () => {
      if (localStorage.getItem(LocalStorageKeys.ACTIVE_TAB_ID) === TAB_ID) {
        localStorage.removeItem(LocalStorageKeys.ACTIVE_TAB_ID)
      }
    }
    window.addEventListener('beforeunload', onPageClose)

    // Teardown when component unmounts
    return () => {
      stopLogoutCountdown()
      stopIdleDetection()
      window.removeEventListener('storage', detectCountdownExpirationChange)
      document.removeEventListener('visibilitychange', trackActiveTab)
      window.removeEventListener('beforeunload', onPageClose)
    }
  }, [])

  // Run updates upon state change
  useEffect(() => {
    switch (currentState) {
      case States.IDLE_COUNTDOWN:
        startLogoutCountdown()
        stopIdleDetection()
        break
      case States.IDLE:
        stopIdleDetection()
        stopLogoutCountdown()
        break
      case States.ACTIVE:
        localStorage.removeItem(LocalStorageKeys.COUNTDOWN_EXPIRATION)
        startIdleDetection()
        stopLogoutCountdown()
        break
    }
  }, [currentState])

  const modalProps = {
    className: classes.modal,
    bodyClassName: classes.modalBody,
    overlayClassName: classes.modalOverlay,
    titleClassName: classes.modalTitle,
    enableOverlayClick: false,
    isOpen: true,
    showCloseButton: false
  }

  switch (currentState) {
    case States.IDLE_COUNTDOWN:
      return (
        <Modal
          title={`Session ending in ${getLogoutCountdown()}`}
          footer={
            <Button onClick={() => setCurrentState(States.ACTIVE)}>
              Continue Session
            </Button>
          }
          {...modalProps}
        >
          <p>
            You will be logged out soon due to inactivity. Click the button
            below to resume your session.
          </p>
        </Modal>
      )
    case States.IDLE:
      return (
        <Modal
          title="Your session has ended"
          footer={
            <Button onClick={() => window.location.reload()}>
              Log back in
            </Button>
          }
          {...modalProps}
        >
          <p>
            You have been signed out automatically due to inactivity. Click the
            button below to log back in.
          </p>
        </Modal>
      )
    default:
      return null
  }
}
