import { Fragment, useEffect, useRef, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import {
  useFloating,
  autoUpdate,
  autoPlacement,
  shift,
  offset
} from '@floating-ui/react'

import {
  useTooltipContainerContext,
  useTooltipPositionContext,
  useTooltipDisplayContext
} from './tooltip.context'
import { useTooltipStyles } from './tooltip.style'

export const TOOLTIP_TYPE = {
  DEFAULT: 'default',
  LIST: 'list',
  MINI: 'mini'
}

export const TOOLTIP_VARIANT = {
  DEFAULT: 'default',
  BOXPLOT: 'boxplot'
}

export const Tooltip = function Tooltip ({
  type = TOOLTIP_TYPE.DEFAULT,
  variant = TOOLTIP_VARIANT.DEFAULT,
  defaults,
  tooltipCoordinates,
  onCoordinatesChange,
  boundaryRef
}) {
  const tooltipContainerRef = useTooltipContainerContext()
  const tooltipPositionRef = useTooltipPositionContext()

  const tooltipRootRef = useRef()
  const tooltipRootNodeRef = useRef()

  const tooltipCoordinatesRef = useRef()
  const tooltipDataRef = useRef()
  const [tooltipDisplayData] = useTooltipDisplayContext()
  const classes = useTooltipStyles()

  const displayData = Object.assign({}, defaults, tooltipDisplayData)

  if (displayData) {
    tooltipDataRef.current = displayData
  } else if (tooltipDataRef.current) {
    tooltipDataRef.current = undefined
  }

  if (
    displayData.secondaryLabel !== undefined &&
    displayData.secondaryValue !== undefined
  ) {
    displayData.secondaryLabel = Array.isArray(displayData.secondaryLabel)
      ? displayData.secondaryLabel
      : [displayData.secondaryLabel]
    displayData.secondaryValue = Array.isArray(displayData.secondaryValue)
      ? displayData.secondaryValue
      : [displayData.secondaryValue]
  }

  const hasSecondary = !!displayData?.secondaryLabel
  const hasReference = !!displayData?.referenceData

  const setTooltipPosition = (x, y) => {
    if (!tooltipRootRef.current) {
      tooltipRootRef.current = tooltipContainerRef.current
    }
    if (!tooltipPositionRef.current) {
      tooltipPositionRef.current =
        tooltipRootRef.current.querySelector('.tooltip-position')
    }

    const position = { x, y }

    tooltipPositionRef.current.style.top = `${position.y}px`
    tooltipPositionRef.current.style.left = `${position.x}px`

    tooltipCoordinatesRef.current = [position.x, position.y]
  }

  const { x, y, refs, strategy, update } = useFloating({
    middleware: [
      autoPlacement({
        allowedPlacements: ['right', 'left'],
        boundary: boundaryRef?.current
      }),
      shift(),
      offset({ mainAxis: 10, crossAxis: 10 })
    ],
    whileElementsMounted: (reference, floating, update) => {
      // IMPORTANT: Make sure the cleanup function is returned
      return autoUpdate(reference, floating, update, {
        animationFrame: true
      })
    }
  })

  // Positioning point of reference to calculate tooltip display
  useLayoutEffect(() => {
    refs.setReference(tooltipPositionRef.current)
  }, [refs.reference, tooltipPositionRef.current])

  useEffect(() => {
    function handleMouseEvent (event) {
      tooltipRootNodeRef.current = event.currentTarget
      tooltipRootRef.current =
        tooltipRootNodeRef.current.getBoundingClientRect()

      // Get position relative to the tooltip root node
      const position = [
        event.x - tooltipRootRef.current.x,
        event.y - tooltipRootRef.current.y
      ]
      setTooltipPosition(...position)
      onCoordinatesChange?.(position)
    }

    tooltipContainerRef.current.addEventListener('mousemove', handleMouseEvent)
    tooltipContainerRef.current.addEventListener('mouseover', handleMouseEvent)

    return () => {
      if (tooltipContainerRef.current) {
        tooltipContainerRef.current.removeEventListener(
          'mousemove',
          handleMouseEvent
        )
        tooltipContainerRef.current.removeEventListener(
          'mouseover',
          handleMouseEvent
        )
      }
    }
  }, [])

  useEffect(() => {
    if (tooltipCoordinates && tooltipCoordinates.length === 2) {
      const [x, y] = tooltipCoordinates
      setTooltipPosition(x, y)
      update()
    }
  }, [tooltipCoordinates])

  if (type === TOOLTIP_TYPE.DEFAULT) {
    return createPortal(
      <div
        className={classes.tooltipBox}
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
          display: tooltipDisplayData ? 'block' : 'none'
        }}
      >
        {tooltipDisplayData &&
          displayData.secondaryLabel &&
          displayData.secondaryValue &&
          displayData.secondaryValue.map((value, index) => (
            <div key={index} className={classes.secondarySection}>
              {displayData.secondaryLabel[index] ? (
                <>
                  <div>{displayData.secondaryLabel[index]}:</div>
                  <div className={classes.secondaryValue}>{value}</div>
                </>
              ) : (
                <div
                  style={{ marginLeft: 0 }}
                  className={classes.secondaryValue}
                >
                  {value}
                </div>
              )}
            </div>
          ))}
        {displayData.referenceData && (
          <div className={classes.referenceSection}>
            {displayData.referenceData.map(({ value, label }) => (
              <div key={label} className={classes.referenceItem}>
                <div className={classes.referenceLabel}>{label}:</div>
                <div className={classes.referenceValue}>{value}</div>
              </div>
            ))}
          </div>
        )}
        {tooltipDisplayData &&
          displayData.primaryLabel &&
          displayData.primaryValue && (
            <div
              className={classes.primarySection}
              style={{ marginTop: hasSecondary || hasReference ? 10 : 0 }}
            >
              <div className={classes.primaryLabel}>
                {displayData.primaryLabel}:
              </div>
              <div className={classes.values}>
                <div className={classes.primaryValue}>
                  {displayData.primaryValue}
                </div>
                {displayData.tertiaryValue !== undefined && (
                  <div className={classes.tertiaryValue}>
                    ({displayData.tertiaryValue})
                  </div>
                )}
              </div>
            </div>
        )}
      </div>,
      document.getElementById('tooltip-container')
    )
  }

  if (type === TOOLTIP_TYPE.LIST) {
    return createPortal(
      <div
        className={classes.listBox}
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
          display:
            displayData?.list && displayData.list.length ? 'block' : 'none'
        }}
      >
        {displayData &&
          displayData.list &&
          displayData.list.map(
            ({
              id,
              label,
              headerValue,
              color,
              primaryValue,
              secondaryValue,
              bands = []
            }) => (
              <Fragment key={id}>
                {headerValue ? (
                  <>
                    <div className={classes.headerValue}>{headerValue}</div>{' '}
                    <hr></hr>
                  </>
                ) : (
                  <div
                    className={
                      variant === TOOLTIP_VARIANT.BOXPLOT
                        ? classes.boxSeriesRow
                        : classes.seriesRow
                    }
                  >
                    {variant === TOOLTIP_VARIANT.BOXPLOT ? (
                      ''
                    ) : (
                      <div
                        className={classes.colorBall}
                        style={{ backgroundColor: color }}
                      />
                    )}
                    <div
                      className={
                        variant === TOOLTIP_VARIANT.BOXPLOT
                          ? classes.boxSeriesLabel
                          : classes.seriesLabel
                      }
                    >
                      {label}:
                    </div>
                    <div
                      className={
                        variant === TOOLTIP_VARIANT.BOXPLOT
                          ? classes.boxSeriesValue
                          : classes.seriesValue
                      }
                    >
                      {primaryValue}
                    </div>
                    {secondaryValue && <div>({secondaryValue})</div>}
                    {bands.length === 1 && <div>({bands[0].value})</div>}
                  </div>
                )}
                {bands.length > 1 &&
                  bands.map(({ label, color, value }) => (
                    <div key={label} className={classes.seriesRowBand}>
                      <div
                        className={classes.colorBall}
                        style={{ backgroundColor: color }}
                      />
                      <div className={classes.seriesLabel}>{label}:</div>
                      <div>{value}</div>
                    </div>
                  ))}
              </Fragment>
            )
          )}
      </div>,
      document.getElementById('tooltip-container')
    )
  }

  if (type === TOOLTIP_TYPE.MINI) {
    return createPortal(
      <div
        className={classes.miniBox}
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
          display: tooltipDisplayData ? 'block' : 'none'
        }}
      >
        {tooltipDisplayData &&
          displayData &&
          displayData.primaryValue &&
          displayData.primaryValue}
      </div>,
      document.getElementById('tooltip-container')
    )
  }

  return null
}
