import { Outlet } from 'react-router'
import { Alerts } from '#base/js/common/alerts'

export function ProvideAlerts ({ ignoreContexts }) {
  return (
    <>
      <Outlet />
      <Alerts ignoreContexts={ignoreContexts} />
    </>
  )
}
