import { useState, useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Flipper, Flipped } from 'react-flip-toolkit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleHistoryCirclePlus } from '@fortawesome/pro-light-svg-icons'
import { REPORT_VIEWERS } from '#common/src/report/report.constants'
import { TextInput } from '../common/components/text-input'
import { Button } from '../common/components/button'
import NotificationManager from '../common/components/Notifications/NotificationManager'
import { Category } from './category'
import { useReportTocContext } from './contexts/report-toc.context'
import { Report } from './report.model'
import { ExecutiveSummaryLink } from '../../js/reports/executive-summary-link'
import { useStyles } from './toc-list.style'

export function TocList ({
  report: reportParam,
  filteredToc,
  mode = 'view',
  style,
  className,
  uncategorizedGroup,
  pageSubPage,
  showExecSummary = false,
  showUnpublishedWarning = false,
  openPageInNewWindowOrTab = false,
  selectedPages = [],
  onSelectPage,
  onChange
}) {
  const classes = useStyles()
  const reportTocContext = useReportTocContext() || {}
  const report = mode === 'edit' ? reportTocContext.report : reportParam

  const [groups, setGroups] = useState(filteredToc || report.groups)
  const [newCategory, setNewCategory] = useState('')
  const [editPage, setEditPage] = useState(null)

  function handleDragEnd (draggable) {
    const { source, destination } = draggable

    if (!destination) {
      return
    }

    const copiedGroups = [...groups]
    const sourceGroup = copiedGroups.find(
      (group) => group.id === source.droppableId
    )
    const destinationGroup = copiedGroups.find(
      (group) => group.id === destination.droppableId
    )

    const [removedItem] = sourceGroup.pages.splice(source.index, 1)
    destinationGroup.pages.splice(destination.index, 0, removedItem)

    updateGroups(copiedGroups)
  }

  function handleCategoryCreate (event) {
    event.preventDefault()

    const newGroupId = newCategory.toLowerCase().trim()

    if (newGroupId.length === 0) {
      NotificationManager.create({
        type: 'error',
        timeOut: 20000,
        message:
          'The category name is required. Could you enter a value for the category name?'
      })
      return
    }

    const duplicateCategory = groups.find((group) => group.id === newGroupId)

    if (duplicateCategory) {
      NotificationManager.create({
        type: 'error',
        timeOut: 20000,
        message: 'Category names must be unique. Could you enter another name?'
      })
      return
    }

    const copiedGroups = [...groups]
    copiedGroups.push({
      id: newGroupId,
      label: newCategory,
      pages: []
    })

    setNewCategory('')
    updateGroups(copiedGroups)
  }

  function updateGroups (groups) {
    setGroups(groups)

    const allGroups = groups
    if (uncategorizedGroup && !allGroups.includes(uncategorizedGroup)) {
      allGroups.push(uncategorizedGroup)
    }
    reportTocContext.setNewGroups(allGroups)

    onChange(allGroups)
  }

  function onEditPage (page, isEdit) {
    setEditPage(isEdit ? page : null)
  }

  function onFilterPage (page) {
    return page.viewer !== REPORT_VIEWERS.EXECUTIVE_SUMMARY_PAGE
  }

  useEffect(() => {
    if (report && filteredToc) {
      setGroups(filteredToc || report.groups)
    }
  }, [report, filteredToc])

  if (!Array.isArray(groups) || groups.length === 0) {
    return
  }

  // This key decides whether or not to animate category changes.
  const flipKey = groups.map((group) => group.id).join('')
  const executiveSummary = Report.getExecutiveSummary(groups)
  const pageGroups = groups.filter((group) => !!group.id)
  let numPages = 0

  return (
    <div
      className={`${classes.tocContainer} ${className || ''}`}
      style={style}
      data-qa="toc-list-container"
    >
      {mode === 'edit' ? (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Flipper flipKey={flipKey}>
              {pageGroups.map((group, index) => {
                return (
                  <Flipped key={group.id} flipId={encodeURI(group.id)}>
                    <Category
                      data-qa={'toc-group'}
                      report={report}
                      groups={pageGroups}
                      index={index}
                      pageSubPage={pageSubPage}
                      mode={mode}
                      editPage={editPage}
                      showUnpublishedWarning={showUnpublishedWarning}
                      onChange={updateGroups}
                      onEditPage={onEditPage}
                      onFilterPage={onFilterPage}
                    />
                  </Flipped>
                )
              })}
            </Flipper>
          </DragDropContext>
          <div className={classes.newCategoryContainer}>
            <form
              className={classes.newCategory}
              onSubmit={handleCategoryCreate}
            >
              <TextInput
                data-qa="toc-list-new-category-name"
                labelClass={classes.newCategoryField}
                value={newCategory}
                onChange={(event) => {
                  setNewCategory(event.target.value)
                }}
                placeholder="New Category Name"
              />
              <Button
                data-qa="toc-list-create-category-button"
                type="submit"
                className={classes.newCategoryButton}
                variant="icon-link"
                tooltipLabel="Create Category"
              >
                <FontAwesomeIcon
                  icon={faRectangleHistoryCirclePlus}
                  size="xl"
                />
              </Button>
            </form>
          </div>
        </>
      ) : (
        <>
          {showExecSummary && executiveSummary ? (
            <ExecutiveSummaryLink
              className={classes.executiveSummary}
              report={report}
              executiveSummary={executiveSummary}
              pageSubPage={pageSubPage}
              mode={mode}
              showUnpublishedWarning={showUnpublishedWarning}
              openInNewWindowOrTab={openPageInNewWindowOrTab}
              isSelected={(id) => selectedPages.includes(id)}
              onSelectPage={onSelectPage}
            />
          ) : (
            ''
          )}
          {pageGroups.map((group, index) => {
            const pageNumberOffset = numPages
            const numGroupPages = group.pages?.filter(onFilterPage).length ?? 0
            if (numGroupPages === 0) {
              return null
            }

            numPages += numGroupPages

            return (
              <Category
                key={group.id}
                data-qa={'toc-group'}
                report={report}
                groups={pageGroups}
                index={index}
                pageNumberOffset={pageNumberOffset}
                pageSubPage={pageSubPage}
                mode={mode}
                showUnpublishedWarning={showUnpublishedWarning}
                openPageInNewWindowOrTab={openPageInNewWindowOrTab}
                isSelectedPage={(id) => selectedPages.includes(id)}
                onFilterPage={onFilterPage}
                onSelectPage={onSelectPage}
              />
            )
          })}
        </>
      )}
    </div>
  )
}
