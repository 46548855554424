import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { animated, useSpring, config } from 'react-spring'

export const ContentAdjustingContainer = forwardRef(
  function ContentAdjustingContainer (
    { containerClassName, className, children, ...props },
    ref
  ) {
    const [containerHeight, setContainerHeight] = useState(0)

    const prevContainerHeight = useRef(0)
    const containerRef = useRef()

    useImperativeHandle(ref, () => containerRef.current)

    const style = useSpring({
      config: { ...config.stiff, clamp: true },
      from: {
        height: prevContainerHeight.current
      },
      to: {
        height: containerHeight
      }
    })

    function setContainerRef (ref) {
      containerRef.current = ref
      if (containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight)
      }
    }

    useEffect(() => {
      prevContainerHeight.current = containerHeight
    }, [containerHeight])

    useEffect(() => {
      if (containerRef.current) {
        const onResize = () =>
          setContainerHeight(containerRef.current.offsetHeight)

        window.addEventListener('resize', onResize)

        return () => window.removeEventListener('resize', onResize)
      }
    }, [])

    return (
      <animated.div className={className} style={style}>
        <div ref={setContainerRef} className={containerClassName}>
          {children}
        </div>
      </animated.div>
    )
  }
)
