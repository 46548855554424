import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const style = {
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 50px 50px'
  },
  controlBar: {
    display: 'flex',
    padding: '10px 0',
    justifyContent: 'bottom'
  },
  control: {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    '& label': {
      textTransform: 'uppercase',
      fontSize: 12,
      margin: '0 0 5px 0'
    }
  },
  datePickerControl: {
    extend: 'control',
    '& input': {
      height: 34,
      width: 120,
      borderRadius: 4,
      border: `1px solid ${colors.border}`
    }
  },
  switchControl: {
    extend: 'control',
    marginLeft: 20,
    alignItems: 'center',
    '& label': {
      margin: '5px 0 5px 0'
    }
  }
}

export const useStyles = createUseStyles(style)
