import { useState } from 'react'
import { scaleLinear } from '@visx/scale'
import { extent } from 'd3-array'

import { colors } from '#base/js/variables.style'
import {
  ConfidencePlot,
  PLOT_TYPE,
  POINT_RADIUS_LARGER,
  ARROW_WIDTH
} from '../../common/confidence-plot/confidence-plot'
import { useTooltipDisplayContext } from '../../common/tooltip/tooltip.context'

import { cellFormatService } from './cell-format.service'
import { useStyles } from './confidence-cell.style'

const SVG_WIDTH = 100
const SVG_HEIGHT = 20
const PLOT_COLOR = colors.brandAccent
const HIGHLIGHT_COLOR = colors.brandPrimary
const MARGIN = Math.max(POINT_RADIUS_LARGER, ARROW_WIDTH) // Ensure the SVG has space to render points and arrows on the ends of the scale

export const ConfidenceCell = ({
  values,
  formatOptions,
  rows,
  colIndex,
  cellIndex
}) => {
  const classes = useStyles()
  const [, setTooltipDisplayData] = useTooltipDisplayContext()
  const [isOnMouseMove, setIsOnMouseMove] = useState(false)

  let [x, ciLo, ciHi] = values.map(mapNonNumericValues)

  const hasConfidenceValue = ciLo !== undefined || ciHi !== undefined

  // If one side of the CI exists, but not the other, default the missing side to the x value so we have a one-sided CI
  if (hasConfidenceValue && ciLo === undefined) {
    ciLo = x
  }
  if (hasConfidenceValue && ciHi === undefined) {
    ciHi = x
  }

  const domain = getDomain(rows, colIndex, cellIndex, formatOptions)
  const xScales = scaleLinear({
    domain,
    range: [MARGIN, SVG_WIDTH - MARGIN]
  })
  const color = doesCIIncludeReference(formatOptions.referenceline, ciLo, ciHi)
    ? HIGHLIGHT_COLOR
    : PLOT_COLOR

  function showTooltip () {
    const formattedValue = cellFormatService.formatValues(x, formatOptions)

    let confidenceRange = ''
    if (hasConfidenceValue) {
      const formattedLo = cellFormatService.formatValues(ciLo, formatOptions)
      const formattedHi = cellFormatService.formatValues(ciHi, formatOptions)
      confidenceRange = `(${formattedLo} - ${formattedHi})`
    }

    setTooltipDisplayData({
      primaryValue: `${formattedValue} ${confidenceRange}`
    })
    setIsOnMouseMove(true)
  }

  function hideTooltip () {
    setTooltipDisplayData()
    setIsOnMouseMove(false)
  }

  if (x === undefined || x === null) {
    return null
  }

  return (
    <div className={classes.svgContainer}>
      <svg
        viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
        preserveAspectRatio="xMidYMid meet"
        width="100%"
        height="100%"
        onMouseMove={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {formatOptions.referenceline !== undefined && (
          <line
            className={classes.refLine}
            x1={xScales(formatOptions.referenceline)}
            y1={0}
            x2={xScales(formatOptions.referenceline)}
            y2={SVG_HEIGHT}
          />
        )}
        <ConfidencePlot
          value={x}
          ciLo={ciLo}
          ciHi={ciHi}
          xScale={xScales}
          y={10}
          type={isOnMouseMove ? PLOT_TYPE.FILLED : PLOT_TYPE.CIRCLE}
          color={color}
        />
      </svg>
    </div>
  )
}

/**
 * Take the calculated domain (at the table or section level) and limit it using the min/max specified
 */
function getDomain (rows, colIndex, cellIndex, options = {}) {
  const { referenceline, domain, min, max } = options
  const result =
    domain || getMinMaxWithinSection(rows, colIndex, cellIndex, options)

  // Limit the domain using min/max first
  if (min !== undefined && result[0] < min) {
    result[0] = min
  }
  if (max !== undefined && result[1] > max) {
    result[1] = max
  }

  // Then ensure the reference line value is included in the range if it is specified
  if (referenceline !== undefined) {
    if (referenceline < min) {
      result[0] = referenceline
    }
    if (referenceline > max) {
      result[1] = referenceline
    }
  }

  return result
}

function getMinMaxWithinSection (rows, colIndex, cellPartIndex) {
  let allDataPoints = []
  rows.forEach((row) => {
    const cellPart = row.columns[colIndex].parts[cellPartIndex]
    if (cellPart && cellPart.values !== undefined) {
      allDataPoints = allDataPoints.concat(cellPart.values)
    }
  })
  return extent(allDataPoints)
}

function doesCIIncludeReference (referenceLine, ciLo, ciHi) {
  if (referenceLine === undefined) {
    return false
  }

  if (ciLo < referenceLine && ciHi < referenceLine) {
    return true
  }
  if (ciLo > referenceLine && ciHi > referenceLine) {
    return true
  }

  return false
}

function mapNonNumericValues (value) {
  value = convertInfinity(value)

  // Usually NaN values are NA/null from R datasets
  if (isNaN(Number(value))) {
    return undefined
  }

  return value
}

function convertInfinity (value) {
  if (value === undefined || value === null) {
    return value
  }

  const valueLower = value.toString().toLowerCase()
  if (valueLower === 'inf' || valueLower === 'infinity') {
    return Infinity
  }

  if (valueLower === '-inf' || valueLower === '-infinity') {
    return -Infinity
  }

  return value
}
