import { EventEmitter } from 'events'
import { createUUID } from '../../services/uuid.service'

const Constants = {
  CHANGE: 'change',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
}

class NotificationManager extends EventEmitter {
  constructor () {
    super()
    this.listNotify = []
    this.listProgress = []
  }

  create (notify) {
    const defaultNotify = {
      id: createUUID(),
      type: 'info',
      title: null,
      message: null,
      timeOut: 5000
      // progress: 0.5,
      // progressId: 'fred',
    }
    // Progress is a special case update to an existing notification
    // if (notify.type === 'progress') return this.updateProgress(notify)

    const n = Object.assign(defaultNotify, notify)
    // if (n.progress !== null && n.progressId !== null) {
    //   n.timeOut = 20000;
    //   this.listProgress.push(n)
    // }
    if (n.priority) {
      this.listNotify.unshift(n)
    } else {
      this.listNotify.push(n)
    }
    this.emitChange()
  }

  // updateProgress(notify) {
  //   let update = this.listNotify.find(n => notify.progessId === n.progessId);
  //   if (!update) {
  //     update = this.listProgress.find(n => notify.progessId === n.progessId);
  //     if (!update) {
  //       console.log('Notification '+notify.progressId+' not found');
  //       return;
  //     }
  //     this.listNotify.unshift(update);
  //   }
  //   update.progress = notify.progress;
  //   if (update.progress >= 1.0) {
  //     this.listProgress = this.listProgress.filter(n => notify.progressId === n.progressId)
  //   }
  //   this.emitChange();
  // }

  remove (notification) {
    this.listNotify = this.listNotify.filter((n) => notification.id !== n.id)
    this.emitChange()
  }

  emitChange () {
    this.emit(Constants.CHANGE, this.listNotify)
  }

  addChangeListener (callback) {
    this.addListener(Constants.CHANGE, callback)
  }

  removeChangeListener (callback) {
    this.removeListener(Constants.CHANGE, callback)
  }
}

export default new NotificationManager()
