import { createUseStyles } from 'react-jss'

export const style = {
  card: {
    composes: 'card',
    maxWidth: 580,
    '& $cardDescription': {
      fontSize: 22
    }
  },
  cardTitle: {
    composes: 'card-title',
    textAlign: 'center',
    marginTop: 10
  },
  cardDescription: {
    composes: 'card-description',
    margin: '10px 0',
    textAlign: 'center'
  }
}

export const useStyles = createUseStyles(style)
