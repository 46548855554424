import { FORMAT_TYPES } from '#common/src/report/format.constants'
import { formatService } from '../../common/format.service'
import { DEFAULT_STRING_JOIN } from '../table.constants'

const DEFAULT_FORMAT_TYPE = FORMAT_TYPES.NUMBER

class CellFormatService {
  mapFormatOptionsToClassName (formatOptions = {}, allClasses = {}) {
    if (formatOptions.classes) {
      const classes = Array.isArray(formatOptions.classes)
        ? formatOptions.classes
        : [formatOptions.classes]
      return classes
        .filter((c) => !!allClasses[c])
        .map((c) => allClasses[c])
        .join(' ')
    }
    return ''
  }

  mapFormatOptionsToStyle (formatOptions, defaults) {
    return {
      ...defaults,
      ...this.mapAlignment(formatOptions)
    }
  }

  mapAlignment (formatOptions = {}) {
    return formatOptions.align ? { textAlign: formatOptions.align } : {}
  }

  formatValues (values, formatOptions = {}) {
    if (!Array.isArray(values)) {
      values = [values]
    }

    let type = formatOptions.type || DEFAULT_FORMAT_TYPE
    // When specifying mixed types, they must match or we use the default type for all
    if (Array.isArray(type) && type.length !== values.length) {
      type = DEFAULT_FORMAT_TYPE
    }

    let result = values
      .map((v, i) => {
        const currentType = Array.isArray(type) ? type[i] : type
        return formatService.formatValue(v, currentType, {
          precision: formatOptions.typePrecision,
          denomination: formatOptions.typeDenomination
        })
      })
      .join(formatOptions.join || DEFAULT_STRING_JOIN)

    if (formatOptions.parens) {
      result = `(${result})`
    }
    return result
  }
}

export const cellFormatService = new CellFormatService()
