import { colors, zIndexes } from '#base/js/variables.style'

import { createUseStyles } from 'react-jss'

export const style = {
  tooltipBox: {
    position: 'absolute',
    pointerEvents: 'none',
    minWidth: 150,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '5px 5px 8px gray',
    borderRadius: 0,
    padding: '15px',
    lineHeight: 1,
    zIndex: zIndexes.chartTooltip,
    textTransform: 'uppercase'
  },
  secondarySection: {
    display: 'flex',
    fontSize: 13,
    whiteSpace: 'nowrap'
  },
  secondaryValue: {
    marginLeft: 3,
    fontWeight: 'bold'
  },
  primarySection: {
    whiteSpace: 'nowrap'
  },
  primaryLabel: {
    fontSize: 16
  },
  values: {
    display: 'flex',
    alignItems: 'center'
  },
  primaryValue: {
    fontSize: 22,
    fontWeight: 'bold'
  },
  tertiaryValue: {
    fontSize: 18,
    marginLeft: 8
  },
  referenceSection: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: 10,
    fontSize: 12
  },
  referenceItem: {
    display: 'flex'
  },
  referenceValue: {
    marginLeft: 3,
    fontWeight: 'bold'
  },
  miniBox: {
    extend: 'tooltipBox',
    padding: '6px 8px',
    whiteSpace: 'nowrap',
    fontSize: 13,
    minWidth: 'auto',
    color: 'white',
    backgroundColor: colors.brandAlt,
    textTransform: 'none'
  },
  listBox: {
    extend: 'tooltipBox',
    whiteSpace: 'nowrap',
    fontSize: 13,
    minWidth: 'auto',
    textTransform: 'none'
  },
  seriesRow: {
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 10
    }
  },
  boxSeriesRow: {
    display: 'grid',
    fontSize: 14,
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    '&:not(:last-child)': {
      marginBottom: 10
    }
  },
  seriesRowBand: {
    extend: 'seriesRow',
    marginLeft: 22
  },
  colorBall: {
    width: 12,
    height: 12,
    borderRadius: 10,
    marginRight: 10
  },
  seriesLabel: {
    marginRight: 5
  },
  seriesValue: {
    fontWeight: 'bold',
    marginRight: 5
  },
  boxSeriesLabel: {
    textAlign: 'right'
  },
  boxSeriesValue: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: 5
  },
  headerValue: {
    fontSize: '1.5rem',
    width: '100%',
    textAlign: 'center'
  }
}

export const useTooltipStyles = createUseStyles(style)
