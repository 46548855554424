import { useStyles } from './switch.style'
import { createUUID } from '../../common/services/uuid.service'

export const SWITCH_TYPE = {
  NORMAL: 'normal',
  MINI: 'mini'
}

export const Switch = ({
  isOn,
  onToggle,
  onLabel,
  offLabel,
  type = SWITCH_TYPE.NORMAL,
  labelClass = '',
  className = '',
  ...props
}) => {
  const classes = useStyles()
  const id = createUUID()

  if (!onLabel && offLabel) {
    onLabel = offLabel
  } else if (onLabel && !offLabel) {
    offLabel = onLabel
  }

  return (
    <div className={`${classes.switchContainer} ${className}`} {...props}>
      {onLabel && offLabel && (
        <div
          className={[
            type === SWITCH_TYPE.MINI
              ? classes.miniSwitchLabel
              : classes.switchLabel,
            labelClass
          ]
            .filter((className) => className)
            .join(' ')}
        >
          {isOn ? onLabel : offLabel}
        </div>
      )}
      <input
        className={classes.checkbox}
        id={id}
        type="checkbox"
        checked={isOn}
        onChange={onToggle}
        disabled={props.disabled}
      />
      <label
        data-qa="switch-label"
        className={
          type === SWITCH_TYPE.MINI ? classes.miniLabel : classes.label
        }
        htmlFor={id}
      >
        <span
          className={
            type === SWITCH_TYPE.MINI ? classes.miniButton : classes.button
          }
          data-qa="switch"
        />
      </label>
    </div>
  )
}
