import { useContext } from 'react'
import { Navigate, Params, useParams } from 'react-router-dom'
import { RouterContext, Router } from '#base/js/common/contexts/routerContext'

export interface Props {
  to:
    | string
    | (({
      params,
      router
    }: {
        params: Readonly<Params<string>>
        router: Router
      }) => string)
}

export function Redirect ({ to }: Props) {
  const params = useParams()
  const { router } = useContext(RouterContext)

  if (typeof to === 'function') {
    to = to({ params, router })
  }

  for (const param in params) {
    to = to.replace(`:${param}`, params[param])
  }

  return <Navigate to={to} />
}
