import { Droppable } from 'react-beautiful-dnd'
import { colors } from '../variables.style'
import { useStyles } from './category-pages.style'
import { CategoryPage } from './category-page'
import { CategoryPageEditor } from './category-page-editor'

export function CategoryPages ({
  group,
  report,
  pageNumberOffset = 0,
  pageSubPage,
  mode = 'view',
  editPage,
  showUnpublishedWarning = false,
  openPageInNewWindowOrTab = false,
  isSelected = (id) => false,
  onEditPage = (page, isEdit) => false,
  onFilterPage,
  onSelectPage = (page, selected) => {},
  onChange = () => {},
  onValidatePage = (page, input, value) => undefined,
  onValidatedPage = (isValid) => true
}) {
  const classes = useStyles()

  const id = group.id
  const pages =
    group.pages && onFilterPage ? group.pages.filter(onFilterPage) : group.pages

  function onChangePage (page, { title, aliases, errors }) {
    const updatedPage = {
      ...page,
      title,
      aliases,
      errors
    }

    onChange({
      ...group,
      pages: pages.map((currentPage) =>
        currentPage.id === updatedPage.id ? updatedPage : currentPage
      )
    })
  }

  if (mode === 'edit') {
    return (
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            className={classes.draggableRowContainer}
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? colors.brandAccent
                : 'transparent'
            }}
            data-qa={`report-category-${group.id}-pages`}
            {...provided.droppableProps}
          >
            {pages.map((page, index) => (
              <CategoryPageEditor
                key={page.id}
                className={classes.draggableRow}
                report={report}
                page={page}
                isEdit={editPage && page.id === editPage.id}
                showUnpublishedWarning={showUnpublishedWarning}
                onEdit={(isEdit) => onEditPage(page, isEdit)}
                onChange={(updates) => onChangePage(page, updates)}
                onValidate={(input, value) =>
                  onValidatePage(page, input, value)
                }
                onValidated={(isValid) => onValidatedPage(page, isValid)}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  } else {
    return (
      <div
        className={classes.rowContainer}
        data-qa={`report-category-${group.id}-pages`}
      >
        {pages.map((page, index) => (
          <CategoryPage
            key={page.id}
            className={classes.row}
            report={report}
            page={page}
            pageSubPage={pageSubPage}
            pageNumber={pageNumberOffset + index + 1}
            showUnpublishedWarning={showUnpublishedWarning}
            openPageInNewWindowOrTab={openPageInNewWindowOrTab}
            isSelected={isSelected}
            onSelect={onSelectPage}
            mode={mode}
            index={index}
          />
        ))}
      </div>
    )
  }
}
