import { ItemCard } from './item-card'

import { useStyles } from './full-page-error.style'

export const FullPageError = function ({
  header = 'Oops, something went wrong.',
  description = 'An error occurred. Please refresh the page to try again'
}) {
  const classes = useStyles()

  return (
    <div className="flex-row flex-center-items full-view-height">
      <ItemCard className="flex-column flex-center-items">
        <div className={classes.card}>
          <div className={classes.cardTitle}>⚠️ {header}</div>
          <div className={classes.cardDescription}>{description}</div>
        </div>
      </ItemCard>
    </div>
  )
}
