import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const style = {
  navItem: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    height: '48px',
    padding: '10px',
    marginRight: '5px',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.brandPrimary
    },

    '&.active': {
      borderBottom: `3px solid ${colors.brandPrimary}`,
      paddingBottom: 7
    }
  }
}

export const useStyles = createUseStyles(style)
