import { useStyles } from './modal.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'

export const Modal = function ({
  className = '',
  bodyClassName = '',
  titleClassName = '',
  overlayClassName = '',
  title = '',
  isOpen,
  footer,
  enableOverlayClick = true,
  showCloseButton = true,
  onClose = () => {},
  children,
  width = 800
}) {
  const classes = useStyles()

  if (!isOpen) return null

  return (
    <div
      className={`${classes.overlay} ${overlayClassName}`}
      onMouseDown={enableOverlayClick ? onClose : function () {}}
    >
      <div
        className={`${classes.window} ${className}`}
        onMouseDown={(event) => {
          event.stopPropagation()
        }}
        style={{ width }}
        data-qa="modal"
      >
        <div className={classes.header}>
          <div
            className={`${classes.title} ${titleClassName}`}
            data-qa="modal-title"
          >
            {title}
          </div>
          {showCloseButton && (
            <FontAwesomeIcon
              icon={faClose}
              className={classes.dismiss}
              onClick={onClose}
              data-qa="modal-close"
            />
          )}
        </div>
        <div
          className={`${classes.body} ${bodyClassName}`}
          data-qa="modal-body"
        >
          {children}
        </div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </div>
  )
}
