import { NavLink } from 'react-router-dom'

import { useStyles } from './navbar-icon.style'

// NOTE: Either to (for link) or onClick (for action), but not both parameters

export const NavbarIcon = function ({ label, to, onClick }) {
  const classes = useStyles()

  return onClick ? (
    <a
      className={`${classes.navItem}`}
      onClick={onClick}
      data-qa={`navbar-${label}`}
    >
      {label}
    </a>
  ) : (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? `${classes.navItem} active` : `${classes.navItem}`
      }
      data-qa={`navbar-${label}`}
    >
      {label}
    </NavLink>
  )
}
