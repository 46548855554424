// Values for all variables here should match with values in _variables.scss.

export const colors = {
  // colors
  brandPrimary: 'var(--theme-brand-primary)',
  brandAlt: 'var(--theme-brand-alt)',
  brandAccent: 'var(--theme-brand-accent)',
  brandHighlight: 'var(--theme-brand-highlight)',
  font: 'var(--theme-font)',
  fontAlt: 'var(--theme-font-alt)',
  background: 'var(--theme-background)',
  backgroundAlt: 'var(--theme-background-alt)',
  border: 'var(--theme-border)',
  searchBorder: 'var(--theme-search-border)',
  actionBarMenuFont: 'var(--theme-action-bar-menu-font)',
  actionBarMenuBackground: 'var(--theme-action-bar-menu-background)',
  modalHeaderActionHover: 'var(--theme-modal-header-action-hover)',
  modalFooter: 'var(--theme-modal-footer)',
  chartBaseline: 'var(--theme-chart-baseline)',
  chartAxis: 'var(--theme-chart-axis-color)',
  placeholder: 'var(--theme-placeholder)',
  tableRowEven: 'var(--theme-table-row-even)',
  tableRowOdd: 'var(--theme-table-row-odd)',
  error: 'rgb(221, 90, 61)',
  info: 'rgb(9, 178, 231)',
  success: 'rgb(53, 187, 123)',
  pending: 'white',
  warning: 'rgb(219,138,6)'
}

export const chartColors = [
  '#d55e00',
  '#0072b2',
  '#cc79a7',
  '#009e73',
  '#f0e442',
  '#911f12',
  '#56b4e9',
  '#e69f00',
  '#92e0b3',
  '#333333'
]

export const sizes = {
  pageHeaderHeight: '80px',
  buttonHeight: '32px',
  textInputHeight: '42px',
  formElementPadding: '16px',
  formElementMediumPadding: '10px',
  navHeight: '48px'
}

export const zIndexes = {
  layoutSidebar: 1,
  chartExportCanvas: 1,
  sideBySideChartFilterBar: 1,
  filterSelectSeparator: 1,
  layoutContent: 2,
  sideBySideChartRight: 3,
  chartLoader: 3,
  editableBlockActionsArea: 3,
  sideBySideChartLeft: 3,
  scrollContent: 4,
  blockConfirmation: 4,
  pageHeader: 8,
  pageControlBar: 9,
  dropdownMenuOverlay: 10,
  inputMessageTooltip: 10,
  chartTooltip: 10,
  dropdownMenu: 11,
  executiveSummaryFullReportLink: 19,
  modalOverlay: 20,
  modal: 21,
  navbar: 40
}

export const fonts = {
  barlow: '"Barlow", san-serif',
  raleway: '"Raleway", sans-serif',
  lato: '"Lato", sans-serif'
}

export const boxShadows = {
  card: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12)',
  hover: '0 8px 16px 0 rgba(0,0,0,0.2)',
  splitLayoutContent:
    '0 0 10px rgba(0, 0, 0, 0.1), 5px 5px 10px rgba(0, 0, 0, 0.1)',
  subLayerBefore: '0 0 5px rgb(0, 0, 0, 0.3), 5px 5px 10px rgb(0, 0, 0, 0.3)',
  subLayerAfter: '0 0 5px rgb(0, 0, 0, 0.3), -5px -5px 10px rgb(0, 0, 0, 0.3)'
}
