import { CellFormatPart } from './cell-format-part.model'

export class CellFormats {
  constructor (formats) {
    Object.keys(formats).forEach((key) => {
      this[key] = formats[key].map(
        (formatPart) => new CellFormatPart(formatPart)
      )
    })
  }
}
