import axios from 'axios'

import { sortService } from '#base/js/common/services/sort.service'
import {
  REPORT_TYPES,
  REPORT_VIEWERS
} from '#common/src/report/report.constants'
import newPageLayout from './editor/new-page-layout.json'

class ReportService {
  async createReport (
    title,
    aliases,
    description,
    viewer = REPORT_VIEWERS.PAGE_LIST
  ) {
    const report = {
      title,
      aliases,
      description,
      viewer
    }

    if (report.viewer === REPORT_VIEWERS.PAGE_LIST) {
      report.type = REPORT_TYPES.PHR
    }

    const { data } = await axios.post('api/report', report)
    return data
  }

  async syncReportFavorites (reportList) {
    const response = await axios.patch('api/user-settings/reports/favorite', {
      reportList
    })
    return response.data
  }

  async getReport (reportId, version, parentVersion) {
    const { data } = await axios.get(`api/report/${reportId}`, {
      params: { version, parentVersion }
    })
    return data
  }

  async updateReport (reportId, changes) {
    const { data } = await axios.post(`api/report/${reportId}`, changes)
    return data
  }

  async updateReportRelated (reportId, { add = [], remove = [] } = {}) {
    const { data } = await axios.patch(`api/report/${reportId}/related`, {
      add,
      remove
    })
    return data
  }

  async getReportList (params) {
    const { data } = await axios.get('api/report/', {
      params
    })
    return data
  }

  async createReportPage (
    title,
    aliases,
    category = { id: '', label: '' },
    viewer = REPORT_VIEWERS.PAGE,
    reportId
  ) {
    const { data } = await axios.post(`api/report/${reportId}/page`, {
      title,
      aliases,
      viewer,
      group: category,
      layout: newPageLayout
    })
    return data
  }

  async createReportVersion (reportId) {
    const { data } = await axios.post(`api/report/${reportId}/version`)
    return data
  }

  async checkReportDuplicateStatus (reportId) {
    const { data } = await axios.get(
      `api/report/${reportId}/data/status?operation=duplicate-report-data`
    )
    return data
  }

  async duplicateReport (reportId, title, description, aliases) {
    const { data } = await axios.post(`api/report/${reportId}/duplicate`, {
      title,
      description,
      aliases
    })
    return data
  }

  async getCategories (reportId, version) {
    const params = {}

    if (version) {
      params.version = version
    }

    const groups = await this.getToC(reportId, params)
    return groups.map((group) => ({ value: group.id, label: group.label }))
  }

  async getToC (
    reportId,
    { includeAvailable, includePages, includeSummary, version }
  ) {
    const { data } = await axios.get(`api/report/${reportId}/groups`, {
      params: {
        includeAvailable,
        includePages,
        includeSummary,
        version
      }
    })
    return data
  }

  hasCommentAccess (report) {
    return ['owner', 'writer', 'reviewer', 'reader', 'readerLimited'].includes(
      report.userRole
    )
  }

  sortReportsByTitle (reports, favorites) {
    return reports.sort(
      sortService.ascendingByWithFavorites('title', favorites || [])
    )
  }

  checkPermissions (report, element) {
    switch (element) {
      case 'add-page':
        return report.isPageList && report.isEditable
      case 'edit':
        return report.isEditable
      case 'duplicate':
        return (
          report.userHasOwnerPermission &&
          report.isPageList &&
          report.hasContent &&
          report.published
        )
      case 'publish-draft':
        return (
          !report.isPage &&
          report.hasContent &&
          report.isPublishable &&
          report.userHasOwnerPermission
        )
      case 'create-draft':
        return (
          !report.isPage &&
          !report.hasDraft &&
          report.hasContent &&
          report.userHasEditPermission &&
          !report.narrative.id
        )
      case 'import-data':
        return !report.isPage && report.isEditable
      case 'manage-data':
        return !report.isPage && report.isEditable
      case 'manage-related-work':
        return report.isPageList && report.userHasEditPermission
      case 'copy-id':
        return report.isEditable
      case 'edit-narrative':
        return (
          report.narrative.id &&
          report.narrative.projectId &&
          report.userHasEditPermission
        )
      case 'change-access':
        return report.canChangeAccess
      case 'add-executive-summary':
        return (
          report.isPageList && report.isEditable && !report.executiveSummary
        )
      case 'view-standalone-bundles':
        return (
          report.isPageList && report.userHasOwnerPermission && report.published
        )
      case 'view-metrics':
        return report.isPageList && report.userHasOwnerPermission
      case 'delete':
        return (
          (report.isEditable || report.isUnversioned || !report.isPage) &&
          report.userHasOwnerPermission
        )
      case 'comment':
        return (
          this.hasCommentAccess(report) &&
          (report.isDraft ? true : report.enablePublishedComments === true)
        )
      default:
        return true
    }
  }
}

export const reportService = new ReportService()
