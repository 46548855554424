/**
 * Connect to a socket.io connection on the API and receive real-time alerts
 *  from the server on any page in the app
 *
 * Props
 */
import { useCallback, useContext, useEffect, useState } from 'react'
import { WebsocketContext } from './contexts/websocketContext'
import {
  NotificationContainer,
  NotificationManager
} from './components/Notifications'
import { websocketEventService } from '../websocket/websocket-event.service'

export const WEBSOCKET_EVENT = {
  name: 'alert'
}

export class AlertClient {
  constructor () {
    this.alertCb = null
  }

  setCallback (cb) {
    this.alertCb = cb
  }

  alert (msg) {
    if (this.alertCb) this.alertCb(msg)
    // eslint-disable-next-line no-console
    console.log('Alert: ' + msg)
  }
}

export const alertClient = new AlertClient()

export function Alerts ({ ignoreContexts }) {
  const { socket } = useContext(WebsocketContext)

  const [alerts, setAlerts] = useState([])

  const pushAlert = (alert) => {
    setAlerts(alerts.concat(alert))
  }

  alertClient.setCallback(pushAlert)

  const notify = useCallback(
    (data) => {
      const types = ['info', 'success', 'warning', 'error']
      let notifyOpts
      const defaultNotify = {
        type: 'info',
        message: ''
      }

      try {
        const obj = JSON.parse(data)
        if (obj.type) obj.type = types.includes(obj.type) ? obj.type : 'info'
        notifyOpts = Object.assign(defaultNotify, obj)
      } catch (e) {
        notifyOpts = Object.assign(defaultNotify, { message: data })
      }

      if (ignoreContexts?.includes(notifyOpts.context)) {
        return
      }

      NotificationManager.create(notifyOpts)
    },
    [ignoreContexts]
  )

  useEffect(() => {
    if (socket) {
      websocketEventService.on(socket, WEBSOCKET_EVENT, notify)

      return () => websocketEventService.off(socket, WEBSOCKET_EVENT, notify)
    }
  }, [socket, notify])

  return <NotificationContainer />
}
