import { useTooltipDisplayContext } from '../../common/tooltip/tooltip.context'
import { formatService } from '../../common/format.service'

import { useStyles, getBarBackground } from './percent-bar.style'

export const PercentBar = ({
  values,
  isRowHover = false,
  isCellHover = false,
  formatOptions
}) => {
  const classes = useStyles()

  const [, setTooltipDisplayData] = useTooltipDisplayContext()

  const value = values[0] // only one value is supported for percent bar
  const displayValue = formatService.formatPercent(value, 2)

  function showTooltip () {
    if (formatOptions.label) {
      setTooltipDisplayData({
        primaryValue: formatOptions.label
      })
    }
  }

  function hideTooltip () {
    if (formatOptions.label) {
      setTooltipDisplayData()
    }
  }

  return (
    <>
      {isRowHover || isCellHover ? (
        <div
          className={classes.displayText}
          onMouseMove={showTooltip}
          onMouseLeave={hideTooltip}
        >
          ({displayValue})
        </div>
      ) : (
        <div
          className={classes.percentBar}
          style={{
            background: getBarBackground(value * 100)
          }}
        />
      )}
    </>
  )
}
