import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { useStyles } from './layered-scroll.style'

const defaultVisibleThresholds = [1, 0.99, 0.98, 0.97, 0.96]
const defaultBoxShadow = ({ percentIntersecting }) =>
  `0 0 10px 5px rgba(0, 0, 0, ${Math.min(percentIntersecting, 0.1) + 0.05})`

export const LayeredScrollContainer = forwardRef(
  function LayeredScrollContainer (
    {
      className,
      headerClassName,
      contentClassName,
      visibleThresholds = defaultVisibleThresholds,
      boxShadow = defaultBoxShadow,
      header,
      headerScrollOverlap = 0,
      children,
      ...props
    },
    ref
  ) {
    const classes = useStyles()

    const rootRef = useRef()
    const headerContainerRef = useRef()
    const headerRef = useRef()
    const contentRef = useRef()

    useImperativeHandle(ref, () => rootRef.current)

    const [headerContainerHeight, setHeaderContainerHeight] = useState(0)
    const [headerHeight, setHeaderHeight] = useState(0)
    const [marginTop, setMarginTop] = useState(0)
    const [contentTop, setContentTop] = useState(0)
    const [percentIntersecting, setPercentIntersecting] = useState(0)
    const [context, setContext] = useState({
      marginTop,
      percentIntersecting
    })

    useEffect(() => {
      const headerContainer = headerContainerRef.current
      const header = headerRef.current
      const headerContainerResizeObserver = new ResizeObserver(
        ([{ contentRect: headerContainer }]) => {
          setHeaderContainerHeight(headerContainer.height)
          setContentTop(headerContainer.height)
        }
      )
      const headerResizeObserver = new ResizeObserver(
        ([{ contentRect: header }]) => {
          setHeaderHeight(header.height)

          if (header.height > headerContainer.offsetHeight) {
            setHeaderContainerHeight(header.height)
            setContentTop(header.height)
          }
        }
      )
      const onWindowResize = () => {
        setHeaderContainerHeight(header.offsetHeight)
        setContentTop(header.offsetHeight)
      }

      headerContainerResizeObserver.observe(headerContainer)
      headerResizeObserver.observe(header)
      window.addEventListener('resize', onWindowResize)

      setHeaderContainerHeight(headerContainer.offsetHeight)
      setContentTop(headerContainer.offsetHeight)
      setHeaderHeight(header.offsetHeight)

      return () => {
        headerContainerResizeObserver.unobserve(headerContainer)
        headerResizeObserver.unobserve(header)
        window.removeEventListener('resize', onWindowResize)
      }
    }, [])

    useEffect(() => {
      const root = rootRef.current
      const content = contentRef.current
      const intersectionObserver = new IntersectionObserver(
        ([{ intersectionRect: root, boundingClientRect: content }]) => {
          // Determine percentage of content that is intersecting (max 100%): (content.y - root.y) / root.height
          setPercentIntersecting((root.y - content.y) / root.height)
        },
        {
          root,
          rootMargin: `${headerScrollOverlap}px`,
          threshold: visibleThresholds
        }
      )

      intersectionObserver.observe(content)

      return () => intersectionObserver.unobserve(content)
    }, [visibleThresholds, headerScrollOverlap])

    useEffect(() => {
      setMarginTop(-headerScrollOverlap + headerHeight)
    }, [headerScrollOverlap, headerHeight])

    useEffect(() => {
      setContext({
        marginTop,
        percentIntersecting
      })
    }, [marginTop, percentIntersecting])

    return (
      <div
        ref={rootRef}
        className={
          className ? `${classes.container} ${className}` : classes.container
        }
        {...props}
      >
        <div
          ref={headerContainerRef}
          className={classes.headerContainer}
          style={{
            top: -headerScrollOverlap,
            height: headerContainerHeight > 0 ? headerContainerHeight : 'auto'
          }}
        >
          <div
            ref={headerRef}
            className={
              headerClassName
                ? `${classes.header} ${headerClassName}`
                : classes.header
            }
            style={{
              boxShadow: percentIntersecting
                ? typeof boxShadow === 'function'
                  ? boxShadow(context)
                  : boxShadow
                : 'none'
            }}
          >
            {typeof header === 'function' ? header(context) : header}
          </div>
        </div>
        <div
          ref={contentRef}
          className={
            contentClassName
              ? `${classes.content} ${contentClassName}`
              : classes.content
          }
          style={{
            marginTop: -contentTop,
            paddingTop: contentTop
          }}
        >
          <LayeredScrollContextProvider context={context}>
            {typeof children === 'function' ? children(context) : children}
          </LayeredScrollContextProvider>
        </div>
      </div>
    )
  }
)

export const LayeredScrollContext = createContext()

export function useLayeredScrollContext () {
  return useContext(LayeredScrollContext)
}

export function LayeredScrollContextProvider ({ context, children } = {}) {
  return (
    <LayeredScrollContext.Provider value={context}>
      {children}
    </LayeredScrollContext.Provider>
  )
}
