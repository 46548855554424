import { createUseStyles } from 'react-jss'

import { colors } from '../variables.style'

export const style = {
  categoryContainer: {
    composes: 'flex-row',
    alignItems: 'center',
    fontSize: 18,
    backgroundColor: colors.brandAlt,
    margin: '24px 0 16px',
    padding: '0 25px 0 10px'
  },
  downArrow: {
    display: 'block',
    fontSize: 14,
    color: 'white',
    width: 26,
    textAlign: 'center',
    cursor: 'pointer',

    '&:hover,&:focus': {
      color: colors.brandPrimary
    }
  },
  upArrow: {
    display: 'block',
    fontSize: 14,
    color: 'white',
    width: 26,
    textAlign: 'center',
    cursor: 'pointer',

    '&:hover,&:focus': {
      color: colors.brandPrimary
    }
  },
  category: {
    composes: 'flex-auto',
    color: 'white',
    padding: '6px 0',
    marginLeft: 9,
    textTransform: 'uppercase',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  mark: {
    width: 20,
    height: 20
  },
  categoryTextFieldContainer: {
    margin: '24px 0 16px'
  },
  categoryTextField: {
    height: 33
  },
  editCategory: {
    color: 'white',
    border: '1px solid transparent',
    padding: '6px 8px',
    fontSize: 15,
    cursor: 'pointer',

    '&:hover,&:focus': {
      color: colors.brandAlt,
      border: `1px solid ${colors.brandAlt}`,
      backgroundColor: colors.background
    }
  },
  deleteCategory: {
    extend: 'editCategory'
  }
}

export const useStyles = createUseStyles(style)
