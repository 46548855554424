import { style, useStyles } from './executive-summary-link.style'
import { Link } from 'react-router-dom'
import { faFileCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import Checkbox from '@mui/material/Checkbox'

import { TooltipIcon } from '../common/components/tooltip-icon'
import { reportRouteService } from './report-route.service'

export const ExecutiveSummaryLink = ({
  className,
  report,
  executiveSummary = report.executiveSummary,
  pageSubPage,
  mode = 'view',
  showUnpublishedWarning = false,
  openInNewWindowOrTab = false,
  isSelected = (id) => false,
  onSelectPage = () => {}
}) => {
  const classes = useStyles()

  if (executiveSummary) {
    return (
      <div
        className={
          className
            ? `${className} ${classes.executiveSummary}`
            : classes.executiveSummary
        }
      >
        {mode === 'select' && (
          <Checkbox
            data-qa="executive-summary-checkbox"
            sx={style.select}
            checked={isSelected(executiveSummary.id)}
            onChange={(e) => onSelectPage(executiveSummary, e.target.checked)}
          />
        )}
        {showUnpublishedWarning && !executiveSummary.published && (
          <TooltipIcon
            className={classes.draftIcon}
            icon={faFileCircleExclamation}
            tooltipLabel="Unpublished"
          />
        )}
        <Link
          className={`${classes.link}`}
          to={reportRouteService.getPageRouteUrl(
            report,
            executiveSummary,
            pageSubPage
          )}
          data-qa="view-executive-summary-link"
          {...(openInNewWindowOrTab && {
            target: '_black',
            rel: 'noreferrer noopener'
          })}
        >
          Executive Summary
        </Link>
      </div>
    )
  } else {
    return null
  }
}
