import { useState, useRef } from 'react'
import {
  useFloating,
  shift,
  offset,
  arrow,
  autoUpdate
} from '@floating-ui/react'
import { useStyles } from './GenericDropdown.style'
import { createPortal } from 'react-dom'

export const MENU_TYPE = {
  DEFAULT: 'default',
  SMALL: 'small'
}

export const GenericDropdown = function ({
  DropdownItems,
  menuTitle,
  Target,
  placement = 'bottom-start',
  dropdownOffset = [0, 0],
  menuType = MENU_TYPE.DEFAULT,
  className = '',
  enableArrow = false,
  onOpen = async () => {},
  onClose = async () => {},
  ...style
}) {
  const arrowRef = useRef(null)
  const portalRoot = document.querySelector('.body-text')

  const {
    x,
    y,
    strategy,
    refs,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} }
  } = useFloating({
    whileElementsMounted: (reference, floating, update) => {
      // IMPORTANT: Make sure the cleanup function is returned
      return autoUpdate(reference, floating, update, {
        animationFrame: true
      })
    },
    placement,
    middleware: [
      offset({ mainAxis: dropdownOffset[0], crossAxis: dropdownOffset[1] }),
      shift(),
      arrow({
        element: arrowRef,
        padding: 5
      })
    ]
  })
  const classes = useStyles()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
  }[placement.split('-')[0]]
  return (
    <>
      {isDropdownOpen && (
        <div
          className={classes.overlay}
          onClick={async (event) => {
            await onClose(event)
            setIsDropdownOpen(false)
          }}
        ></div>
      )}

      <div
        className={className}
        ref={refs.setReference}
        onClick={async (event) => {
          await onOpen(event)
          setIsDropdownOpen(true)
        }}
      >
        {Target}
      </div>

      {isDropdownOpen &&
        createPortal(
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? '',
              left: x ?? '',
              ...style
            }}
            data-placement={placement}
            className={
              menuType === MENU_TYPE.SMALL ? classes.menuSmall : classes.menu
            }
          >
            {menuTitle ? (
              <div title={menuTitle} className={classes.menuTitle}>
                <b>{menuTitle}</b>
              </div>
            ) : (
              ''
            )}
            {DropdownItems.map(({ key, props }) => {
              const { onClick } = props
              return (
                <a
                  key={key}
                  data-qa="dropdown-selection"
                  {...props}
                  onClick={(event) => {
                    setIsDropdownOpen(false)
                    onClick(event)
                  }}
                />
              )
            })}
            {enableArrow ? (
              <div
                style={{
                  left: arrowX != null ? `${arrowX + dropdownOffset[1]}px` : '',
                  top: arrowY != null ? `${arrowY + dropdownOffset[0]}px` : '',
                  position: 'absolute',
                  background: 'white',
                  width: '8px',
                  height: '8px',
                  transform: 'rotate(45deg)',
                  [staticSide]: '-4px'
                }}
                ref={arrowRef}
              ></div>
            ) : (
              ''
            )}
          </div>,
          portalRoot
        )}
    </>
  )
}
