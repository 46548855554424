export const FORMAT_TYPES = {
  NONE: 'none',
  NUMBER: 'number',
  PERCENT: 'percent',
  DATE: 'date',
  CURRENCY: 'currency'
}

export const DEFAULT_PRECISION = 2
export const MAX_PRECISION = 6

export const CURRENCY = {
  USD: 'USD',
  JPY: 'JPY',
  EUR: 'EUR',
  GBP: 'GBP'
}
export const CURRENCY_SYMBOL = {
  USD: '$',
  JPY: '¥',
  EUR: '€',
  GBP: '£'
}
export const DEFAULT_CURRENCY = CURRENCY.USD
