import { Outlet } from 'react-router-dom'
import { NotificationContainer } from '#base/js/common/components/Notifications'

export function ProvideNotifications () {
  return (
    <>
      <NotificationContainer />
      <Outlet />
    </>
  )
}
