import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const style = {
  userCircle: {
    marginRight: 5
  },
  userNotifications: {
    marginRight: 15
  },
  userCircleCaret: {
    marginRight: 15
  },
  userNavContainer: {
    height: '48px',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    marginRight: '5px',
    whiteSpace: 'nowrap'
  },
  timeStamp: {
    color: colors.fontAlt,
    fontStyle: 'italic'
  },
  reportTitle: {
    fontSize: '14px'
  },
  notificationPane: {
    minWidth: '250px',
    '&:hover $timeStamp': {
      color: 'white'
    }
  },
  userMenuPane: {
    minWidth: '180px'
  }
}

export const useStyles = createUseStyles(style)
