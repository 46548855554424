import { colors, sizes } from '../../variables.style'
import { createUseStyles } from 'react-jss'

export const style = {
  button: {
    backgroundColor: colors.brandPrimary,
    borderRadius: '4px',
    border: `1px solid ${colors.brandPrimary}`,
    display: 'inline-block',
    cursor: 'pointer',
    color: 'white',
    padding: '6px 15px',
    textDecoration: 'none',
    height: sizes.buttonHeight,
    fontFamily: 'Arial,sans-serif',

    '&:hover, &:focus, &.active': {
      color: colors.brandPrimary,
      backgroundColor: 'white',
      outline: 'none'
    },

    '&:disabled': {
      opacity: '0.4',
      cursor: 'not-allowed',

      '&:hover, &:focus': {
        color: 'white',
        backgroundColor: colors.brandPrimary
      },

      '& > *': {
        pointerEvents: 'none'
      }
    }
  },
  link: {
    extend: 'button',
    backgroundColor: 'transparent',
    border: 'transparent',
    color: 'black',
    fontSize: '14px',
    textDecoration: 'underline',

    '&:hover, &:focus, &.active': {
      backgroundColor: 'transparent'
    },

    '&:disabled': {
      '&:hover, &:focus': {
        color: 'black',
        backgroundColor: 'transparent'
      }
    }
  },
  linkSansDecoration: {
    extend: 'button',
    padding: '0px 0px',
    backgroundColor: 'transparent',
    border: 'transparent',
    color: 'black',
    fontSize: '12px',
    textDecoration: 'none',
    '&:hover, &:focus, &.active': {
      backgroundColor: 'transparent'
    },

    '&:disabled': {
      '&:hover, &:focus': {
        color: 'black',
        backgroundColor: 'transparent'
      }
    }
  },
  inlineLink: {
    extend: 'link',
    padding: 0,
    display: 'inline',
    height: 'auto',
    color: 'inherit',
    fontFamily: '"Barlow", san-serif'
  },
  secondary: {
    extend: 'button',
    color: 'white',
    backgroundColor: colors.brandAlt,
    border: `1px solid ${colors.brandAlt}`,

    '&:hover, &:focus, &.active': {
      backgroundColor: 'white',
      color: colors.brandAlt
    },

    '&:disabled': {
      '&:hover, &:focus': {
        color: 'white',
        backgroundColor: colors.brandAlt
      }
    }
  },
  iconSecondary: {
    extend: 'button',
    color: 'white',
    backgroundColor: colors.brandAlt,
    borderColor: colors.brandAlt,
    padding: '6px 10px',

    '&:hover, &:focus': {
      color: colors.brandAlt,
      backgroundColor: 'white'
    },

    '&:disabled': {
      '&:hover, &:focus': {
        backgroundColor: colors.brandAlt
      }
    }
  },
  mini: {
    backgroundColor: 'transparent',
    color: colors.brandPrimary,
    border: `1px solid ${colors.brandPrimary}`,
    display: 'inline-block',
    cursor: 'pointer',
    padding: '5px 8px 4px 12px;',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '12px',
    borderRadius: '16px',

    '&:hover, &:focus': {
      color: 'white',
      borderColor: colors.brandPrimary,
      backgroundColor: colors.brandPrimary,
      outline: 'none'
    }
  },
  miniPageList: {
    backgroundColor: 'transparent',
    color: colors.brandPrimary,
    border: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    padding: '5px 0px 5px 0px;',
    textDecoration: 'none',
    textTransform: 'uppercase',
    borderRadius: '0px',

    '&:hover, &:focus': {
      color: 'white',
      borderColor: colors.brandPrimary,
      backgroundColor: colors.brandPrimary,
      outline: 'none'
    }
  },
  miniSecondary: {
    extend: 'mini',
    borderColor: 'black',
    backgroundColor: 'white'
  },
  large: {
    height: sizes.textInputHeight,
    padding: '11px 15px'
  }
}

export const useStyles = createUseStyles(style)
