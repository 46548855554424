import moment from 'moment'

import { ITEM_ACCESS } from '../common/common.constants'
import { REPORT_STATUS, REPORT_STATUS_LABEL, REPORT_ROLE } from './constants'
import {
  REPORT_TYPES,
  REPORT_VIEWERS,
  PAGE_CONTROL_BAR_KEY
} from '#common/src/report/report.constants'

export class Report {
  constructor ({
    id,
    title,
    aliases,
    description,
    type,
    access,
    roles,
    userRole,
    dataVersion,
    dataContainerId,
    viewer = REPORT_VIEWERS.PAGE_LIST,
    narrative,
    lastPublishedVersion = 0,
    publishedDate,
    firstPublishedDate,
    version,
    versionCount = 0,
    published,
    containerId,
    groups = [],
    related = [],
    parent,
    actions,
    layout,
    createdDate,
    modifiedDate,
    enablePublishedComments,
    brand
  } = {}) {
    this.id = id
    this.title = title
    this.aliases = aliases
    this.description = description
    this.type = type || REPORT_TYPES.PHR
    this.access = access
    this.roles = roles || {}
    this.userRole = userRole || REPORT_ROLE.READER
    this.viewer = viewer
    this.narrative = narrative || {}
    this.lastPublishedVersion = lastPublishedVersion
    this.publishedDate = publishedDate
    this.firstPublishedDate = firstPublishedDate
      ? moment.utc(firstPublishedDate).local()
      : undefined
    this.version = version
    this.versionCount = versionCount
    this.published = published
    this.dataVersion = dataVersion
    this.dataContainerId = dataContainerId
    this.containerId = containerId
    this.groups = groups
    this.related = related
    this.parent = parent || {}
    this.actions = actions
    this.layout = this.isPage
      ? Report.getLayoutConfiguration(layout, parent)
      : layout
    this.createdDate = createdDate
    this.modifiedDate = modifiedDate
    this.enablePublishedComments =
      enablePublishedComments !== undefined
        ? enablePublishedComments
        : parent?.enablePublishedComments
    this.brand = brand !== undefined ? brand : parent?.brand
  }

  get isUnversioned () {
    return this.versionCount === 0
  }

  get hasContent () {
    return (
      this.isStatic || this.groups?.filter((group) => !!group.id).length > 0
    )
  }

  get hasDraft () {
    return !this.isUnversioned && this.lastPublishedVersion < this.versionCount
  }

  get hasBeenPublished () {
    return this.lastPublishedVersion > 0
  }

  get isStatic () {
    return [
      REPORT_VIEWERS.IFRAME,
      REPORT_VIEWERS.IFRAME_SUBFOLDER,
      REPORT_VIEWERS.MARKDOWN
    ].includes(this.viewer)
  }

  get isPage () {
    return [
      REPORT_VIEWERS.PAGE,
      REPORT_VIEWERS.DEMO_PAGE,
      REPORT_VIEWERS.EXECUTIVE_SUMMARY_PAGE
    ].includes(this.viewer)
  }

  get isPageList () {
    return [REPORT_VIEWERS.PAGE_LIST, REPORT_VIEWERS.DEMO_PAGE_LIST].includes(
      this.viewer
    )
  }

  get isVersionable () {
    return !this.isPage
  }

  get isInteractive () {
    return this.isPage && this.viewer !== REPORT_VIEWERS.EXECUTIVE_SUMMARY_PAGE
  }

  get isPublishable () {
    return !this.isUnversioned && this.isEditable
  }

  get userHasOwnerPermission () {
    return this.access === ITEM_ACCESS.MANAGE
  }

  get userHasEditPermission () {
    return this.userHasOwnerPermission || this.access === ITEM_ACCESS.EDIT
  }

  get userIsCollaborator () {
    return [
      REPORT_ROLE.OWNER,
      REPORT_ROLE.WRITER,
      REPORT_ROLE.REVIEWER
    ].includes(this.userRole)
  }

  get canChangeAccess () {
    return this.userHasOwnerPermission && !this.isPage
  }

  get isEditable () {
    return this.isDraft && this.userHasEditPermission && !this.isUnversioned
  }

  get isDraft () {
    return this.isPage ? !this.parent.published : !this.published
  }

  get hasPageEditorSupport () {
    return !!this.layout
  }

  get publishedOrUpdated () {
    const date = this.publishedDate || this.modifiedDate
    return moment.utc(date).local()
  }

  get executiveSummary () {
    return Report.getExecutiveSummary(this.groups)
  }

  static getExecutiveSummary (groups = []) {
    const executiveSummaryGroup = groups.find(({ id }) => !id)
    return executiveSummaryGroup?.pages?.find(
      (page) => page && page.viewer === REPORT_VIEWERS.EXECUTIVE_SUMMARY_PAGE
    )
  }

  static getLayoutConfiguration (layout = {}, parent) {
    if (!Array.isArray(layout.blocks) || !parent) {
      return layout
    }

    layout.blocks.forEach((block) => {
      updateSubgroups(block, parent)
      updateStrataOptions(block, parent)
      updateFilterFields(block, parent)
    })

    updatePageFilterFields(layout.pageControlBar, layout.blocks, parent)

    return layout
  }

  getStatus (version) {
    return version <= this.lastPublishedVersion
      ? REPORT_STATUS.PUBLISHED
      : REPORT_STATUS.DRAFT
  }

  getStatusLabel (version) {
    return REPORT_STATUS_LABEL[this.getStatus(version)]
  }

  listVersions () {
    const list = []
    let hasPublishedOption = false
    for (let version = this.versionCount; version > 0; version--) {
      if (hasPublishedOption) {
        continue
      }

      const status = this.getStatus(version)
      const versionInfo = {
        value: version,
        label: this.getStatusLabel(version)
      }

      list.push(versionInfo)

      if (status === REPORT_STATUS.PUBLISHED) {
        hasPublishedOption = true
      }
    }
    return list
  }

  // Returns latest published version if exists, or latest draft is no published exists
  getLatestPublishedVersion (list) {
    const result = list.reduce((previousVersion, currentVersion) => {
      // Initial seed is empty so we have a version to compare against
      if (previousVersion.length === 0) {
        return currentVersion
      }

      if (previousVersion.label !== 'Published') {
        return currentVersion
      } else if (
        currentVersion.label === 'Published' &&
        currentVersion.value > previousVersion.value
      ) {
        return currentVersion
      } else {
        return previousVersion
      }
    }, [])
    if (result.label === 'Draft') {
      return -1
    } else {
      return result.value
    }
  }

  getDataSet (dataKey) {
    return this.isPage ? this.parent.dataSets[dataKey] : this.dataSets[dataKey]
  }
}

function updateSubgroups (block, parent) {
  if (!block.dataKey) {
    return
  }

  const subgroupsFromDataSet =
    ((parent.dataSets || {})[block.dataKey] || {}).subgroups || []

  // Update subgroups configuration with the subgroup dataset metadata
  if (Array.isArray(block.subgroups)) {
    const subgroups = []
    const subgroupsFromDataSetCopy = [...subgroupsFromDataSet]

    block.subgroups.forEach((subgroup) => {
      // null is added when "Overall" is selected in the editor
      if (subgroup.value === null) {
        subgroups.push(subgroup)
        return
      }

      const subgroupIndex = subgroupsFromDataSetCopy.indexOf(subgroup.value)
      if (subgroupIndex > -1) {
        subgroups.push(subgroup)
        subgroupsFromDataSetCopy.splice(subgroupIndex, 1)
      }
    })

    subgroupsFromDataSetCopy.forEach((subgroup) => {
      subgroups.push({
        value: subgroup,
        label: subgroup
      })
    })

    block.subgroups = subgroups
  }

  // Remove stratified subgroups that are not in the subgroup dataset metadata
  if (
    block.props &&
    block.props.strataDefinition &&
    Array.isArray(block.props.strataDefinition.options)
  ) {
    const strataOptions = []

    block.props.strataDefinition.options.forEach((strata) => {
      const doesSubgroupExist = subgroupsFromDataSet.indexOf(strata.value) > -1
      if (doesSubgroupExist || strata.value === null) {
        strataOptions.push(strata)
      }
    })

    block.props.strataDefinition.options = strataOptions
  }

  // Remove filter subgroups that are not in the subgroup dataset metadata
  if (
    block.props &&
    block.props.filterDefinition &&
    Array.isArray(block.props.filterDefinition.fields)
  ) {
    const filterFields = []

    block.props.filterDefinition.fields.forEach((filter) => {
      const doesSubgroupExist = subgroupsFromDataSet.indexOf(filter.key) > -1
      if (doesSubgroupExist || filter.key === null) {
        filterFields.push(filter)
      }
    })

    block.props.filterDefinition.fields = filterFields
  }
}

/**
 * Update stratification definition options. It removes values not in the dataset metadata and appends
 * any values that are new in the dataset metadata.
 */
function updateStrataOptions (block, parent) {
  const strataDefinition = block.props && block.props.strataDefinition
  if (!strataDefinition || !Array.isArray(strataDefinition.options)) {
    return
  }

  block.props.strataDefinition.options = strataDefinition.options.map(
    (strata) => {
      let legend = []
      let valuesFromDataSet = block.dataKey
        ? getSubgroupValueOptions(parent, block.dataKey, strata.value)
        : []
      valuesFromDataSet = valuesFromDataSet.filter(
        ({ value }) => value !== null
      )

      if (!Array.isArray(strata.legend)) {
        return { ...strata, legend: valuesFromDataSet }
      }

      strata.legend.forEach((option) => {
        const index = valuesFromDataSet.findIndex(
          (subgroupValue) => subgroupValue.value === option.value
        )
        if (index >= 0) {
          legend.push({ ...option })
          valuesFromDataSet.splice(index, 1)
        }
      })

      legend = legend.concat(valuesFromDataSet)

      strata = { ...strata, legend }
      return strata
    }
  )
}

/**
 * Update filter definition fields. It removes values not in the dataset metadata and appends any
 * values that are new in the dataset metadata.
 */
function updateFilterFields (block, parent) {
  const filterDefinition = block.props && block.props.filterDefinition
  if (!filterDefinition || !Array.isArray(filterDefinition.fields)) {
    return
  }

  // Move the primary filter to the beginning if it exists
  filterDefinition.fields.sort(({ isPrimary }) => (isPrimary ? -1 : 0))

  block.props.filterDefinition.fields = filterDefinition.fields.map(
    (filter) => {
      const datasetOptions = block.dataKey
        ? getSubgroupValueOptions(parent, block.dataKey, filter.key)
        : []

      return {
        ...filter,
        options: mergeFilterOptions(filter.options, datasetOptions)
      }
    }
  )
}

/**
 * Merge in options from the datasets of each block that uses the page filter.
 * Removes values not in the dataset metadata and appends any values that are
 * new in the dataset metadata.
 */
function updatePageFilterFields (pageControlBar, blocks, parent) {
  const filterDefinition = pageControlBar?.filterDefinition
  if (!filterDefinition || !Array.isArray(filterDefinition.fields)) {
    return
  }

  pageControlBar.filterDefinition.fields = filterDefinition.fields.map(
    (filter) => {
      let dataKeys = blocks
        .filter(
          (b) =>
            b?.dataKey &&
            b?.props?.parentControlBar?.key === PAGE_CONTROL_BAR_KEY &&
            (b?.props?.parentControlBar?.filters ?? []).includes(filter.key)
        )
        .map(({ dataKey }) => dataKey)
      dataKeys = [...new Set(dataKeys)]

      if (dataKeys.length === 0) {
        return filter
      }

      const datasetOptions = []
      dataKeys.forEach((dataKey) => {
        getSubgroupValueOptions(parent, dataKey, filter.key).forEach(
          (option) => {
            if (!datasetOptions.find((o) => o.value === option.value)) {
              datasetOptions.push(option)
            }
          }
        )
      })

      return {
        ...filter,
        options: mergeFilterOptions(filter.options, datasetOptions)
      }
    }
  )
}

/**
 * Combine config options and options from the dataset
 *   - Maintain order from the config options
 *   - Remove options no longer in the dataset
 *   - Append options in dataset that aren't in the config
 */
function mergeFilterOptions (configOptions, datasetOptions) {
  if (!Array.isArray(configOptions)) {
    return datasetOptions
  }

  const options = []

  configOptions.forEach((option) => {
    const index = datasetOptions.findIndex(
      ({ value }) => value === option.value
    )
    if (index >= 0) {
      options.push({ ...option })
      datasetOptions.splice(index, 1)
    }
  })

  return options.concat(datasetOptions)
}

/**
 * Get a list of values/labels for a given subgroup
 */
function getSubgroupValueOptions (parent, dataKey, subgroup, nullLabel = 'All') {
  const values = getSubgroupValues(parent, dataKey, subgroup)
  return values.map((value) => ({
    value,
    label: value === null ? nullLabel : value
  }))
}

/**
 * Get a list of distinct subgroup values for a given subgroup
 */
function getSubgroupValues (parent, dataKey, subgroup) {
  if (!dataKey) {
    return []
  }
  return ((parent.dataSets[dataKey] || {}).subgroupValues || {})[subgroup] || []
}
