import { Tooltip } from '#base/js/common/components/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from './tooltip-icon.style'

export const TooltipIcon = ({ className, icon, tooltipLabel, tooltipType }) => {
  const classes = useStyles()

  return (
    <Tooltip
      className={className ? `${className} ${classes.icon}` : classes.icon}
      title={tooltipLabel}
      type={tooltipType}
      placement="top"
    >
      <FontAwesomeIcon icon={icon} size={'lg'} />
    </Tooltip>
  )
}
