import { ParentSize } from '@visx/responsive'
import { chartSizerService } from './chart-sizer.service'

const AUTO = 'auto'
const FULL = '100%'

/**
 * This component will allow for responsive components that scale to their parent's dimensions
 *
 * Options:
 *   1. Full width / Auto height (default): Adapt to parent width, calculate height based on ratios and max height provided
 *   2. Explicit width/height: this component just passes width/height through to the child component
 *   3. Full width / Full height: Adapt to parent width & height - BE CAREFUL! - if the parent does not have an explicit
 *      height, this will result in an infinite resize since the parent will resize based on child height, the child
 *      will resize based on the new parent height, and so on.
 */
export const ChartSizer = function ({
  width = FULL,
  height = AUTO,
  heightWidthRatio,
  heightViewportRatio,
  maxHeight,
  children
}) {
  if (!Number.isFinite(width)) {
    width = FULL
  }
  if (![AUTO, FULL].includes(height) && !Number.isFinite(height)) {
    height = AUTO
  }

  return (
    <ParentSize>
      {(parent) => {
        if (!parent.ref) {
          return null
        }
        const calcWidth = width === FULL ? parent.width : width
        const calcHeight =
          height === FULL
            ? parent.height
            : height === AUTO
              ? chartSizerService.calculateAutoHeight(
                calcWidth,
                heightWidthRatio,
                heightViewportRatio,
                maxHeight
              )
              : height

        return children({ width: calcWidth, height: calcHeight })
      }}
    </ParentSize>
  )
}
