import { Outlet } from 'react-router-dom'
import { WebsocketContextProvider } from '#base/js/common/contexts/websocketContext'

export function ProvideWebsocketContext ({ namespace }) {
  return (
    <WebsocketContextProvider namespace={namespace}>
      <Outlet />
    </WebsocketContextProvider>
  )
}
