import { Loader } from './loader'

export const FullPageLoader = ({ alt = false }) => {
  const height = alt ? 'full-view-alt' : 'full-view-height'
  return (
    <div className={`flex-row flex-center-items ${height}`}>
      <Loader />
    </div>
  )
}
