import { httpService } from '#base/js/common/services/http.service'

/**
 * Service that handles building report routes
 */
export class ReportRouteService {
  /**
   * Get base url
   *
   * @returns {string} Base url
   */
  getBaseUrl () {
    return httpService.isStandalone ? httpService.getBaseURL() : '/reports'
  }

  /**
   * Is the app routing by alias?
   *
   * @returns {boolean}
   */
  isRoutingByAlias () {
    return httpService.isStandalone
  }

  /**
   * Get the sub page path
   *
   * @param {string} subPage Sub page
   * @returns {string} Sub page path
   */
  getSubPagePath (subPage) {
    switch (subPage) {
      case 'view':
        return httpService.isStandalone ? '' : 'view'
      default:
        return subPage
    }
  }

  /**
   * Get report list url
   *
   * @param {Report} report Report
   * @param {object} [options]
   * @param {number} [options.version] Report version
   * @returns {string} List url
   */
  getListUrl (report, { version } = {}) {
    // No parent, go back to list
    if (!report.parent.id) {
      return this.getBaseUrl()
    }

    return this.getRouteUrl(report.parent, 'view', {
      version
    })
  }

  /**
   * Get report route url
   *
   * @param {Report} report Report
   * @param {object} [options]
   * @param {number} [options.version] Report version
   * @param {number} [options.parentVersion] Report parent version
   * @returns {string} Route url
   */
  getRouteUrl (report, subPage = 'view', { version, parentVersion } = {}) {
    let url = this.getBaseUrl()
    if (this.isRoutingByAlias() && report.aliases?.[0]) {
      url = `${url}/${report.aliases[0]}`
    } else {
      url = `${url}/${report.id}`
    }
    if (subPage) {
      const subPagePath = this.getSubPagePath(subPage)
      if (subPagePath) {
        url = `${url}/${subPagePath}`
      }
    }

    const params = []

    if (!httpService.isStandalone) {
      if (version) {
        params.push(`version=${version}`)
      }
      if (parentVersion) {
        params.push(`parentVersion=${parentVersion}`)
      }
    }

    if (params.length > 0) {
      url = `${url}?${params.join('&')}`
    }

    return url
  }

  /**
   * Get page route url
   *
   * @param {Report} report Report
   * @param {Report} page Page
   * @param {string} subPage Sub page
   * @returns {string} Page route url
   */
  getPageRouteUrl (report, page, subPage) {
    const pageListReport =
      report && Object.keys(report.parent).length > 0 ? report.parent : report

    // Only pass the version if the parent version is not the latest
    return pageListReport.version < pageListReport.versionCount
      ? this.getRouteUrl(page, subPage, {
        version: page.version,
        parentVersion: pageListReport.version
      })
      : this.getRouteUrl(page, subPage)
  }

  /**
   * Get pagination link
   *
   * @param {object} config
   * @param {Report} config.report Report
   * @param {number} config.parentVersion Report parent version
   * @param {string} config.type Link type
   * @returns {string} Pagination link
   */
  getPaginationLink ({ report, parentVersion, type = 'next' }) {
    if (!['next', 'prev'].includes(type)) {
      // eslint-disable-next-line no-console
      console.warn(`Invalid pagination link type: ${type}. Setting to "next"`)
    }
    const action = report.actions[type]
    if (!action) {
      return ''
    }

    return this.getRouteUrl(
      action,
      'view',
      parentVersion
        ? {
            version: action.version,
            parentVersion
          }
        : {}
    )
  }
}

export const reportRouteService = new ReportRouteService()
