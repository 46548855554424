import { createUseStyles } from 'react-jss'
import { colors } from '../../variables.style'

export const style = {
  switchContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  switchLabel: {
    marginRight: 5
  },
  checkbox: {
    height: 0,
    width: 0,
    display: 'none',
    '&:checked + $label $button': {
      left: 'calc(100% - 2px)',
      transform: 'translateX(-100%)'
    },
    '&:checked + $label': {
      background: colors.brandAccent
    },
    '&:checked + $miniLabel $miniButton': {
      left: 'calc(100% - 2px)',
      transform: 'translateX(-100%)'
    },
    '&:checked + $miniLabel': {
      background: colors.brandAccent
    },
    '&:disabled + $miniLabel': {
      background: colors.fontAlt
    },
    '&:disabled': {
      '&:checked + $miniLabel': {
        background: 'rgb(160, 200, 218)'
      }
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: 50,
    height: 25,
    background: colors.brandAlt,
    borderRadius: 100,
    position: 'relative',
    transition: 'background-color .2s',
    '&:active $button': {
      width: 30
    }
  },
  button: {
    content: '',
    position: 'absolute',
    top: 2,
    left: 2,
    width: 20,
    height: 21,
    borderRadius: 25,
    transition: '0.2s',
    background: '#fff'
  },
  miniSwitchLabel: {
    extend: 'switchLabel',
    fontSize: 14
  },
  miniLabel: {
    extend: 'label',
    height: 20,
    width: 34
  },
  miniButton: {
    extend: 'button',
    width: 16,
    height: 16
  }
}

export const useStyles = createUseStyles(style)
