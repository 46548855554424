import { createContext, useContext } from 'react'

const ReportTocContext = createContext()

export const useReportTocContext = function () {
  return useContext(ReportTocContext)
}

class ReportTocChanges {
  constructor (report) {
    this.report = report
    this.changes = {
      title: report.title,
      type: report.type,
      description: report.description,
      groups: report.groups,
      copyrightMsg: report?.brand?.copyrightMsg || '',
      customLogo: report?.brand?.customLogo || '',
      pageLevelBrand: report?.brand?.pageLevelBrand || false
    }
  }

  setNewType (type) {
    this.changes.type = type
  }

  setNewTitle (title) {
    this.changes.title = title
  }

  setNewDescription (description) {
    this.changes.description = description
  }

  setNewGroups (groups) {
    this.changes.groups = groups
  }

  setCopyrightMsg (msg) {
    this.changes.copyrightMsg = msg
  }

  setCustomLogo (file) {
    this.changes.customLogo = file
  }

  setPageLevelBrand (flag) {
    this.changes.pageLevelBrand = flag
  }

  clearChanges () {
    this.changes = {}
  }

  isValid () {
    const hasPageErrors = this.changes.groups.some(({ pages }) =>
      pages.some(({ errors }) => errors && errors.length > 0)
    )

    return !hasPageErrors
  }

  getChangesParams () {
    const report = {}
    const pages = []

    if (
      this.changes.copyrightMsg !== undefined ||
      this.changes.customLogo !== undefined ||
      this.changes.pageLevelBrand !== undefined
    ) {
      report.brand = {}
    }

    if (this.changes.title !== this.report.title) {
      report.title = this.changes.title
    }

    if (this.changes.type !== this.report.type) {
      report.type = this.changes.type
    }

    if (this.changes.description !== this.report.description) {
      report.description = this.changes.description
    }

    if (this.changes.groups !== this.report.groups) {
      report.groups = this.mapGroupUpdates(this.changes.groups)

      const reportPages = this.report.groups.map(({ pages }) => pages).flat()
      const changesPages = this.changes.groups.map(({ pages }) => pages).flat()

      changesPages.forEach(({ id, title, aliases }) => {
        const isUpdated = reportPages.some(
          (reportPage) =>
            reportPage.id === id &&
            (reportPage.title !== title || reportPage.aliases !== aliases)
        )
        if (isUpdated) {
          pages.push({ id, title, aliases })
        }
      })
    }

    if (this.changes.copyrightMsg !== undefined) {
      report.brand.copyrightMsg = this.changes.copyrightMsg
    }

    if (this.changes.customLogo !== undefined) {
      report.brand.customLogo = this.changes.customLogo
    }

    if (this.changes.pageLevelBrand !== undefined) {
      report.brand.pageLevelBrand = this.changes.pageLevelBrand
    }

    return {
      report,
      pages
    }
  }

  /**
   * Full page structure should not be sent to the server for an update - only the page id & version
   */
  mapGroupUpdates (groups) {
    return groups.map((group) => {
      const pages = group.pages.map((page) => {
        return { id: page.id, version: page.version }
      })
      return { ...group, pages }
    })
  }
}

export const ReportTocContextProvider = function ({ report, children }) {
  const reportToc = new ReportTocChanges(report)
  return (
    <ReportTocContext.Provider value={reportToc}>
      {children}
    </ReportTocContext.Provider>
  )
}
