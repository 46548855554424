import { createUseStyles } from 'react-jss'

import { colors } from '../variables.style'

export const style = {
  rowContainer: {
    marginLeft: 16
  },
  draggableRowContainer: {
    minHeight: 38
  },
  row: {
    composes: 'flex-row',
    alignItems: 'center',
    marginBottom: 16
  },
  draggableRow: {
    marginTop: '6px',
    border: `1px solid ${colors.brandAlt}`,
    borderRadius: '4px',
    overflow: 'hidden'
  }
}

export const useStyles = createUseStyles(style)
