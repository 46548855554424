class ChartSizerService {
  calculateAutoHeight (
    width,
    heightWidthRatio,
    heightViewportRatio,
    explicitMaxHeight
  ) {
    let maxFromWidthRatio = Infinity
    let maxFromViewportRatio = Infinity

    if (!Number.isFinite(explicitMaxHeight)) {
      explicitMaxHeight = Infinity
    }
    if (Number.isFinite(heightWidthRatio)) {
      maxFromWidthRatio = width * heightWidthRatio
    }
    if (Number.isFinite(heightViewportRatio)) {
      maxFromViewportRatio = window.innerHeight * heightViewportRatio
    }
    if (
      !Number.isFinite(explicitMaxHeight) &&
      !Number.isFinite(maxFromWidthRatio) &&
      !Number.isFinite(maxFromViewportRatio)
    ) {
      maxFromViewportRatio = window.innerHeight * 0.9
    }

    return Math.min(explicitMaxHeight, maxFromWidthRatio, maxFromViewportRatio)
  }
}

export const chartSizerService = new ChartSizerService()
