import { CategoryBar } from './category-bar'
import { CategoryPages } from './category-pages'

export const Category = ({
  report,
  groups,
  index,
  pageNumberOffset,
  pageSubPage,
  mode = 'view',
  editPage,
  showUnpublishedWarning = false,
  openPageInNewWindowOrTab = false,
  isSelectedPage,
  onChange = (groups) => {},
  onEditPage,
  onFilterPage,
  onSelectPage,
  ...props
}) => {
  function onChangePage (updatedGroup) {
    onChange(
      groups.map((currentGroup) =>
        currentGroup.id === updatedGroup.id ? updatedGroup : currentGroup
      )
    )
  }

  async function onValidatePage (page, input, value) {
    const values = Array.isArray(value) ? value : [value]
    for (const value of values) {
      if (input === 'alias') {
        // Is this alias in use by another page?
        const isDuplicate = groups.some(({ pages }) =>
          pages.some(
            ({ id, aliases }) => id !== page.id && aliases?.includes(value)
          )
        )
        if (isDuplicate) {
          return {
            type: 'error',
            message:
              'Sorry, this page alias already exists on this report. Try another?'
          }
        }
      }
    }
  }

  return (
    <div {...props}>
      <CategoryBar
        groups={groups}
        index={index}
        mode={mode}
        onChange={onChange}
      />
      <CategoryPages
        group={groups[index]}
        report={report}
        pageNumberOffset={pageNumberOffset}
        pageSubPage={pageSubPage}
        mode={mode}
        editPage={editPage}
        showUnpublishedWarning={showUnpublishedWarning}
        openPageInNewWindowOrTab={openPageInNewWindowOrTab}
        isSelected={isSelectedPage}
        onEditPage={onEditPage}
        onFilterPage={onFilterPage}
        onSelectPage={onSelectPage}
        onChange={onChangePage}
        onValidatePage={onValidatePage}
      />
    </div>
  )
}
