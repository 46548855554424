import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { Label } from './label'
import { useStyles } from './text-input.style'

export const TextInput = function ({
  size = 'large',
  label,
  type = 'text',
  enableFocusAnimation = true,
  _ref,
  className = '',
  message,
  labelClass = '',
  ...props
}) {
  const classes = useStyles()
  return (
    <>
      <label>
        {label && (
          <Label
            className={
              size === 'medium'
                ? 'form-element-label-medium'
                : 'form-element-label'
            }
            required={props.required}
          >
            {label}
          </Label>
        )}
        <div className="form-element-wrapper">
          <div>
            <input
              data-qa={`text-input-${label}`}
              type={type}
              className={[
                'form-element',
                props.required && 'form-element-error',
                size === 'medium' ? classes.inputMedium : classes.input,
                className
              ]
                .filter((className) => className)
                .join(' ')}
              ref={_ref}
              {...props}
            />
            {enableFocusAnimation && (
              <div className="form-element-border"></div>
            )}
          </div>
        </div>
      </label>
      {message && (
        <div
          className={`${classes.message} ${message.type}`}
          data-qa={`text-input-message-${label}`}
        >
          <FontAwesomeIcon
            className={classes.emptyStateIcon}
            icon={faCircleExclamation}
            size="xl"
          />
          {message.message}
        </div>
      )}
    </>
  )
}
