import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import { useStyles } from './table-section.style'
import { TableRowMemo } from './table-row'

const TableSection = function ({
  section,
  columns,
  selectedRows = new Set(),
  shiftBy,
  onClick
}) {
  const classes = useStyles()
  const [isCollapsed, setIsCollapsed] = useState(section.isCollapsed)

  return (
    <>
      <div
        className={classes.sectionLabelCell}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
        data-qa={'section-head-' + section.key}
      >
        <div className={classes.sectionToggle}>
          <FontAwesomeIcon
            className={classes.sectionToggleIcon}
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </div>
        {section.label}
      </div>
      <div
        className={`${classes.dataRowsBody} ${
          isCollapsed ? classes.collapsed : ''
        }`}
        data-qa={'section-body-' + section.key}
      >
        {section.rows.map((row, index) => (
          <TableRowMemo
            rows={section.rows}
            index={index}
            key={row.key}
            selectedRows={selectedRows}
            columns={columns}
            section={section}
            shiftBy={shiftBy}
            onClick={onClick}
          />
        ))}
      </div>
    </>
  )
}

export { TableSection }
