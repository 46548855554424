import { createContext, useContext, useRef, useState } from 'react'

import { useTooltipContextStyles } from './tooltip.context.style'

const TooltipContainerContext = createContext()
const TooltipPositionContext = createContext()
const TooltipDisplayContext = createContext()

export const useTooltipContainerContext = function () {
  return useContext(TooltipContainerContext)
}

export const useTooltipPositionContext = function () {
  return useContext(TooltipPositionContext)
}

export const useTooltipDisplayContext = function () {
  return useContext(TooltipDisplayContext)
}

export const TooltipContextProvider = function ({ children, className }) {
  const classes = useTooltipContextStyles()
  const tooltipContainerRef = useRef()
  const tooltipPositionRef = useRef()
  const tooltipDisplayDataState = useState()

  const [tooltipDisplayData] = tooltipDisplayDataState

  return (
    <TooltipContainerContext.Provider value={tooltipContainerRef}>
      <TooltipPositionContext.Provider value={tooltipPositionRef}>
        <TooltipDisplayContext.Provider value={tooltipDisplayDataState}>
          <div
            ref={tooltipContainerRef}
            className={`${classes.container} ${className || ''}`}
          >
            <div
              ref={tooltipPositionRef}
              style={!tooltipDisplayData ? { visibility: 'hidden' } : {}}
              className={`${classes.positionDiv} tooltip-position`}
            ></div>
            {children}
          </div>
        </TooltipDisplayContext.Provider>
      </TooltipPositionContext.Provider>
    </TooltipContainerContext.Provider>
  )
}
