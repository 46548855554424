import { CSSProperties } from 'react'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from './loader.style'

export interface LoaderProps {
  className: string
  style: CSSProperties
}

export const Loader = function ({ className = '', style = {} }: LoaderProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.container} ${className}`} style={style}>
      <div className={classes.rectangle1}></div>
      <div className={classes.rectangle2}></div>
      <div className={classes.rectangle3}></div>
      <div className={classes.rectangle4}></div>
      <div className={classes.rectangle5}></div>
    </div>
  )
}

export interface SpinLoaderProps {
  className: string
  size?: SizeProp
}

export const SpinLoader = function ({
  className = '',
  size = 'xl'
}: SpinLoaderProps) {
  const classes = useStyles()

  return (
    <FontAwesomeIcon
      className={className ? `${classes.icon} ${className}` : classes.icon}
      icon={faLoader}
      size={size}
    />
  )
}
