import { useEffect, useState } from 'react'
import { TextInput } from './text-input'
import {
  ALIAS_VALID,
  aliasService
} from '#base/js/common/services/alias.service'

export function isDuplicateAliasError (error) {
  return error?.response?.data?.key === 'DUPLICATE_ALIAS'
}

export const AliasInput = function ({
  label,
  seed = '',
  value = '',
  onChange,
  message,
  placeholder,
  ...props
}) {
  const [isAuto, setIsAuto] = useState(!value)

  function validate (value) {
    if (!ALIAS_VALID.test(value)) {
      return {
        type: 'error',
        message:
          'Sorry, only lowercase letters, numbers, and dashes are allowed.'
      }
    } else if (value.length > 50) {
      return {
        type: 'error',
        message: `Sorry, "${value}" is too long. Try shortening?`
      }
    }
  }

  useEffect(() => {
    if (isAuto) {
      const alias = aliasService.format(seed)

      onChange({
        alias,
        error: validate(alias)
      })
    }
  }, [seed])

  return (
    <TextInput
      label={label}
      value={value}
      onChange={(event) => {
        const alias = event.target.value

        onChange({
          alias,
          error: validate(alias)
        })

        setIsAuto(!alias)
      }}
      message={message}
      placeholder={placeholder}
      {...props}
    />
  )
}
