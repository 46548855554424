import throttle from 'lodash/throttle'

// The amount of time to throttle event detection, in milliseconds.
// It is set to 5 seconds to avoid updating localStorage too often.
const EVENT_THROTTLE_AMOUNT = 5000

class IdleTimeoutManagerService {
  constructor () {
    // Call init() to set to true
    this._runnable = false

    this.options = {
      // The total timeout amount, in seconds. Defaults to 30 minutes.
      timeout: 1800,

      // The amount of warning to give the user, in seconds. Defaults to 2 minutes.
      warningTimeout: 120,

      // The key that gets saved in localstorage for tracking
      key: '_idle_session_exp',

      // The DOMevents to detect
      events: [
        'mousemove',
        'scroll',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
        'visibilitychange'
      ]
    }

    // The event handler function that gets called when any of the above events fire
    this._eventHandler = throttle(() => {
      localStorage.setItem(this.options.key, this.getExpirationTimestamp())
    }, EVENT_THROTTLE_AMOUNT)
  }

  init ({ timeout, warningTimeout }) {
    this.options.timeout = Number(timeout)
    if (warningTimeout && !isNaN(warningTimeout)) {
      this.options.warningTimeout = Number(warningTimeout)
    } else {
      // Default to 2 minutes
      this.options.warningTimeout = 120
    }
    this._runnable = true
  }

  isRunnable () {
    return this._runnable
  }

  getExpirationTimestamp () {
    let timeout = this.options.timeout - this.options.warningTimeout
    if (timeout < 0) {
      // Default timer to 5 minutes if timeout is less than the warningTimeout
      timeout = 300
    }
    return Date.now() + timeout * 1000
  }

  getWarningExpirationTimestamp () {
    return Date.now() + this.options.warningTimeout * 1000
  }

  awaitIdle (expireCallback) {
    this._eventHandler()
    this._tracker()

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(this.options.key))
      const currentTime = Date.now()
      if (expiredTime < currentTime) {
        this.clear()
        if (expireCallback) {
          expireCallback(expiredTime)
        }
      }
    }, 1000)

    return this.interval
  }

  _tracker () {
    for (const event of this.options.events) {
      window.addEventListener(event, this._eventHandler)
    }
  }

  _clearTracker () {
    for (const event of this.options.events) {
      window.removeEventListener(event, this._eventHandler)
    }
  }

  clear () {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this._clearTracker()
  }
}

export const idleTimeoutManagerService = new IdleTimeoutManagerService()
