import { Link } from 'react-router-dom'
import { faFileCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import Checkbox from '@mui/material/Checkbox'
import { TooltipIcon } from '../common/components/tooltip-icon'
import { reportRouteService } from './report-route.service'
import { style, useStyles } from './category-page.style'

export function CategoryPage ({
  className,
  report,
  page,
  pageNumber = 0,
  pageSubPage,
  mode = 'view',
  showUnpublishedWarning = false,
  openPageInNewWindowOrTab = false,
  isSelected = (id) => false,
  onSelect = () => {},
  onChange = () => {},
  ...props
}) {
  const classes = useStyles()

  return (
    <div className={className} {...props}>
      {mode === 'select' ? (
        <Checkbox
          data-qa={`page-checkbox-${page.id}`}
          sx={style.select}
          checked={isSelected(page.id)}
          onChange={(e) => onSelect(page, e.target.checked)}
        />
      ) : (
        <div
          className={
            page.id === report.id
              ? classes.currentPageNumber
              : classes.pageNumber
          }
        >
          {pageNumber}
        </div>
      )}
      {showUnpublishedWarning && !page.published && (
        <TooltipIcon
          className={classes.draftIcon}
          icon={faFileCircleExclamation}
          tooltipLabel="Unpublished"
        />
      )}
      {page.id === report.id ? (
        <div className={classes.currentReport}>{page.title}</div>
      ) : (
        <Link
          className={classes.pageLink}
          to={reportRouteService.getPageRouteUrl(report, page, pageSubPage)}
          data-qa={`page-link-${page.id}`}
          {...(openPageInNewWindowOrTab && {
            target: '_black',
            rel: 'noreferrer noopener'
          })}
        >
          {page.title}
        </Link>
      )}
    </div>
  )
}
