export const ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
}

export const CELL_FORMAT_TYPE = {
  NONE: 'none',
  NUMBER: 'number',
  PERCENT_BAR: 'percent_bar',
  CONFIDENCE: 'confidence',
  TEXT: 'text'
}

export const CONFIDENCE_SCALE_SCOPE = {
  SECTION: 'section',
  TABLE: 'table'
}
