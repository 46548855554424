import { useStyles } from './navbar-layout.style'
import { Navbar } from './navbar'

export const NavbarLayout = function ({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.layout}>
      <Navbar />
      <div className="app-content">{children}</div>
    </div>
  )
}
