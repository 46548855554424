import { useEffect } from 'react'
import {
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Router } from '#base/js/common/contexts/routerContext'

export interface Settings {
  enableSentry?: boolean
  sentryTracesSampleRate?: number
}

export class SentryService {
  initialize (appSettings: Settings = {}, { location }: Router) {
    const { enableSentry, sentryTracesSampleRate } = appSettings

    if (
      enableSentry &&
      !isNaN(sentryTracesSampleRate) &&
      sentryTracesSampleRate > 0 &&
      sentryTracesSampleRate <= 1
    ) {
      Sentry.init({
        dsn: __CONFIG__.sentryDsn,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              location,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            )
          })
        ],
        tracesSampleRate: sentryTracesSampleRate
      })
    }
  }
}

export const sentryService = new SentryService()
