import { AxiosResponse } from 'axios'
import { NotificationManager } from '../components/Notifications'

export class ErrorHandlerService {
  handleServerError (
    error: any,
    message: string | ((response: AxiosResponse) => string)
  ) {
    if (error && error.response) {
      // eslint-disable-next-line no-console
      console.error(
        `${error.response.status} Error: ${error.response.data.message}`
      )
      if (error.response.status === 401) {
        return
      }

      NotificationManager.create({
        type: 'error',
        timeOut: 20000,
        message:
          typeof message === 'function' ? message(error.response) : message
      })
    } else {
      // handle errors coming from our code, not from the server
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

export const errorHandlerService = new ErrorHandlerService()
