import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const borderStyle = `1px solid ${colors.border}`
export const paddingRight = 10

export const style = {
  table: {
    fontSize: 14,
    margin: '0 auto'
  },
  tableBottomFade: {
    position: 'relative',
    marginTop: -40,
    marginRight: 15,
    width: '100%',
    height: 40,
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${colors.background})`
  },
  rowsWithoutSection: {
    borderLeft: borderStyle,
    borderRight: borderStyle
  },
  downloadLink: {
    display: 'flex',
    paddingTop: 25,
    justifyContent: 'center'
  }
}

export const useStyles = createUseStyles(style)
