import { CellFormat } from './cell-formats/cell-format'

import { useStyles } from './table-data-cell.style'

const TableDataCell = function ({
  parts = [],
  rows,
  rowIndex,
  columns,
  colIndex,
  section,
  width,
  isRowHover = false,
  flexDefault = 1,
  onClick
}) {
  const classes = useStyles()

  function handleClick () {
    if (onClick) {
      const row = rows[rowIndex] || {}
      const column = columns.dataColumns[colIndex]
      onClick({ section, row, column, values: parts })
    }
  }

  return (
    <div
      className={onClick ? classes.dataCellClickable : classes.dataCell}
      style={{ width }}
      onClick={handleClick}
      data-qa={`data-column-${colIndex + 1}`}
    >
      {parts.map(({ format, formatOptions, values }, i) => (
        <div
          key={`${i}-${values}`}
          className={classes.dataCellValue}
          style={{ flex: (formatOptions || {}).flex || flexDefault }}
          data-qa={`data-cell-value-${i + 1}`}
        >
          <CellFormat
            format={format}
            formatOptions={formatOptions}
            values={values}
            rows={rows}
            rowIndex={rowIndex}
            colIndex={colIndex}
            cellIndex={i}
            isRowHover={isRowHover}
          />
        </div>
      ))}
    </div>
  )
}

export { TableDataCell }
