import { useEffect, useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import {
  faBars,
  faCircleExclamation,
  faFileCircleExclamation,
  faPencil
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AliasInput } from '../common/components/alias-input'
import { Button } from '../common/components/button'
import { ContentAdjustingContainer } from '../common/components/content-adjusting-container'
import { TooltipIcon } from '../common/components/tooltip-icon'
import { TextInput } from '../common/components/text-input'
import { useStyles } from './category-page-editor.style'

export function CategoryPageEditor ({
  className,
  report,
  page,
  pageMode = 'view',
  isEdit = false,
  showUnpublishedWarning = false,
  onEdit = (isEdit) => {},
  onChange = (updates) => {},
  onValidate = async (input, value) => undefined,
  ...props
}) {
  const classes = useStyles()

  const parentAliasPattern = new RegExp(`^(${report.aliases.join('/|')}/)`)

  const [title, setTitle] = useState({
    value: page.title
  })
  const [aliases, setAliases] = useState(getAliases(page.aliases ?? []))
  const [resetTitle, setResetTitle] = useState(title)
  const [resetAliases, setResetAliases] = useState(aliases)

  const formRef = useRef()

  function getFullAlias (alias) {
    if (!alias || parentAliasPattern.test(alias)) {
      return [alias]
    } else {
      return report.aliases.map((reportAlias) => `${reportAlias}/${alias}`)
    }
  }

  function getFullAliases (aliases) {
    return aliases
      .map(({ value, error }) =>
        getFullAlias(value).map((value) => ({
          value,
          error
        }))
      )
      .flat()
  }

  function getAlias (alias) {
    return {
      value: alias.replace(parentAliasPattern, '')
    }
  }

  function getAliases (aliases) {
    return aliases
      .map((alias) => getAlias(alias))
      .filter(
        (a, index, aliases) =>
          aliases.findIndex((b) => a.value === b.value) === index
      )
  }

  async function validate () {
    let isValid = !title.error && !aliases.some(({ error }) => !!error)
    if (isValid) {
      // Validate title/alias inputs against parent validation
      const validations = (
        await Promise.all([
          onValidate('title', title.value),
          ...getFullAliases(aliases).map(({ value }) =>
            onValidate('alias', value)
          )
        ])
      ).map((error) => ({ error }))

      const [validatedTitle, ...validatedAliases] = validations
      if (validatedTitle.error) {
        setTitle({
          value: title.value,
          error: validatedTitle.error
        })
      }

      const hasAliasErrors = validatedAliases.some(({ error }) => !!error)
      if (hasAliasErrors) {
        const updatedAliases = aliases.map(({ value }, index) => ({
          value,
          error: validatedAliases[index]?.error
        }))

        setAliases(updatedAliases)
      }

      isValid = !validations.some(({ error }) => !!error)
    }

    return isValid
  }

  async function onUpdate () {
    await validate()

    const fullAliases = getFullAliases(aliases).filter(({ value }) => !!value)

    const errors = []
    if (title.error) {
      errors.push(title.error)
    }

    for (const { error } of fullAliases) {
      if (error) {
        errors.push(error)
      }
    }

    onChange({
      title: title.value,
      aliases: fullAliases.map(({ value }) => value),
      errors
    })
  }

  async function onClose () {
    onEdit(false)
  }

  async function onReset () {
    setTitle(resetTitle)
    setAliases(resetAliases)

    onEdit(false)
  }

  async function onKeyDown ({ key }) {
    switch (key) {
      case 'Enter':
        await onClose()
        break
      case 'Escape':
        await onReset()
        break
    }
  }

  async function onTitleChange (value) {
    setTitle({
      value
    })
  }

  async function onAliasChange ({ alias, error }, index) {
    const updateAlias = {
      value: alias,
      error
    }

    const updatedAliases =
      aliases.length > 0
        ? aliases.map((currentAlias, currentIndex) =>
          currentIndex === index ? updateAlias : currentAlias
        )
        : [updateAlias]

    setAliases(updatedAliases)
  }

  useEffect(() => {
    onUpdate()
  }, [title, aliases])

  useEffect(() => {
    if (isEdit) {
      setResetTitle(title)
      setResetAliases(aliases)
    }
  }, [isEdit])

  return (
    <Draggable draggableId={page.id} {...props}>
      {(provided) => (
        <div data-qa={`page-draggable-${page.id}`} {...provided.draggableProps}>
          <ContentAdjustingContainer
            ref={provided.innerRef}
            className={className}
            containerClassName={classes.container}
          >
            <div className={classes.handle} {...provided.dragHandleProps}>
              <FontAwesomeIcon icon={faBars} size={'lg'} />
            </div>
            {showUnpublishedWarning && !page.published && (
              <TooltipIcon
                className={classes.draftIcon}
                icon={faFileCircleExclamation}
                tooltipLabel="Unpublished"
              />
            )}
            {isEdit ? (
              <form
                ref={formRef}
                className={classes.detailsUpdateForm}
                onBlur={(event) => {
                  const isFormFocused =
                    formRef.current === event.relatedTarget?.form
                  if (!isFormFocused) {
                    onClose()
                  }
                }}
                onKeyDown={onKeyDown}
                noValidate
              >
                <TextInput
                  label="Title"
                  data-qa={`page-draggable-title-input-${page.id}`}
                  value={title.value}
                  message={title.error}
                  onChange={(event) => onTitleChange(event.target.value)}
                  placeholder="Enter a title..."
                />
                {(aliases.length > 0 ? aliases : ['']).map(
                  ({ value, error }, index) => {
                    const key = `alias-${index}`

                    return (
                      <AliasInput
                        key={key}
                        data-qa={`page-draggable-alias-input-${page.id}`}
                        label="Alias"
                        value={value}
                        message={error}
                        onChange={(value) => onAliasChange(value, index)}
                        placeholder="Enter an alias..."
                      />
                    )
                  }
                )}
              </form>
            ) : (
              <div className={classes.details}>
                <div data-qa={`page-draggable-title-${page.id}`}>
                  {title.error && (
                    <TooltipIcon
                      className={classes.draftIcon}
                      icon={faCircleExclamation}
                      tooltipLabel={title.error.message}
                      tooltipType="error"
                    />
                  )}
                  {title.value}
                </div>
                {getFullAliases(aliases).map(
                  (alias, index) =>
                    alias.value && (
                      <div
                        key={index}
                        className={classes.alias}
                        data-qa={`page-draggable-alias-${page.id}`}
                      >
                        {alias.error && (
                          <TooltipIcon
                            className={classes.draftIcon}
                            icon={faCircleExclamation}
                            tooltipLabel={alias.error.message}
                            tooltipType="error"
                          />
                        )}
                        @{alias.value}
                      </div>
                    )
                )}
              </div>
            )}
            {!isEdit && (
              <div className={classes.actions}>
                <Button
                  data-qa={`page-draggable-edit-${page.id}`}
                  variant="icon-link"
                  tooltipLabel="Edit Page Title/Alias"
                  onClick={() => onEdit(true)}
                >
                  <FontAwesomeIcon icon={faPencil} size={'lg'} />
                </Button>
              </div>
            )}
          </ContentAdjustingContainer>
        </div>
      )}
    </Draggable>
  )
}
