import { useState, useEffect } from 'react'

import { DownloadLink } from '../../../common/components/download-link'
import {
  TooltipContextProvider,
  useTooltipPositionContext
} from '../common/tooltip/tooltip.context'
import { Tooltip, TOOLTIP_TYPE } from '../common/tooltip/tooltip'
import { csvExportService } from '../csv-export.service'
import { TableSection } from './table-section'
import { TableColumnHeader } from './table-column-header'
import { TableRowMemo } from './table-row'
import { TableColumn } from './table-column.model'
import { tableSortService } from './table-sort.service'

import { useStyles, paddingRight } from './table.style'
import { FixedSizeList as List } from 'react-window'

/***
 * React Component to render a table
 *
 * @param {object} tableData full nested table data format
 * @param {Set} selectedRows set of row key strings to add background color to
 * @param {number} width table width
 * @param {number} labelColumnWidth optionally specify an explicit width of the label column
 * @param {number} dataColumnWidth optionally specify an explicit width for the data column
 * @param {function} onClick callback function to call when a table data cell is clicked
 * @param {boolean} isPreviewMode is table in preview mode inside the report page editor?
 */
export function Table ({ ...props }) {
  return (
    <TooltipContextProvider>
      <TableContent {...props} />
      <Tooltip type={TOOLTIP_TYPE.MINI} />
    </TooltipContextProvider>
  )
}

function TableContent ({
  dataKey,
  tableData,
  isDownloadable = true,
  selectedRows = new Set(),
  width = '100%',
  labelColumnWidth,
  dataColumnWidth,
  onClick,
  disableSort = false,
  noSortColumns,
  isPreviewMode = false,
  style = {},
  ...props
}) {
  const classes = useStyles()

  const [shiftBy, setShiftBy] = useState(0)
  const [sortSetting, setSortSetting] = useState({
    column: null,
    direction: null
  })
  const [sortedTableData, setSortedTableData] = useState(tableData)
  const tooltipPositionRef = useTooltipPositionContext()

  const Row = ({ data, index, style }) => {
    return (
      <TableRowMemo
        style={style}
        rows={sortedTableData.rows}
        index={index}
        key={data.key}
        selectedRows={selectedRows}
        columns={columns}
        shiftBy={shiftBy}
        onClick={onClick}
      />
    )
  }

  useEffect(() => {
    // Reset the tooltip position div when new table data is set so that we can accurately
    // determine the height of the table.
    if (tooltipPositionRef && tooltipPositionRef.current) {
      tooltipPositionRef.current.style.top = '0px'
      tooltipPositionRef.current.style.left = '0px'
    }
    setSortedTableData(tableSortService.sortData(tableData, sortSetting))
  }, [tableData])

  if (!sortedTableData) {
    return null
  }

  const columns = new TableColumn(
    sortedTableData.columns,
    labelColumnWidth,
    dataColumnWidth,
    width - paddingRight
  )

  function onSortColumnChange (column) {
    const nextSort = tableSortService.getNextSort(sortSetting, column)
    setSortSetting(nextSort)
    setSortedTableData(tableSortService.sortData(tableData, nextSort))
  }

  return (
    <>
      <div
        className={classes.table}
        style={{ width, ...style }}
        data-qa="nsw-table"
      >
        <TableColumnHeader
          columns={columns}
          columnGroups={sortedTableData.columnGroups}
          onShiftChange={setShiftBy}
          sortSetting={sortSetting}
          disableSort={disableSort}
          noSortColumns={noSortColumns}
          onSortColumnChange={onSortColumnChange}
          isPreviewMode={isPreviewMode}
        />
        {sortedTableData.overall && (
          <TableRowMemo
            rowClass="standalone"
            rows={[{ key: 'overall', ...sortedTableData.overall }]}
            index={0}
            columns={columns}
            shiftBy={shiftBy}
            onClick={onClick}
          />
        )}
        {(sortedTableData.sections || []).map((section) => {
          if (section.label) {
            return (
              <TableSection
                key={section.key}
                section={section}
                columns={columns}
                selectedRows={selectedRows}
                shiftBy={shiftBy}
                onClick={onClick}
              />
            )
          } else if (section.rows.length === 1) {
            // Promote row to a special standalone row format
            return (
              <TableRowMemo
                key={section.rows[0].key}
                rowClass="standalone"
                rows={section.rows}
                index={0}
                columns={columns}
                shiftBy={shiftBy}
                onClick={onClick}
              />
            )
          } else {
            return null
          }
        })}
        {sortedTableData.rows?.length > 0 && (
          <div className={classes.rowsWithoutSection}>
            {props.isChartTable ? (
              <List
                height={props.height ? props.height : 300}
                itemCount={sortedTableData.rows.length}
                itemData={sortedTableData.rows}
                itemSize={50}
                layout="vertical"
                width={width}
              >
                {Row}
              </List>
            ) : (
              sortedTableData.rows.map((row, index) => (
                <TableRowMemo
                  rows={sortedTableData.rows}
                  index={index}
                  key={row.key}
                  selectedRows={selectedRows}
                  columns={columns}
                  shiftBy={shiftBy}
                  onClick={onClick}
                />
              ))
            )}
          </div>
        )}
      </div>

      {isDownloadable && (
        <div className={classes.downloadLink}>
          <DownloadLink
            contentType="text/csv"
            fileName={`${dataKey}.csv`}
            data={csvExportService.mapTableDataToCsv(sortedTableData)}
          />
        </div>
      )}
    </>
  )
}
