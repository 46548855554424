import { createContext } from 'react'
import { reportRouteService } from '#base/js/reports/report-route.service'
import { NavigateFunction, useLocation, useNavigate } from 'react-router'

export class Router {
  location: Location
  navigate: NavigateFunction
  inactiveSession = false

  constructor ({ location, navigate }) {
    this.location = location
    this.navigate = navigate
  }

  getUserHomeRoute () {
    return reportRouteService.getBaseUrl()
  }

  getReportListRoute (report) {
    // Go back to the parent - only pass the version back if it was passed to the page
    return reportRouteService.getListUrl(report, {
      version: this.getQueryParam('parentVersion')
    })
  }

  getReportViewRoute (report) {
    return reportRouteService.getRouteUrl(report, 'view', {
      version: this.getQueryParam('version'),
      parentVersion: this.getQueryParam('parentVersion')
    })
  }

  /**
   * Retrieve the value of the given query parameter
   *
   * @param {string} name Query parameter name to retrieve
   */
  getQueryParam (name) {
    const queryParams = new URLSearchParams(this.location.search)
    return queryParams.get(name)
  }

  /**
   * Replace the querystring parameters for current route in the browser history
   *
   * @param {object} params key/value pairs to set in the querystring - an undefined value will delete the parameter
   */
  replaceQueryParams (params) {
    const queryParams = new URLSearchParams(this.location.search)
    Object.keys(params).forEach((name) => {
      if (params[name] === undefined) {
        queryParams.delete(name)
      } else {
        queryParams.set(name, params[name])
      }
    })
    this.navigate(
      {
        pathname: this.location.pathname,
        search: `?${queryParams.toString()}`
      },
      { replace: true }
    )
  }

  setInactiveSession () {
    this.inactiveSession = true
  }
}

export const RouterContext = createContext<{ router: Router }>(undefined)

export function RouterContextProvider ({ children }) {
  const router = new Router({
    location: useLocation(),
    navigate: useNavigate()
  })

  return (
    <RouterContext.Provider value={{ router }}>
      {children}
    </RouterContext.Provider>
  )
}
