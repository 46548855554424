import {
  REPORT_TYPES,
  REPORT_VIEWERS
} from '#common/src/report/report.constants'

export const REPORT_TYPES_LABEL = {
  [REPORT_TYPES.PHR]: 'Population Health Report',
  [REPORT_TYPES.SANKEY]: 'Sankey Report',
  [REPORT_TYPES.NARRATIVE]: 'Narrative Report',
  [REPORT_TYPES.IP]: 'Interactive Publication'
}

export const REPORT_VIEWER_MODE = {
  READ: 0,
  WRITE: 1
}

export const REPORT_VIEWER_LABEL = {
  [REPORT_VIEWERS.PAGE_LIST]: 'Dynamic Report',
  [REPORT_VIEWERS.IFRAME]: 'Static HTML'
}

export const REPORT_VIEWER_STATUS = {
  [REPORT_VIEWERS.PAGE_LIST]: 'Recommended',
  [REPORT_VIEWERS.IFRAME]: 'Advanced'
}

export const REPORT_VIEWER_SELECT_OPTIONS = [
  {
    value: REPORT_VIEWERS.PAGE_LIST,
    label: REPORT_VIEWER_LABEL[REPORT_VIEWERS.PAGE_LIST],
    status: REPORT_VIEWER_STATUS[REPORT_VIEWERS.PAGE_LIST]
  },
  {
    value: REPORT_VIEWERS.IFRAME,
    label: REPORT_VIEWER_LABEL[REPORT_VIEWERS.IFRAME],
    status: REPORT_VIEWER_STATUS[REPORT_VIEWERS.IFRAME]
  }
]

export const REPORT_STATUS = {
  DRAFT: 0,
  PUBLISHED: 1
}

export const REPORT_STATUS_LABEL = {
  [REPORT_STATUS.DRAFT]: 'Draft',
  [REPORT_STATUS.PUBLISHED]: 'Published'
}

export const REPORT_ROLE = {
  OWNER: 'owner',
  WRITER: 'writer',
  REVIEWER: 'reviewer',
  READER: 'reader',
  READER_LIMITED: 'readerLimited'
}

export const LIMITED_ROLES = [REPORT_ROLE.READER_LIMITED]
