import { colors } from '#base/js/variables.style'

export function getStyle ({
  color = 'white',
  background = colors.brandAlt
} = {}) {
  return {
    arrow: {
      sx: {
        color: background
      }
    },
    tooltip: {
      sx: {
        margin: '10px',
        padding: '10px',
        background,
        color,
        fontSize: '13px',
        borderRadius: '8px'
      }
    }
  }
}

export const styles = {
  default: getStyle({
    background: colors.brandAlt
  }),
  error: getStyle({
    background: colors.error
  }),
  info: getStyle({
    background: colors.info
  }),
  success: getStyle({
    background: colors.success
  }),
  pending: getStyle({
    color: 'black',
    background: colors.pending
  }),
  warning: getStyle({
    background: colors.warning
  })
}
