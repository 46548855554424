import { useEffect, useState, useContext } from 'react'
import { REPORT_VIEWERS } from '#common/src/report/report.constants'
import { Button } from '../common/components/button'
import {
  AliasInput,
  isDuplicateAliasError
} from '../common/components/alias-input'
import { NotificationManager } from '../common/components/Notifications'
import { FullPageLoader } from '../common/components/full-page-loader'
import { FullPageError } from '../common/components/full-page-error'
import { Report } from './report.model'
import { reportService } from './report.service'
import { useStyles } from './create-executive-summary.style'
import { RouterContext } from '../common/contexts/routerContext'
import { Navigate, useParams } from 'react-router'

const PageStates = {
  PENDING: 'PENDING',
  REDIRECT: 'REDIRECT',
  FORM: 'FORM',
  ERROR: 'ERROR'
}

export const CreateExecutiveSummaryPage = () => {
  const classes = useStyles()

  const { router } = useContext(RouterContext)
  const { reportId } = useParams()
  const parentReportId = reportId

  const [pageState, setPageState] = useState(PageStates.PENDING)
  const [pageId, setPageId] = useState()
  const [alias, setAlias] = useState('executive-summary')
  const [aliasError, setAliasError] = useState()

  function isValid () {
    return pageState !== PageStates.PENDING && alias && !aliasError
  }

  function backToPageList () {
    router.navigate(`/reports/${parentReportId}/view`)
  }

  function handleAliasChange ({ alias, error }) {
    setAlias(alias)
    setAliasError(error)
  }

  async function create () {
    setPageState(PageStates.PENDING)

    try {
      const parent = new Report(await reportService.getReport(parentReportId))

      const aliases = parent.aliases.map(
        (parentAlias) => `${parentAlias}/${alias}`
      )

      const { id } = await reportService.createReportPage(
        'Executive Summary',
        aliases,
        undefined,
        REPORT_VIEWERS.EXECUTIVE_SUMMARY_PAGE,
        parentReportId
      )

      NotificationManager.create({
        type: 'success',
        message: 'Successfully created the executive summary'
      })
      setPageId(id)
      setPageState(PageStates.REDIRECT)
    } catch (error) {
      if (error.response.status === 303) {
        NotificationManager.create({
          type: 'error',
          message: 'An executive summary already exists for this report'
        })
        setPageId(error.response.data.message)
        setPageState(PageStates.REDIRECT)
      } else if (isDuplicateAliasError(error)) {
        setAliasError({
          type: 'error',
          message: `Sorry, a page with an alias of "${alias}" already exists on this report. Try another?`
        })
        setPageState(PageStates.FORM)
      } else {
        setPageState(PageStates.ERROR)
      }
    }
  }

  useEffect(() => {
    create()
  }, [])

  switch (pageState) {
    case PageStates.PENDING:
      return <FullPageLoader />
    case PageStates.REDIRECT:
      return <Navigate to={`/reports/${pageId}/edit`} replace />
    case PageStates.FORM:
      return (
        <div className="page-layout">
          <div className="page-header">
            <div className="page-header-left flex-row"></div>
            <Button
              className="button-space-right"
              variant="link"
              type="button"
              onClick={backToPageList}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="page-header-right"
              data-qa="create-executive-summary-button"
              disabled={!isValid()}
              onClick={create}
            >
              Create Executive Summary
            </Button>
          </div>
          <div className="page-content">
            <div className={classes.form}>
              <div>
                <AliasInput
                  label="Alias"
                  data-qa="page-alias-input"
                  message={aliasError}
                  value={alias}
                  onChange={handleAliasChange}
                  required={!alias}
                />
              </div>
            </div>
          </div>
        </div>
      )
    case PageStates.ERROR:
      return (
        <FullPageError description="An error occurred creating the executive summary. Please refresh the page to try again." />
      )
  }
}
