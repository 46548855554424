import { createUseStyles } from 'react-jss'
import { colors } from '#base/js/variables.style'

export const style = {
  icon: {
    color: colors.warning
  }
}

export const useStyles = createUseStyles(style)
