export const ALIAS_VALID = /^[0-9a-z-]*$/
export const ALIAS_INVALID = /[^0-9a-z-]+/g

export class AliasService {
  format (value) {
    return value.toLowerCase().replace(ALIAS_INVALID, '-')
  }
}

export const aliasService = new AliasService()
