import { useStyles } from './NavbarUserMenu.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleUser,
  faCaretDown,
  faBell,
  faUser
} from '@fortawesome/pro-solid-svg-icons'

import { GenericDropdown } from '../FloatingUI/GenericDropdown'
import { useContext, useState } from 'react'
import { UserContext } from '#base/js/common/contexts/userContext'
import { useNavigate } from 'react-router'
import { reportService } from '#base/js/reports/report.service.js'
import moment from 'moment'
import {
  faCircleQuestion,
  faGear,
  faRightFromBracket
} from '@fortawesome/pro-light-svg-icons'

export const NavbarUserMenu = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { user, signOut } = useContext(UserContext)

  const [notificationPane, setNotificationPane] = useState([])

  function checkPermissions (element) {
    switch (element) {
      case 'settings':
        return user.isSiteAdmin() || user.isUserAdmin()
      default:
        return true
    }
  }

  async function fetchNotificationList () {
    const data = await reportService.getReportList({
      limit: 5,
      hasAncestors: false,
      byLastUpdated: -1
    })
    const processedNotifications = await buildNotifications(data.docs)
    setNotificationPane(processedNotifications)
  }

  async function buildNotifications (docs) {
    const currentTime = moment()
    return docs.map((doc) => {
      return (
        <div
          className={classes.notificationPane}
          key={doc.id}
          onClick={() => {
            navigateTo(`/reports/${doc.id}/view`)
          }}
        >
          <div className={classes.reportTitle}>{doc.title}</div>
          <div className={classes.timeStamp}>
            {moment(doc.publishedDate || doc.modifiedDate).from(currentTime)}
          </div>
        </div>
      )
    })
  }

  function navigateTo (link) {
    navigate(`${link}`)
  }

  async function clickLogOut () {
    signOut('You have signed out')
  }

  return (
    <div className={classes.userNavContainer} data-qa="navbar-UserMenu">
      <GenericDropdown
        Target={
          <FontAwesomeIcon
            icon={faBell}
            color="white"
            className={classes.userNotifications}
          />
        }
        onOpen={fetchNotificationList}
        DropdownItems={notificationPane}
        menuTitle={'Latest Updates'}
        placement="bottom-end"
        enableArrow={true}
        dropdownOffset={[0, -6.5]}
      ></GenericDropdown>
      <GenericDropdown
        Target={
          <>
            <FontAwesomeIcon
              icon={faCircleUser}
              size="xl"
              color="white"
              className={classes.userCircle}
            />
            <FontAwesomeIcon
              icon={faCaretDown}
              size="sm"
              color="white"
              className={classes.userCircleCaret}
            />
          </>
        }
        menuTitle={[
          `${user.displayName}`,
          <br key={'user-menu-break-' + classes.userMenuPane} />,
          `${user.username}`
        ]}
        DropdownItems={[
          <div
            className={classes.userMenuPane}
            key={'profile'}
            onClick={() => {
              navigateTo('/profile')
            }}
            data-qa="navbar-Profile"
          >
            <FontAwesomeIcon
              icon={faUser}
              size="sm"
              className={classes.userCircle}
            />
            <span>Profile</span>
          </div>,
          <div
            className={classes.userMenuPane}
            key={'settings'}
            onClick={() => {
              navigateTo('/admin/settings')
            }}
            data-qa="navbar-Settings"
          >
            <FontAwesomeIcon
              icon={faGear}
              size="sm"
              className={classes.userCircle}
            />
            <span>Settings</span>
          </div>,
          <div
            className={classes.userMenuPane}
            key={'help'}
            onClick={() => {
              navigateTo('/help')
            }}
            data-qa="navbar-Help"
          >
            <FontAwesomeIcon
              icon={faCircleQuestion}
              size="sm"
              className={classes.userCircle}
            />
            <span>Help</span>
          </div>,
          <div
            className={classes.userMenuPane}
            key={'log-out'}
            onClick={clickLogOut}
            data-qa="navbar-LogOut"
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              size="sm"
              className={classes.userCircle}
            />
            <span>Sign Out</span>
          </div>
        ].filter(({ key }) => checkPermissions(key))}
        placement="bottom-end"
        dropdownOffset={[0, 0]}
      ></GenericDropdown>
    </div>
  )
}
