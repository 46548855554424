import { createUseStyles } from 'react-jss'
import { boxShadows } from '../../variables.style'

export const style = {
  container: {
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: boxShadows.card
  },
  clickable: {
    cursor: 'pointer'
  }
}

export const useStyles = createUseStyles(style)
