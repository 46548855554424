import { createUseStyles } from 'react-jss'
import { borderStyle } from './table.style'

export const style = {
  sectionToggle: {
    display: 'inline-block',
    padding: '0 5px'
  },
  sectionToggleIcon: {
    width: 14,
    height: 14
  },
  sectionLabelCell: {
    fontFamily: "'Raleway', sans-serif",
    fontVariantNumeric: 'lining-nums',
    fontSize: 17,
    textTransform: 'uppercase',
    cursor: 'pointer',
    padding: '20px 0 6px',
    borderBottom: borderStyle
  },
  dataRowsBody: {
    borderLeft: borderStyle,
    borderRight: borderStyle
  },
  collapsed: {
    display: 'none'
  }
}

export const useStyles = createUseStyles(style)
