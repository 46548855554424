/***
 *** Defines interface for top-level React Object
 ***  (Used in /router.jsx)
 ***/
import { AppRoutes } from './routes'

import 'normalize.css'
import '../style/theme/brand.css'
import '../style/theme/light.css'
import '../style/cs-bootstrap.scss'
import 'hopscotch/dist/css/hopscotch.min.css'
import 'katex/dist/katex.css'
import '../style/causal-studio.css'
import '../style/app.scss'

function Root ({ appSettings }) {
  return <AppRoutes appSettings={appSettings} />
}

export default Root
