import { useStyles } from './label.style'

export const Label = ({ className, required, children }) => {
  const classes = useStyles()

  return (
    <div className={className}>
      {children}{' '}
      {required && <span className={classes.requiredLabel}>- Required</span>}
    </div>
  )
}
