import { createUseStyles } from 'react-jss'
import { colors } from '../variables.style'

export const style = {
  executiveSummary: {
    composes: 'flex-row',
    alignItems: 'center'
  },
  select: {
    margin: '-9px 0 -9px -9px'
  },
  link: {
    color: colors.brandPrimary,
    fontWeight: 'bold'
  },
  draftIcon: {
    marginLeft: '-10px',
    padding: '0 10px'
  }
}

export const useStyles = createUseStyles(style)
