import { useTooltipDisplayContext } from '../../common/tooltip/tooltip.context'
import { cellFormatService } from './cell-format.service'

import { useStyles } from './cell-format.style'

export const NumberCell = ({ values, formatOptions, isLastCell }) => {
  const classes = useStyles()

  const [, setTooltipDisplayData] = useTooltipDisplayContext()

  const defaultStyles = { textAlign: 'right' }
  const displayValue = cellFormatService.formatValues(values, formatOptions)
  const className = cellFormatService.mapFormatOptionsToClassName(
    formatOptions,
    classes
  )
  const style = cellFormatService.mapFormatOptionsToStyle(
    formatOptions,
    defaultStyles,
    isLastCell
  )

  function showTooltip () {
    if (formatOptions.label) {
      setTooltipDisplayData({
        primaryValue: formatOptions.label
      })
    }
  }

  function hideTooltip () {
    if (formatOptions.label) {
      setTooltipDisplayData()
    }
  }

  return (
    <div
      className={`${classes.numberCell} ${className}`}
      style={style}
      onMouseMove={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {displayValue}
    </div>
  )
}
