import { extent } from 'd3-array'

import { CellFormats } from './cell-formats.model'
import { ROW_LABEL_KEY } from './table-column.model'
import { CONFIDENCE_SCALE_SCOPE } from '#common/src/report/blocks/table.constants'

const DEFAULT_CELL_FORMAT = 'default'

/**
 * Map from a flat, rectangular format into the nested table data format
 *
 * @param {object} data
 * @param {object} config
 * @returns {object} Nested table data format
 */
export function mapFromRawData (
  data,
  {
    cellFormats = {},
    columnHeadersAlign = 'center',
    sparklineVariable,
    sparklineFormat,
    primaryLabel,
    secondaryLabel,
    columnGroups: columnGroupSettings = [],
    columns: columnSettings = [],
    sections: sectionSettings = [],
    rows: rowSettings = [],
    confidenceScaleScope = CONFIDENCE_SCALE_SCOPE.TABLE
  }
) {
  cellFormats = new CellFormats(cellFormats)
  let nestedColumnsAndGroups = getDistinctColumns(data, columnHeadersAlign)
  nestedColumnsAndGroups = applyColumnGroupSettings(
    nestedColumnsAndGroups,
    columnGroupSettings
  )
  let { columnGroups, columns } = flattenColumnGroups(nestedColumnsAndGroups)
  columns = applyColumnSettings(columns, columnSettings)
  const columnKeys = columns.map(({ key }) => key)
  const sections = new Map()

  // If there are no sections at all, we build a list of rows without sections for a flat table
  const hasSections = data.find((r) => !!r.section) !== undefined
  const rowsWithoutSections = new Map()

  if (confidenceScaleScope === CONFIDENCE_SCALE_SCOPE.TABLE) {
    // Calculate the confidence domain for each confidence cell format
    const confidenceDomains = calculateConfidenceDomains(data, cellFormats)

    // Apply the domain to each confidence cell format
    confidenceDomains.forEach(({ key, index, domain }) => {
      // Clone and add the domain to confidence cell format
      cellFormats[key] = [...cellFormats[key]]
      cellFormats[key][index] = { ...cellFormats[key][index] }
      cellFormats[key][index].domain = domain
    })
  }

  data.forEach((r, i) => {
    const cellFormat =
      r.cell_format && cellFormats[r.cell_format]
        ? cellFormats[r.cell_format]
        : getDefaultCellFormat(cellFormats)

    let section
    let row
    if (hasSections) {
      // Find section
      const sectionKey = r.section || r.row
      section = sections.get(sectionKey)

      // Add section if it doesn't exist
      if (!section) {
        section = { key: sectionKey, label: r.section, rows: new Map() }
        sections.set(sectionKey, section)
      }

      // Find row from section
      row = section.rows.get(r.row)
    } else {
      // Find row from rowsWithoutSections
      row = rowsWithoutSections.get(r.row)
    }

    // Add row if it doesn't exist
    if (!row) {
      row = createRow({ key: r.row, label: r.row }, columnKeys)
      if (sparklineVariable) {
        row.secondaryLabel = {
          key: 'sparkline',
          variable: sparklineVariable,
          formatOptions: {
            type: sparklineFormat
          }
        }
      }

      if (hasSections) {
        section.rows.set(r.row, row)
      } else {
        rowsWithoutSections.set(r.row, row)
      }
    }

    // Find cell within row
    const cell = row.columns.get(getColumnKey(r.column_group, r.column))

    // if cell isn't found - bail
    if (!cell) {
      return
    }

    // Map cell parts
    cell.parts = cellFormat.map(({ variables, format, ...formatOptions }) => ({
      values: variables.map((v) => r[v]).flat(),
      variables,
      format,
      formatOptions
    }))
  })

  const result = {
    columns: [
      { key: ROW_LABEL_KEY, label: primaryLabel || '', secondaryLabel },
      ...columns
    ],
    columnGroups,
    sections: getArrayFromValues(sections).map((s) => ({
      ...s,
      rows: getArrayFromValues(s.rows).map((r) => ({
        ...r,
        columns: getArrayFromValues(r.columns)
      }))
    })),
    rows: getArrayFromValues(rowsWithoutSections).map((r) => ({
      ...r,
      columns: getArrayFromValues(r.columns)
    }))
  }

  result.sections = applySectionSettings(result.sections, sectionSettings)
  result.rows = applyRowSettings(result.rows, rowSettings)

  return result
}

function getDefaultCellFormat (cellFormats) {
  const keys = Object.keys(cellFormats)
  if (keys.includes(DEFAULT_CELL_FORMAT)) {
    return cellFormats[DEFAULT_CELL_FORMAT]
  }
  return keys.length > 0 ? cellFormats[keys[0]] : undefined
}

/**
 * Pull out the distinct column values from the data
 */
function getDistinctColumns (data, columnHeadersAlign) {
  const groups = []
  const columnKeys = new Set()

  data.forEach((r) => {
    const columnKey = getColumnKey(r.column_group, r.column)
    const column = {
      key: columnKey,
      label: r.column,
      align: columnHeadersAlign
    }
    let group

    // Find or create the group
    if (r.column_group) {
      // Column has a group - find/create group and append the column
      group = groups.find(({ label }) => label === r.column_group)
      if (!group) {
        group = { label: r.column_group, columns: [] }
        groups.push(group)
      }
    } else {
      // Column has no group - should be appended to a new/existing undefined group
      if (
        groups.length === 0 ||
        groups[groups.length - 1].label !== undefined
      ) {
        // create a new undefined group
        group = { columns: [] }
        groups.push(group)
      } else {
        group = groups[groups.length - 1]
      }
    }

    // Add the column to the group if it not already there
    if (!columnKeys.has(columnKey)) {
      columnKeys.add(columnKey)
      group.columns.push(column)
    }
  })

  return groups
}

/**
 * Split out the column groups and columns into separate lists - the form expected by the table component
 */
function flattenColumnGroups (nestedColumnsAndGroups) {
  let columns = []

  let columnGroups = nestedColumnsAndGroups.map(
    ({ label, columns: nestedColumns }) => {
      columns = columns.concat(nestedColumns)
      return {
        label,
        columnCount: nestedColumns.length
      }
    }
  )

  // This indicates there are no groups
  if (columnGroups.length === 1 && columnGroups[0].label === undefined) {
    columnGroups = []
  }

  return { columnGroups, columns }
}

/**
 * Order and update labels for a nested structure of column groups and columns
 *
 * Column groups are ordered in the order they appear in the settings, with any groups that are not in the settings
 * being added to the end.
 *
 * Columns are ordered within each group in the order they appear in the settings, with any columns that are not in the
 * settings being added to the end (within that group)
 *
 * Columns without a group are included in a group with an empty label. These are grouped/ordered according to how
 * they are grouped/ordered in the settings. Any columns without a group that are not listed in the settings are added
 * to an empty label group at the end.
 */
function applyColumnGroupSettings (nestedColumnsAndGroups, columnGroupSettings) {
  if (columnGroupSettings.length) {
    // Pull out all the ungrouped columns
    let ungroupedColumns = []
    nestedColumnsAndGroups = nestedColumnsAndGroups.filter(
      ({ label, columns }) => {
        if (isEmpty(label)) {
          ungroupedColumns = ungroupedColumns.concat(columns)
          return false
        }
        return true
      }
    )

    // Build out the groups in the order they are in the settings, including ungrouped columns
    let columnGroups = []
    columnGroupSettings.forEach((settings) => {
      let group
      if (isEmpty(settings.value)) {
        // Pull from ungrouped columns
        group = { columns: [] }
        const columnsInSettings = (settings.columns || []).map(
          ({ value }) => value
        )
        ungroupedColumns = ungroupedColumns.filter((column) => {
          if (columnsInSettings.includes(column.label)) {
            group.columns.push(column)
            return false
          }
          return true
        })
      } else {
        // Find existing group and apply settings before adding to our new list
        const index = nestedColumnsAndGroups.findIndex(
          ({ label }) => label === settings.value
        )
        if (index > -1) {
          group = nestedColumnsAndGroups.splice(index, 1)[0]
          if (settings.label) {
            group.label = settings.label
          }
        }
      }
      if (group) {
        group.columns = applyColumnSettings(
          group.columns,
          settings.columns || []
        )
        columnGroups.push(group)
      }
    })

    // Tack on any groups that weren't in the settings
    if (nestedColumnsAndGroups.length) {
      columnGroups = columnGroups.concat(nestedColumnsAndGroups)
    }

    // Tack on any ungrouped columns that weren't in the settings
    if (ungroupedColumns.length) {
      if (
        columnGroups.length &&
        columnGroups[columnGroups.length - 1].label === undefined
      ) {
        const lastGroup = columnGroups[columnGroups.length - 1]
        lastGroup.columns = lastGroup.columns.concat(ungroupedColumns)
      } else {
        columnGroups.push({ columns: ungroupedColumns })
      }
    }

    nestedColumnsAndGroups = columnGroups
  }

  return nestedColumnsAndGroups
}

/**
 * Order columns and update labels and/or heading alignment
 */
function applyColumnSettings (columns, columnSettings) {
  if (columnSettings.length) {
    // Reorder columns
    const columnOrder = columnSettings.map(({ value }) => value)
    sortList(columns, columnOrder, 'label')

    // Update any group labels from the settings
    columns.forEach((c) => {
      const settings = columnSettings.find(({ value }) => value === c.label)
      if (settings && settings.label) {
        c.label = settings.label
      }
      if (settings && settings.headingAlign) {
        c.align = settings.headingAlign
      }
    })
  }

  return columns
}

/**
 * Order sections (and rows within sections) and update labels
 */
function applySectionSettings (sections, sectionSettings) {
  if (sectionSettings.length) {
    // Reorder sections
    const order = sectionSettings.map(({ value }) => value)
    sortList(sections, order, 'label')

    // Update any section labels and apply row settings
    sections.forEach((section) => {
      const settings = sectionSettings.find(
        ({ value }) => value === section.label
      )
      if (settings && settings.label) {
        section.label = settings.label
      }
      if (settings && settings.rows) {
        section.rows = applyRowSettings(section.rows, settings.rows)
      }
    })
  }

  return sections
}

/**
 * Order rows and update labels
 */
function applyRowSettings (rows, rowSettings) {
  if (rowSettings.length) {
    // Reorder rows
    const order = rowSettings.map(({ value }) => value)
    sortList(rows, order, 'label')

    rows.forEach((row) => {
      const settings = rowSettings.find(({ value }) => value === row.label)
      if (settings && settings.label) {
        row.label = settings.label
      }
    })
  }

  return rows
}

/**
 * Determine if a given value is empty
 */
function isEmpty (value) {
  return value === undefined || value === null || value === ''
}

/**
 * Sort an array of objects based on an array of values and a field in the objects to match against
 *
 * @param {array} arr
 * @param {array} order
 * @param {string} sortField
 */
function sortList (arr, order, sortField) {
  arr.sort((value1, value2) => {
    let value1Index = order.indexOf(value1[sortField])
    let value2Index = order.indexOf(value2[sortField])
    value1Index = value1Index === -1 ? arr.length : value1Index
    value2Index = value2Index === -1 ? arr.length : value2Index
    return value1Index - value2Index
  })
}

/**
 * Create a new row
 */
function createRow (row, columnKeys) {
  return {
    ...row,
    columns: new Map(columnKeys.map((c) => [c, createNewCell(c)]))
  }
}

/**
 * Create a new cell within a row
 */
function createNewCell (key) {
  return { key, parts: [] }
}

/**
 * Return an array of just the values of a Map object
 */
function getArrayFromValues (map) {
  return Array.from(map.values())
}

function getColumnKey (group, value) {
  return group !== undefined ? `${group}-${value}` : value
}

/**
 * Calculate the domain for each confidence cell format
 */
function calculateConfidenceDomains (data, cellFormats) {
  const confidenceFormats = []

  // Pull out all the confidence cell formats
  Object.keys(cellFormats).forEach((key) => {
    cellFormats[key].forEach(({ format = 'text', variables = [] }, index) => {
      if (format === 'confidence') {
        confidenceFormats.push({ key, index, variables })
      }
    })
  })

  // Collect all the values for each confidence cell format variable
  data.forEach((r) => {
    confidenceFormats.forEach((confidenceFormat) => {
      confidenceFormat.values = confidenceFormat.values || []
      confidenceFormat.variables.forEach((variable) => {
        const value = r[variable]
        if (
          r.cell_format === confidenceFormat.key &&
          value !== undefined &&
          value !== null
        ) {
          if (Array.isArray(value)) {
            confidenceFormat.values = confidenceFormat.values.concat(value)
          } else {
            confidenceFormat.values.push(value)
          }
        }
      })
    })
  })

  // Calculate the domains
  return confidenceFormats.map(({ key, index, values }) => ({
    key,
    index,
    domain: values ? extent(values) : [undefined, undefined]
  }))
}
