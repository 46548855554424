import { createUseStyles } from 'react-jss'
import { colors, zIndexes } from '../../variables.style'

const BORDER_RADIUS = 4

export const style = {
  overlay: {
    zIndex: zIndexes.modalOverlay,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  window: {
    composes: 'flex-column',
    zIndex: zIndexes.modal,
    margin: 'auto',
    backgroundColor: colors.background,
    borderRadius: BORDER_RADIUS,
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1)',
    minHeight: 200,
    maxHeight: '90vh',
    '@media (max-width: 992px)': {
      maxWidth: '100dvw',
      maxHeight: '100dvh'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    backgroundColor: colors.brandAlt,
    color: 'white',
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS
  },
  title: {
    fontFamily: 'Raleway, sans-serif',
    fontVariantNumeric: 'lining-nums',
    fontSize: 20,
    flex: 'auto',
    padding: '15px 30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  dismiss: {
    cursor: 'pointer',
    height: 30,
    padding: '12px 15px',
    borderTopRightRadius: BORDER_RADIUS,
    '&:hover': {
      backgroundColor: colors.modalHeaderActionHover
    }
  },
  body: {
    padding: '10px 30px',
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 'auto',

    // Modal content styling
    '& h1': {
      fontSize: 16,
      textTransform: 'uppercase',
      margin: '20px 0 12px'
    },
    '& ol,ul': {
      paddingInlineStart: 30,
      margin: '12px 0'
    },
    '& p': {
      margin: '12px 0'
    },
    '& p,ol,ul,li': {
      fontSize: 14
    }
  },
  footer: {
    height: 53,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 15,
    backgroundColor: colors.modalFooter,
    borderTop: `1px solid ${colors.border}`,
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS
  }
}

export const useStyles = createUseStyles(style)
