import { colors } from '../../variables.style'
import { createUseStyles } from 'react-jss'

export const style = {
  downloadLink: {
    color: 'black',
    fontSize: '14px',
    '&:hover': {
      color: colors.brandPrimary
    }
  },
  linkText: {
    margin: 'auto 0',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  hoverAnchor: {
    cursor: 'pointer'
  }
}

export const useStyles = createUseStyles(style)
